import { ReactNode } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

interface FileInputProps {
  children: ReactNode;
  dropzoneProps: DropzoneOptions;
  className?: string;
}

export function FileInput({
  children,
  dropzoneProps,
  className,
}: FileInputProps) {
  const { getRootProps, getInputProps } = useDropzone(dropzoneProps);

  return (
    <div {...getRootProps()} className={className}>
      <input {...getInputProps()} />
      <div>{children}</div>
    </div>
  );
}
