import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { DocumentTypeEnum } from "../../../generated/types";
import {
  filingQueryKey,
  isConstructedPrimaryDocument,
  TConstructedPrimaryDocument,
  TDocument,
  TFiling,
} from "../../_common/api/filings";
import { Table } from "../../_common/components/Table/Table";
import { UploadDocumentCta } from "../../_common/components/UploadDocumentCta";
import {
  TableActionConfig,
  TableHeaderConfig,
} from "../../_common/components/Table/types";
import { useMemo } from "react";

interface PrimaryDocumentTableProps {
  filing: TFiling;
}

export function PrimaryDocumentTable({ filing }: PrimaryDocumentTableProps) {
  const { data = filing } = useQuery<TFiling>({
    queryKey: [filingQueryKey(filing.id)],
  });

  const primaryDocuments = useMemo(() => {
    const uploadedPrimaryDocuments =
      data?.documents.filter(
        (d) => d.document_type === DocumentTypeEnum.primary_document
      ) || [];

    return [...uploadedPrimaryDocuments, ...data.constructed_primary_documents];
  }, [data.constructed_primary_documents, data?.documents]);

  return (
    <Table
      data={primaryDocuments}
      headers={headerConfigs}
      rowKey="id"
      actions={documentActions}
      emptyState={
        <div className="pl-7 py-2">
          <UploadDocumentCta
            text="Upload Primary Document"
            documentType={DocumentTypeEnum.primary_document}
            filingId={filing.id}
          />
        </div>
      }
    />
  );
}

const documentActions: TableActionConfig<
  TDocument | TConstructedPrimaryDocument
>[] = [];

const headerConfigs: TableHeaderConfig<
  TDocument | TConstructedPrimaryDocument
>[] = [
  {
    label: "Primary Document",
    renderCellContent: (doc) =>
      isConstructedPrimaryDocument(doc) ? (
        <Link
          to={`/filings/${doc.filing}/constructed-documents/${doc.id}`}
          className="text-brandBlue hover:underline"
        >
          View Filing
        </Link>
      ) : (
        <Link
          to={`/filings/${doc.filing_id}/documents/${doc.id}`}
          className="text-brandBlue hover:underline"
        >
          {/* @ts-expect-error - metadata is json and untyped */}
          {doc.metadata?.name || "-"}
        </Link>
      ),
    getStringContent: (doc) =>
      /* @ts-expect-error - metadata is json and untyped */
      isConstructedPrimaryDocument(doc) ? doc.title : doc.metadata?.name,
    hasTooltip: true,
  }
];
