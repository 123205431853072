import clsx from "clsx";
import { CommonIconProps } from "./icons/_common";

interface SpinnerProps extends CommonIconProps {
  color: "gray" | "brand";
}
export function Spinner({ color, className, ...props }: SpinnerProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      className={clsx("animate-spin", className)}
      {...props}
    >
      <defs>
        <linearGradient id="spinner-secondHalf">
          {color === "gray" ? (
            <>
              <stop offset="0%" stopOpacity="0" stopColor="#b1b1b1" />
              <stop offset="100%" stopOpacity="0.5" stopColor="#b1b1b1" />
            </>
          ) : (
            <>
              <stop offset="0%" stopOpacity="0" stopColor="#FFA500" />
              <stop offset="100%" stopOpacity="0.5" stopColor="#FFA500" />
            </>
          )}
        </linearGradient>
        <linearGradient id="spinner-firstHalf">
          {color === "gray" ? (
            <>
              <stop offset="0%" stopOpacity="1" stopColor="#EBEBEB" />
              <stop offset="100%" stopOpacity="0.5" stopColor="#B1B1B1" />
            </>
          ) : (
            <>
              <stop offset="0%" stopOpacity="1" stopColor="#32AAE3" />
              <stop offset="100%" stopOpacity="0.5" stopColor="#ffa500" />
            </>
          )}
        </linearGradient>
      </defs>

      <g stroke-width="10">
        <path
          stroke="url(#spinner-secondHalf)"
          d="M 4 100 A 96 96 0 0 1 196 100"
        />
        <path
          stroke="url(#spinner-firstHalf)"
          d="M 196 100 A 96 96 0 0 1 4 100"
        />
      </g>
    </svg>
  );
}
