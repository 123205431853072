import { useCallback, useMemo } from "react";
import { ButtonLink } from "../ButtonLink";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";

interface PaginationProps {
  totalPages: number;
}
export function Pagination({ totalPages }: PaginationProps) {
  const [currentPage, setCurrentPage] = useQueryParam(
    "page",
    withDefault(NumberParam, 1),
  );

  const hasNext = useMemo(() => {
    return currentPage < totalPages;
  }, [currentPage, totalPages]);

  const hasPrev = useMemo(() => {
    return currentPage > 1;
  }, [currentPage]);

  const handleNext = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage, setCurrentPage]);

  const handlePrev = useCallback(() => {
    setCurrentPage(currentPage - 1);
  }, [currentPage, setCurrentPage]);

  return (
    <div className="flex items-center justify-end gap-2">
      <ButtonLink
        onClick={handlePrev}
        disabled={!hasPrev}
        className="text-gray-500"
      >
        Previous
      </ButtonLink>
      <span className="text-sm">
        Page {currentPage} of {totalPages}
      </span>
      <ButtonLink
        onClick={handleNext}
        disabled={!hasNext}
        className="text-gray-500"
      >
        Next
      </ButtonLink>
    </div>
  );
}
