import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from "react";

import { ErrorToastProps } from "./ErrorToast";
import { InfoToastProps } from "./InfoToast";

export type TToast = ErrorToastProps | InfoToastProps;

interface ToastState {
  toasts: TToast[];
}

const initialToastState: ToastState = {
  toasts: [],
};

type ToastStateAction =
  | {
    type: "add_toast";
    payload: TToast;
  }
  | {
    type: "dismiss_toast";
    payload: number;
  };

const toastStateReducer = (state: ToastState, action: ToastStateAction) => {
  switch (action.type) {
    case "add_toast":
      return {
        ...state,
        toasts: [action.payload, ...state.toasts],
      };

    case "dismiss_toast":
      return {
        ...state,
        toasts: [
          ...state.toasts.slice(0, action.payload),
          ...state.toasts.slice(action.payload + 1),
        ],
      };

    default:
      return state;
  }
};

type ToastContextValue = [ToastState, Dispatch<ToastStateAction>];
const ToastContext = createContext<ToastContextValue>([
  initialToastState,
  () => null,
]);

export function ToastContextProvider({ children }: PropsWithChildren) {
  const toastState = useReducer(toastStateReducer, initialToastState);

  return (
    <ToastContext.Provider value={toastState}>{children}</ToastContext.Provider>
  );
}

export function useToasts() {
  return useContext(ToastContext);
}

export function useDispatchInfoToast() {
  const dispatch = useToasts()[1];

  return (content: string, autoDismissAfter?: number) => {
    dispatch({
      type: "add_toast",
      payload: { intent: "info", content, autoDismissAfter },
    });
  };
}

export function useDispatchErrorToast() {
  const dispatch = useToasts()[1];

  return (content: string, autoDismissAfter?: number) => {
    dispatch({
      type: "add_toast",
      payload: { intent: "error", content, autoDismissAfter },
    });
  };
}
