import { ButtonLink } from "../ButtonLink";
import { CircleXIcon } from "../icons/CircleXIcon";
import { PaperClipIcon } from "../icons/PaperClipIcon";

interface FileListItemProps {
  file: File;
  onRemove: () => void;
}
export function FileListItem({ file, onRemove }: FileListItemProps) {
  return (
    <div className="flex justify-between items-center gap-2">
      <div className="flex gap-2 text-sm text-gray-400">
        <PaperClipIcon />
        <span>{file.name}</span>
      </div>

      <div className="flex items-center gap-2">
        <ButtonLink
          onClick={onRemove}
          className="text-gray-400 hover:text-gray-500"
        >
          <CircleXIcon />
        </ButtonLink>
      </div>
    </div>
  );
}
