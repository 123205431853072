import { useState } from "react";
import { Status6b5Enum } from "../../../generated/types";
import {
  CreateReferenceDocumentParams,
  updateReferenceDocument,
  UpdateReferenceDocumentParams,
} from "../../_common/api/document";
import { TFiling, TReferenceDocument } from "../../_common/api/filings";
import { Table } from "../../_common/components/Table/Table";
import {
  TableActionConfig,
  TableHeaderConfig,
} from "../../_common/components/Table/types";
import { AddReferenceCta } from "./AddReferenceCta";
import { Modal } from "../../_common/components/Modal/Modal";
import {
  initialValuesUpdate,
  ReferenceExhibitForm,
} from "./ReferenceExhibitForm";
import { isAxiosError } from "axios";
import {
  useDispatchErrorToast,
  useDispatchInfoToast,
} from "../../_common/components/Toasts/context";
import { ExhibitTypeCell } from "./ExhibitDocumentsTable/ExhibitTypeCell";
import { EditableTextCell } from "./ExhibitDocumentsTable/EditableTextCell";
import { documentSortFn } from "../../_common/utils/documentSort";

interface ReferenceDocumentsTable {
  filing: TFiling;
}

export function ReferenceDocumentsTable({ filing }: ReferenceDocumentsTable) {
  const [open, setOpen] = useState(false);
  const [editingDoc, setEditingDoc] = useState<TReferenceDocument>();
  const referenceActions: TableActionConfig<TReferenceDocument>[] = [
    {
      label: "Edit",
      callback: (doc) => {
        setEditingDoc(doc);
        setOpen(true);
      },
    },
    {
      label: "Archive",
      callback: (doc) => {
        updateReferenceDocument(doc.id, { status: Status6b5Enum.archived });
      },
    },
  ];

  function onClose() {
    setOpen(false);
    setTimeout(() => {
      setEditingDoc(undefined);
    }, 300);
  }

  const dispatchErrorToast = useDispatchErrorToast();
  const dispatchInfoToast = useDispatchInfoToast();

  async function onSubmit(
    data: UpdateReferenceDocumentParams | CreateReferenceDocumentParams,
  ) {
    if ("filing" in data || !editingDoc) {
      return;
    }
    try {
      await updateReferenceDocument(editingDoc.id, data);

      dispatchInfoToast("Reference updated successfully", 3000);

      onClose();
    } catch (e) {
      if (isAxiosError(e)) {
        dispatchErrorToast(e.message, 3000);
      }
    }
  }

  return (
    <>
      <Table
        data={filing.reference_documents}
        headers={headerConfigs}
        rowKey="id"
        sortFn={documentSortFn}
        actions={referenceActions}
        emptyState={
          <div className="pl-7 py-2">
            <AddReferenceCta filing={filing} />
          </div>
        }
      />
      <Modal open={open} onClose={() => setOpen(false)} title="Edit reference">
        <ReferenceExhibitForm
          initialValues={initialValuesUpdate(editingDoc)}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      </Modal>
    </>
  );
}

const headerConfigs: TableHeaderConfig<TReferenceDocument>[] = [
  {
    label: "File Name",
    getStringContent: (doc) => doc.linked_url || "-",
    renderCellContent: (doc) => (
      <a
        href={doc.linked_url}
        target="_blank"
        rel="noreferrer"
        className="text-brandBlue hover:underline"
      >
        {doc.linked_url?.split("/").pop()}
      </a>
    ),
    className: "w-1/6",
    hasTooltip: true,
  },
  {
    label: "Type",
    getStringContent: (doc) => doc.exhibit_type || "-",
    renderCellContent: ExhibitTypeCell,
    hasTooltip: false,
    className: "w-1/12",
  },
  {
    label: "Suffix",
    getStringContent: (doc) => doc.exhibit_type_suffix || "-",
    renderCellContent: (doc) => (
      <EditableTextCell document={doc} keyName="exhibit_type_suffix" />
    ),
    hasTooltip: false,
    className: "w-1/12",
  },
  {
    label: "Link text",
    getStringContent: (doc) => doc.link_text,
    renderCellContent: (doc) => (
      <EditableTextCell document={doc} keyName="link_text" />
    ),
    hasTooltip: true,
  },
];
