import { useQuery } from "@tanstack/react-query";
import { ChangeEvent, useRef, useState } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";

import {
  searchEditableBlockTemplates,
  TEditableBlockTemplate,
} from "../../../../_common/api/editableBlockTemplate";
import { Input } from "../../../../_common/components/Input";
import { AddBlockMultistageContextData } from "./types";
import { useMultistageFlowContext } from "../../../../_common/components/MultistageFlow/context";

export function DisclosureTypeStage() {
  const [query, setQuery] = useState("");
  const { contextData, setContextData, next } =
    useMultistageFlowContext<AddBlockMultistageContextData>();

  const { data = [] } = useQuery<TEditableBlockTemplate[]>({
    queryKey: ["editable_block_templates", query],
    queryFn: ({ queryKey }) =>
      searchEditableBlockTemplates(queryKey[1] as string),
    staleTime: Infinity,
  });

  const timeoutRef = useRef<number | undefined>(undefined);

  function handleQueryChange(e: ChangeEvent<HTMLInputElement>) {
    clearTimeout(timeoutRef.current);
    const value = e.currentTarget.value;
    timeoutRef.current = setTimeout(() => setQuery(value), 500);
  }

  function handleTemplateSelect(template: TEditableBlockTemplate) {
    setContextData({
      ...contextData,
      disclosure_type_template: template,
    });
    next();
  }

  function handleCustomSelect() {
    setContextData({
      ...contextData,
      disclosure_type_template: undefined,
    });
    next();
  }

  return (
    <div className="space-y-2">
      <h3 className="text-lg">What type of block will this be?</h3>

      <Input
        placeholder="Search disclosure types"
        onChange={handleQueryChange}
        className="w-full"
      />
      <div className="space-y-2 flex-1 overflow-auto min-w-0">
        {data.map((r) => (
          <button
            key={r.id}
            className="p-4 rounded-md border border-gray-300 hover:border-brandBlue cursor-pointer w-full"
            onClick={() => handleTemplateSelect(r)}
          >
            <span className="font-semibold">{r.default_title}</span>
          </button>
        ))}
        <button
          className="p-4 rounded-md border border-gray-300 hover:border-brandBlue cursor-pointer w-full flex items-center justify-center text-black gap-2"
          onClick={handleCustomSelect}
        >
          <PlusIcon className="size-4" />
          <span className="font-semibold">Custom</span>
        </button>
      </div>
    </div>
  );
}
