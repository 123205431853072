import { HtmlHTMLAttributes } from "react";

interface AvatarProps extends HtmlHTMLAttributes<HTMLImageElement> {
  src: string;
}

export function Avatar({ src, ...props }: AvatarProps) {
  return (
    <img
      className="inline-block size-6 rounded-full bg-gray-50"
      src={src}
      {...props}
    />
  );
}
