import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { ButtonLink } from "../_common/components/ButtonLink";
import { useState } from "react";

export function PreviewHtml() {
  const { documentId } = useParams();
  const [copied, setCopied] = useState(false);

  const { data: serializedHtml } = useQuery<string>({
    queryKey: [`constructed_primary_documents/${documentId}/serialized`],
    staleTime: 0,
  });

  return (
    <>
      <ButtonLink
        onClick={() => {
          window.navigator.clipboard.writeText(serializedHtml || "");
          setCopied(true);
          setTimeout(() => setCopied(false), 5000);
        }}
      >
        {copied ? "Copied!" : "Copy to clipboard"}
      </ButtonLink>
      <div
        dangerouslySetInnerHTML={{ __html: serializedHtml || "" }}
        className="no-twp"
      />
    </>
  );
}
