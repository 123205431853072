import { useParams } from "react-router-dom";
import { useConstructedDocument } from "./hooks/useConstructedDocument";
import { Breadcrumbs } from "../_common/components/Breadcrumbs/Breadcrumbs";
import { ID } from "../_common/types/id";
import { DocumentBuilder } from "./components/DocumentBuilder/DocumentBuilder";
import { FullscreenLoader } from "../_common/components/FullscreenLoader";
import { DocumentBuilderContextProvider } from "./components/DocumentBuilder/context";

export function ConstructedDocument() {
  const { filingId, documentId } = useParams();
  const { filing, document } = useConstructedDocument(
    filingId as ID,
    documentId as ID
  );

  if (!document || !filing) {
    return <FullscreenLoader />;
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        <Breadcrumbs className="flex-none" />

        <div className="flex-1 border-t py-4">
          <div className="flex justify-between items-center">
            <div className="flex gap-3 items-center">
              <span className="font-semibold text-xl max-w-[25ch] overflow-hidden text-ellipsis whitespace-nowrap">
                Primary Document
              </span>
            </div>
          </div>
        </div>

        <DocumentBuilderContextProvider document={document} filing={filing}>
          <DocumentBuilder document={document} />
        </DocumentBuilderContextProvider>
      </div>
    </>
  );
}
