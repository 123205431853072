import { createContext, PropsWithChildren, useContext } from "react";
import {
  TConstructedPrimaryDocument,
  TFiling,
} from "../../../_common/api/filings";

const initialContextValue = {
  document: null as TConstructedPrimaryDocument | null,
  filing: null as TFiling | null,
};

const DocumentBuilderContext = createContext(initialContextValue);

interface DocumentBuilderContextProviderProps {
  filing: TFiling;
  document: TConstructedPrimaryDocument;
}
export function DocumentBuilderContextProvider({
  children,
  document,
  filing,
}: PropsWithChildren<DocumentBuilderContextProviderProps>) {
  return (
    <DocumentBuilderContext.Provider
      value={{
        ...initialContextValue,
        document,
        filing,
      }}
    >
      {children}
    </DocumentBuilderContext.Provider>
  );
}

export function useDocumentBuilderContext() {
  return useContext(DocumentBuilderContext);
}
