import { TEntity, updateEntity } from "../../_common/api/entity";
import { EditableText } from "../../_common/components/EditableText";

interface EditableEntityTableCellProps {
  entity: TEntity;
  keyName: "name" | "cik" | "ccc";
}

export function EditableEntityTableCell({
  entity,
  keyName,
}: EditableEntityTableCellProps) {
  async function onValue(v: string) {
    await updateEntity(entity.id, { [keyName]: v.trim() });
  }

  return (
    <EditableText
      valueHandlerOnBlur={onValue}
      content={entity[keyName]}
      validator={(v) => !!v}
    />
  );
}
