import { Field } from "@headlessui/react";

import { Input } from "../../_common/components/Input";
import { Label } from "../../_common/components/Label";
import { Select } from "../../_common/components/Select";
import { FilingTypeEnum } from "../../../generated/types";
import { TFiling, updateFiling } from "../../_common/api/filings";
import { ChangeEventHandler } from "react";

interface FilingDataFormProps {
  filing: TFiling;
}
export function FilingDataForm({ filing }: FilingDataFormProps) {
  const buildChangeHandler =
    (
      fieldName: keyof TFiling
    ): ChangeEventHandler<HTMLInputElement | HTMLSelectElement> =>
    (e) => {
      const newVal = e.currentTarget.value;

      if (!newVal) return;

      const existing = filing[fieldName];
      if (newVal === existing) return;

      updateFiling(filing.id, { [fieldName]: newVal });
    };

  return (
    <div className="flex gap-4 ">
      <Field className="flex flex-1 flex-col gap-2">
        <Label>Reporting Date</Label>
        <Input
          name="reporting_date"
          defaultValue={filing.reporting_date}
          type="date"
          onChange={buildChangeHandler("reporting_date")}
        />
      </Field>
      <Field className="flex flex-1 flex-col gap-2 whitespace-nowrap">
        <Label>Filing Type</Label>
        <Select
          name="filing_type"
          defaultValue={filing.filing_type}
          onChange={buildChangeHandler("filing_type")}
        >
          <option value={FilingTypeEnum.Value8_K}>8-K</option>
          {/* <option value={FilingTypeEnum.Value8_K_A}>8-K/A </option> */}
          <option value={FilingTypeEnum.Value10_K} disabled>
            10-K
          </option>
          <option value={FilingTypeEnum.Value10_Q} disabled>
            10-Q
          </option>
        </Select>
      </Field>
      <Field className="flex flex-1 flex-col gap-2">
        <Label>Entity</Label>
        <Input
          name="Entity"
          defaultValue={filing.entity.name}
          disabled
          type="text"
        />
      </Field>
    </div>
  );
}
