import { format } from "date-fns/format";
import {
  deleteSignature,
  TSignature,
  updateSignature,
} from "../../_common/api/signatures";
import { useDocumentBuilderContext } from "./DocumentBuilder/context";
import { Spinner } from "../../_common/components/Spinner";
import { useState } from "react";
import { useDispatchErrorToast } from "../../_common/components/Toasts/context";
import { ID } from "../../_common/types/id";
import { isAxiosError } from "axios";
import { TrashIcon } from "@heroicons/react/24/outline";
import { EditableText } from "../../_common/components/EditableText";

interface EditableSignatureProps {
  signature: TSignature;
}
export function EditableSignature({ signature }: EditableSignatureProps) {
  const { filing, document } = useDocumentBuilderContext();
  const [deleting, setDeleting] = useState(false);

  const dispatchErrorToast = useDispatchErrorToast();
  async function handleDeleteSignature(sigId: ID) {
    if (!filing || !document) return;
    try {
      setDeleting(true);
      await deleteSignature(sigId, filing.id, document.id);
    } catch (err) {
      if (isAxiosError(err)) {
        dispatchErrorToast(err.message, 2000);
      }
    } finally {
      setDeleting(false);
    }
  }

  async function handleNameChange(str: string) {
    if (!filing || !document) return;
    try {
      await updateSignature(filing.id, document.id, signature.id, {
        full_name: str,
      });
    } catch (err) {
      if (isAxiosError(err)) {
        dispatchErrorToast(err.message, 2000);
      }
    }
  }

  async function handleTitleChange(str: string) {
    if (!filing || !document) return;
    try {
      await updateSignature(filing.id, document.id, signature.id, {
        title: str,
      });
    } catch (err) {
      if (isAxiosError(err)) {
        dispatchErrorToast(err.message, 2000);
      }
    }
  }

  return (
    <div className="flex justify-between items-center p-2 text-xs border border-transparent hover:border-gray-300 rounded group ">
      <p>Date: {format(new Date(signature.created_at), "MMMM dd, yyyy")}</p>

      <div className="flex gap-3">
        <div className="space-y-1">
          <div className="text-right">
            By:{" "}
            <u>
              /s/{" "}
              <EditableText
                valueHandlerOnBlur={handleNameChange}
                content={signature.full_name}
                className="inline"
              ></EditableText>
            </u>
          </div>
          <div className="text-right">
            Name:{" "}
            <EditableText
              valueHandlerOnBlur={handleNameChange}
              content={signature.full_name}
              className="inline"
            />
          </div>
          <div className="text-right">
            Title:{" "}
            <EditableText
              valueHandlerOnBlur={handleTitleChange}
              content={signature.title}
              className="inline"
            />
          </div>
        </div>
        <button
          className="rounded p-2 text-red-500 hover:text-white hover:bg-red-500 cursor-pointer opacity-0 group-hover:opacity-100 self-center"
          onClick={() => handleDeleteSignature(signature.id)}
        >
          {deleting ? (
            <Spinner color="gray" className="size-4" />
          ) : (
            <TrashIcon className="size-4" />
          )}
        </button>
      </div>
    </div>
  );
}
