import { Tooltip, TooltipContent, TooltipTrigger } from "../Tooltip";
import { TableHeaderConfig } from "./types";

interface TableCellProps<T> {
  config: TableHeaderConfig<T>;
  rowData: T;
}

export function TableCell<T>({ config, rowData }: TableCellProps<T>) {
  const stringContent = config.getStringContent(rowData);
  const content = config.renderCellContent
    ? config.renderCellContent(rowData)
    : stringContent;

  return (
    <td className="first:pl-7 last:pr-7 p-2">
      {config.hasTooltip ? (
        <Tooltip>
          <TooltipTrigger className="overflow-hidden text-ellipsis text-left max-w-full">
            {content}
          </TooltipTrigger>
          <TooltipContent className="bg-white p-2 shadow-lg rounded max-w-80 text-gray-400 text-xs">
            {stringContent}
          </TooltipContent>
        </Tooltip>
      ) : (
        <>{content}</>
      )}
    </td>
  );
}
