import { useParams } from "react-router-dom";
import { useMultistageFlowContext } from "../../../_common/components/MultistageFlow/context";
import { WrappedData } from "../../types";
import { StatBlock } from "../WrappedFlow/StatBlock/StatBlock";
import { formatStat } from "../../utils/formatStat";
import { useState } from "react";
import { GradientText } from "../GradientText/GradientText";

export function CountsBreakdown() {
  const { year, ticker } = useParams<{ year: string; ticker: string }>();
  const {
    contextData: {
      total_eight_k_filings,
      total_insider_reports_filings,
      total_filings,
    },
  } = useMultistageFlowContext<WrappedData>();
  const [emptyLogo, setEmptyLogo] = useState(false);

  return (
    <div className="flex h-full flex-col items-center justify-between pb-16 pt-6 text-center md:py-6">
      <div className="space-y-3">
        <h3 className="text-4xl uppercase md:text-5xl">
          <GradientText>
            Congratulations,
          </GradientText>
          <br />
          <span>
            {year} was a busy year for you!
          </span>
        </h3>
      </div>

      <div className="flex flex-wrap justify-center gap-4 md:flex-nowrap md:justify-between">
        <div>
          <StatBlock size="sm">{formatStat(total_filings)}</StatBlock>
          <div className="text-lg mt-6">Total EDGAR Filings</div>
        </div>
        <div>
          <StatBlock size="sm">{formatStat(total_eight_k_filings)}</StatBlock>
          <div className="text-lg mt-6 mb-1">Current Reports</div>
          <div className="text-gray-500">(8-K Filings)</div>
        </div>
        <div>
          <StatBlock size="sm">
            {formatStat(total_insider_reports_filings)}
          </StatBlock>
          <div className="text-lg mt-6 mb-1">Insider Ownership Reports</div>
          <div className="text-gray-500">(Section-16 Filings)</div>
        </div>
      </div>

      {emptyLogo ? (
        <div />
      ) : (
        <img
          src={`https://img.logo.dev/ticker/${ticker}?token=pk_Q-nim_IsRpWqIXx9w-WAxA`}
          className="hidden md:block"
          onError={() => setEmptyLogo(true)}
        />
      )}
    </div>
  );
}
