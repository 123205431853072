import { useFieldArray, useForm } from "react-hook-form";
import { useCallback } from "react";
import { Field } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { CommonModalProps, Modal } from "../../_common/components/Modal/Modal";
import { Label } from "../../_common/components/Label";
import { Input } from "../../_common/components/Input";
import { Button } from "../../_common/components/Button";
import { ButtonLink } from "../../_common/components/ButtonLink";
import { PlusIcon } from "../../_common/components/icons/PlusIcon";
import { ModalActions } from "../../_common/components/Modal/ModalActions";
import { createEntity, CreateEntityParams } from "../../_common/api/entity";
import {
  useDispatchErrorToast,
  useDispatchInfoToast,
} from "../../_common/components/Toasts/context";
import { isAxiosError } from "axios";

interface AddEntityModalProps extends CommonModalProps {}
export function AddEntityModal({ open, onClose }: AddEntityModalProps) {
  const { register, handleSubmit, formState, control, reset } =
    useForm<CreateEntityParams>({
      defaultValues: { users: [] },
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "users",
  });

  const dispatchErrorToast = useDispatchErrorToast();
  const dispatchInfoToast = useDispatchInfoToast();
  const onSubmit = useCallback(
    async (v: CreateEntityParams) => {
      try {
        await createEntity(v);
        reset();
        onClose();
        dispatchInfoToast("Created entity", 200);
      } catch (err) {
        if (isAxiosError(err)) {
          dispatchErrorToast(err.message, 3000);
        }
      }
    },
    [dispatchErrorToast, dispatchInfoToast, onClose, reset],
  );

  const isLoading = formState.isSubmitting;
  const isValid = formState.isValid;

  return (
    <Modal open={open} onClose={onClose} title="Add Entity">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <Field className="flex flex-col gap-2">
          <Label>Name</Label>
          <Input {...register("name", { required: true })} />
        </Field>
        <Field className="flex flex-col gap-2">
          <Label>CIK</Label>
          <Input {...register("cik", { required: true })} />
        </Field>
        <Field className="flex flex-col gap-2">
          <Label>CCC</Label>
          <Input {...register("ccc", { required: true })} />
        </Field>

        <Field className="flex flex-col gap-2">
          <Label>User emails</Label>
          <p className="text-xs text-gray-400">
            Users with emails entered here will be added to the entity if they
            are present in our database
          </p>

          {fields.map((f, index) => (
            <div className="flex items-center gap-2">
              <Input
                key={f.id}
                {...register(`users.${index}.email`)}
                className="w-full"
              />
              <button onClick={() => remove(index)}>
                <XMarkIcon className="size-4" />
              </button>
            </div>
          ))}

          <ButtonLink onClick={() => append({ email: "" })} type="button">
            <PlusIcon /> <span>Add</span>
          </ButtonLink>
        </Field>

        <ModalActions>
          <Button
            type="button"
            onClick={onClose}
            variant="default"
            color="gray"
          >
            Cancel
          </Button>
          <Button
            variant="default"
            color="blue"
            disabled={!isValid}
            loading={isLoading}
          >
            Save
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
}
