import { Extension } from "@tiptap/core";

export interface TextIndentOptions {
  /**
   * The types where the text indent attribute can be applied.
   * @default []
   * @example ['heading', 'paragraph']
   */
  types: string[];
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    textIndent: {
      /**
       * Unset the text align attribute
       * @example editor.commands.toggleTextIndent()
       */
      toggleTextIndent: () => ReturnType;
    };
  }
}

export const TextIndent = Extension.create<TextIndentOptions>({
  name: "textIndent",

  addOptions() {
    return {
      types: [],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          textIndent: {
            parseHTML: (element) => {
              const indentation = element.style.textIndent;

              return !!indentation;
            },

            renderHTML: (attributes) => {
              if (!attributes.textIndent) {
                return {};
              }

              return {
                style: `text-indent: 24px`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      toggleTextIndent:
        () =>
        ({ commands }) => {
          if (this.editor.isActive({ textIndent: true })) {
            return this.options.types
              .map((t) => commands.resetAttributes(t, "textIndent"))
              .every((r) => r);
          } else {
            return this.options.types
              .map((t) => commands.updateAttributes(t, { textIndent: true }))
              .every((r) => r);
          }
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Shift-Tab": () => this.editor.commands.toggleTextIndent(),
    };
  },
});
