import { QueryClient, QueryFunction } from "@tanstack/react-query";
import axiosBase, { AxiosError, isAxiosError } from "axios";
import { z } from "zod";
import { getCookie } from "../utils/cookie";

export const axios = axiosBase.create({
  baseURL: "/api/",
});
axios.interceptors.request.use((config) => {
  const csrftoken = getCookie("csrftoken");
  config.headers["X-CSRFToken"] = csrftoken;

  return config;
});

const detailedErrorPayload = z.record(
  z.string(),
  z.record(z.string(), z.array(z.string()))
);

function unpackAxiosError(error: AxiosError) {
  const parsed = detailedErrorPayload.safeParse(error.response?.data);
  if (!parsed.success) {
    return error.message;
  }
  const errors = Object.values(parsed.data);
  let resString = "";

  for (const error of errors) {
    const arrayFields = Object.values(error).filter((v) => Array.isArray(v));
    if (arrayFields.length > 0) {
      resString += "\n";
      resString += arrayFields.join(",");
    }
  }

  return resString || error.message;
}
axios.interceptors.response.use(undefined, (error) => {
  if (isAxiosError(error)) {
    error.message = unpackAxiosError(error);
  }

  return Promise.reject(error);
});

const defaultQueryFn: QueryFunction = async ({ queryKey }) => {
  const { data } = await axios.get(`${queryKey[0]}/${queryKey[1] || ""}`);

  return data;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
      queryFn: defaultQueryFn,
    },
  },
});
