import { ButtonHTMLAttributes } from "react";
import clsx, { ClassValue } from "clsx";
import { Spinner } from "./Spinner";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: "outline" | "default";
  color: "gray" | "blue" | "unstyled";
  loading?: boolean;
}

const buttonVariantColorStyles: Record<
  ButtonProps["color"],
  Record<ButtonProps["variant"], ClassValue>
> = {
  gray: {
    outline:
      "bg-transparent border-2 border-buttonGray text-gray-500 hover:bg-buttonGray",
    default: "bg-buttonGray text-black/50 hover:bg-buttonGray/80",
  },
  blue: {
    outline:
      "bg-transparent text-brandBlue border-2 border-brandBlue hover:text-white hover:bg-brandBlue",
    default: "bg-brandBlue text-white hover:bg-brandBlue/80",
  },
  unstyled: {
    outline: "",
    default: "",
  },
};

export function Button({
  children,
  variant,
  color,
  loading,
  className,
  ...props
}: ButtonProps) {
  return (
    <button
      className={clsx(
        "rounded-md text-center h-9 px-4 text-sm disabled:opacity-50 disabled:cursor-not-allowed",
        buttonVariantColorStyles[color][variant],
        className
      )}
      disabled={loading}
      {...props}
    >
      {loading ? <Spinner color="gray" className="size-5 m-auto" /> : children}
    </button>
  );
}
