export function FilingCompleteGraphic() {
  return (
    <svg
      width="102"
      height="102"
      viewBox="0 0 102 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.1111 51L45.7778 66.6667L71.8889 35.3333M98 51C98 76.9574 76.9574 98 51 98C25.0426 98 4 76.9574 4 51C4 25.0426 25.0426 4 51 4C76.9574 4 98 25.0426 98 51Z"
        stroke="url(#paint0_linear_58_1118)"
        strokeWidth="7"
      />
      <defs>
        <linearGradient
          id="paint0_linear_58_1118"
          x1="98.4747"
          y1="3.52525"
          x2="4.47475"
          y2="97.5253"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA500" />
          <stop offset="1" stopColor="#32AAE3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
