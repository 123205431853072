import { ExclamationCircleIcon } from "@heroicons/react/16/solid";
import { BaseToast } from "./BaseToast";

export interface ErrorToastProps {
  content: string;
  intent: "error";
  autoDismissAfter?: number;
}

export function ErrorToast({ content }: ErrorToastProps) {
  return (
    <BaseToast>
      <div className="flex items-center gap-2">
        <ExclamationCircleIcon className="size-6 text-red-500" />
        <span>{content}</span>
      </div>
    </BaseToast>
  );
}
