import { Outlet } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import queryString from "query-string";

import { Sidebar } from "./Sidebar";
import { ToastContextProvider } from "./Toasts/context";
import { Toasts } from "./Toasts/Toasts";

export function Layout() {
  return (
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        searchStringToObject: queryString.parse,
        objectToSearchString: queryString.stringify,
      }}
    >
      <ToastContextProvider>
        <div className="flex h-screen">
          <div className="flex-0 border-r-2">
            <Sidebar />
          </div>

          <div className="flex w-full flex-col">
            <main
              className="relative flex-1 overflow-auto bg-bgSecondary px-12 py-6"
              id="main-content-panel"
            >
              <Outlet />
            </main>
          </div>
          <Toasts />
        </div>
      </ToastContextProvider>
    </QueryParamProvider>
  );
}
