import { Table } from "../../_common/components/Table/Table";
import { TableHeaderConfig } from "../../_common/components/Table/types";

interface Item {
  title: string;
  description: string;
  body: string;
}

interface ItemsTableProps {
  items: Item[];
}

export function ItemsTable({ items }: ItemsTableProps) {
  return (
    <Table
      data={items}
      headers={headerConfigs}
      rowKey="id"
      actions={[]}
      emptyState={<div className="py-2 pl-7">No Items Found</div>}
    />
  );
}

const headerConfigs: TableHeaderConfig<Item>[] = [
  {
    label: "Items",
    getStringContent: (item) => item.body,
    hasTooltip: false,
  },
];
