import { ID } from "../types/id";
import { axios } from "./client";
import { CreateEditableBlockParams } from "./editableBlock";

interface GenerateContentForBlockParams {
  title: string;
  description: string; // For template specific descriptions to help give the LLM more context
  user_input: string;
  entity_id: ID;
}
export async function generateContentForBlock(
  params: GenerateContentForBlockParams
) {
  const { data } = await axios.post<CreateEditableBlockParams>(
    "llm/generate_block_content/",
    params
  );

  return data;
}
