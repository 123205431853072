import { useQuery } from "@tanstack/react-query";
import { ID } from "../../_common/types/id";
import { filingQueryKey, TFiling } from "../../_common/api/filings";
import { FullscreenLoader } from "../../_common/components/FullscreenLoader";
import { DocumentHtmlViewer } from "../../Document/components/DocumentHtmlViewer/DocumentHtmlViewer";
import { ChangeEventHandler, useState } from "react";
import { Select } from "@headlessui/react";
import { DocumentTypeEnum } from "../../../generated/types";

interface FilingPreviewProps {
  filingId: ID;
}
export function FilingPreview({ filingId }: FilingPreviewProps) {
  const { data } = useQuery<TFiling>({ queryKey: [filingQueryKey(filingId)] });
  const [documentIndex, setDocumentIndex] = useState(0);

  const handleDocumentSelectChange: ChangeEventHandler<HTMLSelectElement> = (
    e
  ) => {
    setDocumentIndex(parseInt(e.currentTarget.value));
  };

  if (!data) return <FullscreenLoader />;

  const sortedDocuments = data.documents.sort((a) =>
    a.document_type === DocumentTypeEnum.primary_document ? -1 : 1
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between items-center">
        <strong>Filing Preview</strong>

        <div className="text-gray-400 text-sm">
          <span>Jump To:</span>
          <Select
            value={documentIndex}
            onChange={handleDocumentSelectChange}
            className="text-gray-500 font-semibold bg-transparent"
          >
            {sortedDocuments.map((d, i) => (
              <option key={d.id} value={i}>
                {/* @ts-expect-error - metadata is untyped */}
                {d.metadata?.name}
              </option>
            ))}
          </Select>
        </div>
      </div>

      <DocumentHtmlViewer document={sortedDocuments[documentIndex]} />
    </div>
  );
}
