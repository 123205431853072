import {
  Context,
  createContext,
  FunctionComponent,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useDispatchErrorToast } from "../Toasts/context";
import { isAxiosError } from "axios";

export interface StageConfig {
  id: string;
  component: FunctionComponent;
}

interface MultistageFlowContextValue<ContextData> {
  contextData: ContextData;
  currentStage: StageConfig;
  stages: StageConfig[];
  next: () => void;
  previous: () => void;
  hasNext: boolean;
  hasPrevious: boolean;
  goTo: (stage: string) => void;
  setContextData: React.Dispatch<SetStateAction<ContextData>>;
  complete: () => void;
  submitting: boolean;
}

const MultistageFlowContext =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createContext<MultistageFlowContextValue<any> | null>(null);

export function useMultistageFlowContext<ContextData>() {
  return useContext<MultistageFlowContextValue<ContextData>>(
    MultistageFlowContext as Context<MultistageFlowContextValue<ContextData>>
  );
}

interface MultistageFlowContextProviderProps<ContextData> {
  stages: StageConfig[];
  initialContextData: ContextData;
  onComplete: (t: ContextData) => void;
}
export function MultistageFlowContextProvider<ContextData>({
  stages,
  children,
  initialContextData,
  onComplete,
}: PropsWithChildren<MultistageFlowContextProviderProps<ContextData>>) {
  const [currentStage, setCurrentStage] = useState(stages[0]);
  const [contextData, setContextData] = useState(initialContextData);

  const hasNext = useMemo(() => {
    const currentIndex = stages.findIndex((s) => s.id === currentStage.id);
    return currentIndex < stages.length - 1;
  }, [currentStage.id, stages]);

  const hasPrevious = useMemo(() => {
    const currentIndex = stages.findIndex((s) => s.id === currentStage.id);

    return currentIndex !== 0;
  }, [currentStage.id, stages]);

  const next = useCallback(() => {
    setCurrentStage((current) => {
      const indexOf = stages.findIndex((s) => s.id === current.id);
      const nextId = stages[indexOf + 1];

      if (!nextId) return current;
      return nextId;
    });
  }, [stages]);

  const previous = useCallback(() => {
    setCurrentStage((current) => {
      const indexOf = stages.findIndex((s) => s.id === current.id);
      const prevId = stages[indexOf - 1];

      if (!prevId) return current;

      return prevId;
    });
  }, [stages]);

  const goTo = useCallback(
    (stageId: string) => {
      const stage = stages.find((s) => s.id === stageId);
      if (!stage) return;

      setCurrentStage(stage);
    },
    [stages]
  );

  const dispatchErrorToast = useDispatchErrorToast();
  const [submitting, setSubmitting] = useState(false);
  const complete = useCallback(async () => {
    setSubmitting(true);

    try {
      await onComplete(contextData);
    } catch (err) {
      if (isAxiosError(err)) {
        dispatchErrorToast(err.message);
      }
    } finally {
      setSubmitting(false);
    }
  }, [contextData, dispatchErrorToast, onComplete]);

  return (
    <MultistageFlowContext.Provider
      value={{
        previous,
        next,
        goTo,
        stages,
        currentStage,
        contextData,
        setContextData,
        hasNext,
        hasPrevious,
        complete,
        submitting,
      }}
    >
      {children}
    </MultistageFlowContext.Provider>
  );
}
