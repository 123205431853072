import { components } from "../../../generated/types";
import { getConstructedDocumentFromFiling } from "../../ConstructedDocument/hooks/useConstructedDocument";
import { ID } from "../types/id";
import {
  listToNormalized,
  normalizedToList,
  upsertToNormalized,
} from "../utils/normalized";
import { axios, queryClient } from "./client";
import {
  filingQueryKey,
  TConstructedPrimaryDocument,
  TFiling,
} from "./filings";

export type TSignature = components["schemas"]["ConstructedDocumentSignature"];

export interface SignatureData {
  full_name: string;
  title: string;
}
export async function addSignatureToDocument(
  documentId: ID,
  filingId: ID,
  params: SignatureData
) {
  const { data } = await axios.post(
    `constructed_primary_document_signatures/`,
    {
      ...params,
      document: documentId,
    }
  );

  _upsertSignatureInCache(data, filingId, documentId);

  return data;
}

export async function deleteSignature(
  signatureId: ID,
  filingId: ID,
  documentId: ID
) {
  await axios.delete(`constructed_primary_document_signatures/${signatureId}/`);

  _removeSignatureFromCache(signatureId, filingId, documentId);
}

export async function updateSignature(
  filingId: ID,
  documentId: ID,
  signatureId: ID,
  updateParams: Partial<SignatureData>
) {
  const { data } = await axios.patch(
    `constructed_primary_document_signatures/${signatureId}/`,
    updateParams
  );

  _upsertSignatureInCache(data, filingId, documentId);

  return data;
}

function _upsertSignatureInCache(
  signature: TSignature,
  filingId: ID,
  documentId: ID
) {
  const existingFilingData = queryClient.getQueryData<TFiling>([
    filingQueryKey(filingId),
  ]);
  if (!existingFilingData) return;

  const existingDocumentData = getConstructedDocumentFromFiling(
    existingFilingData,
    documentId
  );
  if (!existingDocumentData) return;

  let normSignatures = listToNormalized(
    existingDocumentData.signatures,
    (k) => k.id
  );

  normSignatures = upsertToNormalized(normSignatures, signature, (k) => k.id);

  const newDocumentData: TConstructedPrimaryDocument = {
    ...existingDocumentData,
    signatures: normalizedToList(normSignatures),
  };

  const updatedConstructedPrimaryDocuments =
    existingFilingData.constructed_primary_documents.map((pd) => {
      if (pd.id === newDocumentData.id) return newDocumentData;

      return pd;
    });

  queryClient.setQueryData([filingQueryKey(filingId)], {
    ...existingFilingData,
    constructed_primary_documents: updatedConstructedPrimaryDocuments,
  });
}

function _removeSignatureFromCache(
  signatureId: ID,
  filingId: ID,
  documentId: ID
) {
  const existingFilingData = queryClient.getQueryData<TFiling>([
    filingQueryKey(filingId),
  ]);
  if (!existingFilingData) return;

  const existingDocumentData = getConstructedDocumentFromFiling(
    existingFilingData,
    documentId
  );
  if (!existingDocumentData) return;

  const newSignatures = existingDocumentData.signatures.filter(
    (s) => s.id !== signatureId
  );

  const newDocumentData: TConstructedPrimaryDocument = {
    ...existingDocumentData,
    signatures: newSignatures,
  };

  const updatedConstructedPrimaryDocuments =
    existingFilingData.constructed_primary_documents.map((pd) => {
      if (pd.id === newDocumentData.id) return newDocumentData;

      return pd;
    });

  queryClient.setQueryData([filingQueryKey(filingId)], {
    ...existingFilingData,
    constructed_primary_documents: updatedConstructedPrimaryDocuments,
  });
}
