import { axios } from "../../_common/api/client";

export async function getSecFilingsDump(accession_number: string) {
  const res = await axios.post(
    "sec/get_filing_dump/",
    {
      accession_number,
    },
    { responseType: "blob" }
  );

  const href = URL.createObjectURL(res.data);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", `${accession_number}.zip`); //or any other extension
  document.body.appendChild(link);
  link.click();
  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);

  return res.data;
}
