import { Table } from "../../../_common/components/Table/Table";
import { useStubbedData } from "../../hooks/useStubbedData";

export function SecuritiesPanel() {
  const { securityTypes } = useStubbedData();

  return (
    <div className="p-4">
      <div className="space-y-4">
        <h3 className="font-semibold">Security Types</h3>

        <Table
          data={securityTypes}
          actions={[]}
          rowKey="id"
          emptyState="No security types"
          headers={[
            {
              label: "Title",
              getStringContent: (d) => d.title,
              hasTooltip: false,
            },
            {
              label: "Type",
              getStringContent: (d) =>
                d.derivesFromId ? "Derivative" : "Non-Derivative",
              hasTooltip: false,
            },
          ]}
        />
      </div>
    </div>
  );
}
