import { BaseToast } from "./BaseToast";

export interface InfoToastProps {
  content: string;
  intent: "info";
  autoDismissAfter?: number;
}
export function InfoToast({ content }: InfoToastProps) {
  return <BaseToast>{content}</BaseToast>;
}
