import { useQuery } from "@tanstack/react-query";
import {
  ArrayParam,
  DelimitedNumericArrayParam,
  NumberParam,
  useQueryParams,
} from "use-query-params";
import queryString from "query-string";
import { useEffect, useMemo } from "react";

import { TPaginatedFilingList } from "../../../_common/api/filings";
import { FilingsListItem } from "./FilingsListItem";
import { Pagination } from "../../../_common/components/Pagination/Pagination";
import { RadioPill } from "../../../_common/components/RadioPill";
import { useForm } from "react-hook-form";
import { Status101Enum } from "../../../../generated/types";

export function FilingsList() {
  const [params, setParams] = useQueryParams({
    entity_id: DelimitedNumericArrayParam,
    status: ArrayParam,
    page: NumberParam,
  });

  const { data } = useQuery<TPaginatedFilingList>({
    queryKey: ["filings", `?${queryString.stringify(params)}`],
  });

  const totalPages = useMemo(() => {
    return Math.ceil((data?.count || 10) / 10);
  }, [data?.count]);

  const { watch, handleSubmit, register } = useForm<{ status: string[] }>({
    defaultValues: { status: [] },
  });

  useEffect(() => {
    const sub = watch(() => {
      handleSubmit(({ status }) => {
        setParams({ status });
      })();
    });
    return () => sub.unsubscribe();
  }, [handleSubmit, setParams, watch]);

  return (
    <div className="space-y-2">

      <div className="rounded border bg-white">
        <div className="flex items-center justify-between overflow-hidden border-b p-4">
          <h3>Recent Activity</h3>

          <form
            className="flex items-center gap-2"
            onSubmit={handleSubmit(() => null)}
          >
            <RadioPill
              color="blue"
              label="In Progress"
              type="checkbox"
              value={Status101Enum.draft}
              {...register("status")}
            />
            <RadioPill
              color="yellow"
              label="In Review"
              type="checkbox"
              value={Status101Enum.in_review}
              {...register("status")}
            />
            <RadioPill
              color="purple"
              label="Scheduled"
              type="checkbox"
              value={Status101Enum.scheduled}
              {...register("status")}
            />
            <RadioPill
              color="green"
              label="Filed"
              type="checkbox"
              value={Status101Enum.filed}
              {...register("status")}
            />
          </form>
        </div>

        <div className="max-h-[500px] divide-y divide-gray-300 overflow-auto">
          {data?.results.map((f) => <FilingsListItem filing={f} key={f.id} />)}
          {!data?.results.length && <div className="p-4">No Activity</div>}
        </div>
      </div>

      <Pagination totalPages={totalPages} />
    </div>
  );
}
