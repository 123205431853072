import { Fragment, HtmlHTMLAttributes, ReactNode } from "react";
import { UIMatch, useMatches } from "react-router-dom";
import clsx from "clsx";

export interface BreadCrumbHandle<T = unknown> {
  crumb: (filing: T) => ReactNode;
}

interface BreadcrumbsProps extends HtmlHTMLAttributes<HTMLOListElement> {}

export function Breadcrumbs<T = unknown>({
  className,
  ...props
}: BreadcrumbsProps) {
  const matches = useMatches() as UIMatch<T, BreadCrumbHandle>[];
  const crumbs = matches
    .filter((m) => m.handle?.crumb)
    .map((m) => m.handle.crumb(m.data))
    .flat();

  return (
    <ol
      className={clsx(
        "flex items-center gap-1 font-medium text-xs text-gray-400",
        className
      )}
      {...props}
    >
      {crumbs.map((crumb, index) => (
        <Fragment key={index}>
          <li className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[30ch]">
            {crumb}
          </li>
          {index < crumbs.length - 1 && <span>&gt;</span>}
        </Fragment>
      ))}
    </ol>
  );
}
