import { FunctionComponent, PropsWithChildren, useMemo } from "react";
import {
  MultistageFlowContextProvider,
  StageConfig,
  useMultistageFlowContext,
} from "./context";
import { Button } from "../Button";
import clsx from "clsx";

interface MultistageFlowProps<ContextData, ResData = unknown> {
  initialContextData: ContextData;
  stages: StageConfig[];
  layout?: FunctionComponent<PropsWithChildren>;
  onComplete: (t: ContextData) => Promise<ResData>;
}

export function MultistageFlow<ContextData, ResData = unknown>({
  initialContextData,
  stages,
  layout: Layout,
  onComplete,
}: MultistageFlowProps<ContextData, ResData>) {
  return (
    <MultistageFlowContextProvider
      stages={stages}
      initialContextData={initialContextData}
      onComplete={onComplete}
    >
      {Layout ? (
        <Layout>
          <MultistageFlowContent />
        </Layout>
      ) : (
        <MultistageFlowContent />
      )}
    </MultistageFlowContextProvider>
  );
}

function getTransformValue(currentIndex: number, index: number) {
  if (currentIndex === index) return undefined;
  if (currentIndex > index) return "translateX(-1000px)";
  return "translateX(1000px)";
}

export function MultistageFlowContent() {
  const { currentStage, stages } = useMultistageFlowContext();

  const currentStageIndex = useMemo(() => {
    return stages.findIndex((s) => s.id === currentStage.id);
  }, [currentStage.id, stages]);

  return (
    <div className="relative h-full w-full overflow-hidden">
      {stages.map((s, i) => (
        <div
          className="absolute inset-0 h-full w-full transition-all duration-500"
          style={{
            transform: getTransformValue(currentStageIndex, i),
            opacity: currentStageIndex === i ? undefined : 0,
          }}
        >
          <s.component />
        </div>
      ))}
    </div>
  );
}

interface MultistageFlowNavButtonsProps {
  currentStageValid: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  className?: string;
}
export function MultistageFlowNavButtons({
  children,
  currentStageValid,
  onNext,
  onPrevious,
  className,
}: PropsWithChildren<MultistageFlowNavButtonsProps>) {
  const { next, previous, hasNext, hasPrevious, submitting } =
    useMultistageFlowContext();
  return (
    <div className={clsx("flex items-center gap-2", className)}>
      {hasPrevious && (
        <Button
          className="flex-1"
          variant="default"
          color="gray"
          onClick={onPrevious || previous}
          type="button"
        >
          Previous
        </Button>
      )}
      {hasNext && (
        <Button
          className="flex-1"
          variant="default"
          color="blue"
          onClick={onNext || next}
          disabled={!currentStageValid}
          loading={submitting}
          type="button"
        >
          Next
        </Button>
      )}
      {children}
    </div>
  );
}
