import {
  components,
  FilingTypeEnum,
  Status101Enum,
} from "../../../generated/types";
import { ID } from "../types/id";
import { axios, queryClient } from "./client";

export type TFiling = components["schemas"]["FilingDetail"];
export type TFilingListFiling = components["schemas"]["FilingList"];
export type TConstructedPrimaryDocument =
  TFiling["constructed_primary_documents"][number];
export type TDocument = TFiling["documents"][number];
export type TReferenceDocument = TFiling["reference_documents"][number];

export function filingQueryKey(id: ID) {
  return `filings/${id}`;
}
export async function getFiling(id: ID) {
  return queryClient.fetchQuery<TFiling>({ queryKey: [filingQueryKey(id)] });
}

export type TPaginatedFilingList =
  components["schemas"]["PaginatedFilingListList"];

export async function listFilings() {
  return queryClient.fetchQuery<TPaginatedFilingList>({
    queryKey: [`filings`],
  });
}

export interface CreateFilingParams {
  uploaded_primary_document?: File;
  name: string;
  filing_type: FilingTypeEnum;
  entity_id: number;
}
export async function createFiling(params: CreateFilingParams) {
  const fd = new FormData();
  fd.append("status", Status101Enum.draft);
  fd.append("name", params.name);
  fd.append("filing_type", params.filing_type);
  fd.append("entity_id", params.entity_id.toString());
  if (params.uploaded_primary_document) {
    fd.append("uploaded_primary_document", params.uploaded_primary_document)
  }

  const { data } = await axios.post("filings/", fd);

  return data;
}

export interface UpdateFilingParams {
  name?: string;
  reportingDate?: string;
  filingType?: FilingTypeEnum;
  status?: Status101Enum;
}
export async function updateFiling(id: number, params: UpdateFilingParams) {
  const { data } = await axios.patch<TFiling>(`filings/${id}/`, params);

  queryClient.setQueryData([`filings/${id}`], data);
  const existingFilingsList = queryClient.getQueryData<TPaginatedFilingList>([
    "filings",
  ]);

  if (data.status === Status101Enum.archived && existingFilingsList) {
    queryClient.setQueryData(["filings"], {
      ...existingFilingsList,
      count: existingFilingsList.count - 1,
      results: existingFilingsList.results.filter((f) => f.id !== data.id),
    });
  }

  return data;
}

export function isConstructedPrimaryDocument(
  doc: TDocument | TConstructedPrimaryDocument
): doc is TConstructedPrimaryDocument {
  if ("filing" in doc) {
    return true;
  }

  return false;
}
