import { useState } from "react";

import { AddFilingModal } from "../_common/components/AddFilingModal/AddFilingModal";
import { FilingTypeEnum } from "../../generated/types";
import { useCurrentUser } from "../_common/hooks/useCurrentUser";
import { FilingsList } from "./components/FilingsList/FilingsList";
import { ButtonLink } from "../_common/components/ButtonLink";
import { PlusIcon } from "../_common/components/icons/PlusIcon";
import { Link } from "react-router-dom";
import {
  BellIcon,
  DocumentIcon,
  PaperClipIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { FilingStats } from "./components/FilingStats";
import { DelimitedNumericArrayParam, useQueryParams } from "use-query-params";
import { Multiselect } from "../_common/components/Multiselect";
import { Logo } from "../_common/components/Logo";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../_common/components/Tooltip";
import { FullscreenLoader } from "../_common/components/FullscreenLoader";

export function Filings() {
  const user = useCurrentUser();
  const [, setParams] = useQueryParams({
    entity_id: DelimitedNumericArrayParam,
  });
  const [addFilingModalOpen, setAddFilingModalOpen] = useState(false);

  const companies = [
    {
      name: "Apple",
      full_message:
        "Apple announced Q3 2024 earnings of $85.8 billion, led by record services revenue and iPad sales growth, despite a slight dip in iPhone sales.",
      message: "Q3 earnings announced, investor call summary",
      time: "2 hours ago",
      link: "https://www.sec.gov/ix?doc=/Archives/edgar/data/320193/000032019324000080/aapl-20240801.htm",
    },
    {
      name: "Adobe",
      full_message:
        "Adobe and Figma mutually ended their merger agreement, with Adobe paying Figma a $1 billion termination fee as the sole financial obligation.",
      message:
        "Proposed merger terminated in light of intense regulatory scrutiny",
      time: "5 hours ago",
      link: "https://www.sec.gov/ix?doc=/Archives/edgar/data/0000796343/000079634323000254/adbe-20231217.htm",
    },
    {
      name: "loanDepot",
      full_message:
        "loanDepot reported a cybersecurity breach involving data encryption and unauthorized access. It has secured systems and is investigating the incident's impact.",
      message: "Cybersecurity breach disclosed, material impacts not expected",
      time: "1 day ago",
      link: "https://www.sec.gov/ix?doc=/Archives/edgar/data/1831631/000183163124000004/ldi-20240104.htm",
    },
  ];

  // const fakeUpdates = useMemo(() => {
  //   return [
  //     {
  //       message: "Q3 earnings announced, investor call summary",
  //       time: "2 hours ago",
  //     },
  //     {
  //       message:
  //         "Proposed merger terminated in light of intense regulatory scrutiny",
  //       time: "5 hours ago",
  //     },
  //     {
  //       message:
  //         "Cybersecurity breach disclosed, material impacts not expected",
  //       time: "1 day ago",
  //     },
  //   ].map((u, i) => ({
  //     ...u,
  //     entity: user.entity_set[i] || user.entity_set[0],
  //   }));
  // }, [user.entity_set]);

  if (!user) return <FullscreenLoader />;

  const entity_id = user?.entity_set[0]?.id;

  return (
    <>
      <div className="absolute left-0 top-0 w-full bg-yellow-50/50">
        <div className="p-8">
          <div className="flex items-center justify-between">
            <div className="space-y-2">
              <div className="flex items-center gap-4">
                <Logo />
                <h1 className="text-xl font-semibold text-gray-900">
                  Securities Filing Dashboard
                </h1>
              </div>

              <div className="flex items-center gap-4">
                {user.entity_set.length > 1 && (
                  <Multiselect
                  options={user.entity_set}
                  defaultValue={[]}
                  onChange={(v) => {
                    setParams({ entity_id: v.map((d) => d.id) });
                  }}
                  // TOD: This is a hack for now and expects securities in entity management to be filled out
                  getDisplayString={(v) => {
                    const ticker = (v.securities?.length || 0) > 0 ? ` (${v.securities?.[0].ticker})` : "";
                    return `${v.name}${ticker}`;
                  }}
                  getId={(v) => v.id}
                  placeholder="Select Client"
                  />
                )}
              </div>
            </div>

            <ButtonLink
              onClick={() => setAddFilingModalOpen(true)}
              className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            >
              <PlusIcon className="mr-2 h-3 w-5" />
              <span>Create Filing</span>
            </ButtonLink>
          </div>
        </div>
      </div>
      <div className="mt-12 grid grid-cols-4 gap-4 pt-20">
        <div className="col-span-4">
          <FilingStats />
        </div>
        <div className="col-span-3">
          <FilingsList />
        </div>
        <div className="col-span-1 flex flex-col gap-4">
          <div className="rounded border bg-white">
            <div className="flex items-center gap-2 border-b p-4 font-semibold">
              <BellIcon className="size-5 text-yellow-600" />
              <span>Client Updates</span>
            </div>

            <div className="divide-y">
              {companies.map((fu) => (
                <Tooltip>
                  <TooltipTrigger className="max-w-full overflow-hidden text-ellipsis text-left">
                    <a href={fu.link} target="_blank">
                      <div key={fu.time} className="p-4">
                        <p className="text-sm">{fu.name}</p>
                        <p className="text-xs text-gray-400">{fu.message}</p>
                        <p className="text-[10px] text-gray-400">{fu.time}</p>
                      </div>
                    </a>
                  </TooltipTrigger>
                  <TooltipContent className="max-w-80 rounded bg-white p-2 text-xs text-gray-400 shadow-lg">
                    {fu.full_message}
                  </TooltipContent>
                </Tooltip>
              ))}
            </div>
          </div>
          <div className="rounded border bg-white">
            <div className="border-b p-2 font-semibold">Quick Links</div>

            <ul className="space-y-1 p-2 px-4">
              <li>
                <a
                  href="https://www.sec.gov/search-filings"
                  className="text-s inline-flex items-center gap-2 font-semibold text-brandBlue hover:underline"
                  target="_blank"
                >
                  <PaperClipIcon className="size-5" />
                  <span>SEC Edgar Search</span>
                </a>
              </li>
              <li>
                <Link
                  to="/entities"
                  className="text-s inline-flex items-center gap-2 font-semibold text-brandBlue hover:underline"
                  target="_blank"
                >
                  <UserGroupIcon className="size-5" />
                  <span>Client Directory</span>
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.wilmerhale.com/-/media/files/shared_content/editorial/publications/documents/20231115-keeping-current-with-form-8-k-a-practical-guide-2023-update.pdf"
                  className="text-s inline-flex items-center gap-2 font-semibold text-brandBlue hover:underline"
                  target="_blank"
                >
                  <DocumentIcon className="size-5" />
                  Filing Templates
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <AddFilingModal
        initialValues={{
          filing_type: FilingTypeEnum.Value8_K,
          name: "",
          entity_id,
        }}
        open={addFilingModalOpen}
        onClose={() => setAddFilingModalOpen(false)}
      />
    </>
  );
}
