import { forwardRef } from "react";
import { Select as HSelect, SelectProps } from "@headlessui/react";
import clsx from "clsx";

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <HSelect
        className={clsx(
          "outline outline-1 outline-gray-300 rounded px-2 h-11 text-sm border-r-8 border-transparent",
          className
        )}
        ref={ref}
        {...props}
      >
        {children}
      </HSelect>
    );
  }
);
