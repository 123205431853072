import { useMemo } from "react";
import { useMultistageFlowContext } from "../../../_common/components/MultistageFlow/context";

import { WrappedData } from "../../types";
import { StatBlock } from "../WrappedFlow/StatBlock/StatBlock";
import gradientStar from "./BusiestMonth/gradientStar.png";
import { Heading } from "../WrappedFlow/Heading";
import { GradientText } from "../GradientText/GradientText";

export function BusiestMonth() {
  const {
    contextData: { busiest_month_name, busiest_month_filings_count },
  } = useMultistageFlowContext<WrappedData>();

  const copy = useMemo(() => {
    if (busiest_month_filings_count >= 17) {
      return `${busiest_month_filings_count} filings? EDGAR’s new best friend! Let’s aim for less ‘together time’ next year.`;
    }
    if (busiest_month_filings_count >= 13) {
      return `${busiest_month_filings_count} filings? You’ve definitely earned a break… maybe even from seeing proofs in your inbox.`;
    }
    if (busiest_month_filings_count >= 9) {
      return `${busiest_month_filings_count} filings? Wow, when did you sleep, or did you just dream about proofs?`;
    }
    if (busiest_month_filings_count >= 5) {
      return `${busiest_month_filings_count} filings on EDGAR? Proofs everywhere! You must be a master juggler by now.`;
    }
    `${busiest_month_filings_count} filings on EDGAR? Light work—but we both know it felt like a marathon!`;
    return;
  }, [busiest_month_filings_count]);

  return (
    <div className="mx-auto flex h-full flex-col justify-between pb-16 pt-6 text-center md:py-6">
      <div className="space-y-2 text-center hidden md:block">
        <Heading size="lg">
          You had some busy{" "}
          <GradientText>
            months
          </GradientText>
          , but you outdid yourself in
        </Heading>
      </div>

      <div className="md:hidden uppercase text-3xl">
        Your busiest month was
      </div>

      <div className="flex items-center flex-col md:flex-row gap-6 md:items-start md:justify-center md:gap-20">
        <div className="relative flex size-44 items-center justify-center md:size-96">
          <img
            src={gradientStar}
            className="absolute inset-0 size-full animate-slowSpin object-contain"
          />
          <div className="z-10 text-2xl uppercase text-[#8E3FDA] md:text-3xl">
            {busiest_month_name}
          </div>
        </div>

        <div className="max-w-min space-y-6 text-center">
          <StatBlock size="sm">{busiest_month_filings_count}</StatBlock>
          <div className="md:text-base text-sm">{copy}</div>
        </div>
      </div>

      <div className="md:text-xl text-lg">
        Busy months are always stressful,
        <br />
        that's why we built Fyler
      </div>
    </div>
  );
}
