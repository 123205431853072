export function omit<
  OT extends Record<string | number, unknown>,
  KT extends keyof OT,
>(obj: OT, key: KT): Omit<OT, KT> {
  const res = {
    ...obj,
  };

  delete res[key];

  return res;
}
