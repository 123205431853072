import clsx from "clsx";
import { ButtonHTMLAttributes, forwardRef } from "react";

interface ButtonLinkProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const ButtonLink = forwardRef<HTMLButtonElement, ButtonLinkProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={clsx(
          "text-brandBlue hover:underline font-medium text-sm flex gap-1 items-center whitespace-nowrap",
          {
            "cursor-not-allowed opacity-60": props.disabled,
            "cursor-pointer": !props.disabled,
          },
          className
        )}
        {...props}
      >
        {children}
      </button>
    );
  }
);
