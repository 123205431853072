import { useState } from "react";
import { TEntity, updateEntity } from "../../_common/api/entity";
import { ButtonLink } from "../../_common/components/ButtonLink";
import { Modal } from "../../_common/components/Modal/Modal";
import { Input } from "../../_common/components/Input";
import { Field } from "@headlessui/react";
import { Label } from "../../_common/components/Label";
import { Select } from "../../_common/components/Select";
import { useFieldArray, useForm } from "react-hook-form";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { ModalActions } from "../../_common/components/Modal/ModalActions";
import { Button } from "../../_common/components/Button";
import { useDispatchErrorToast } from "../../_common/components/Toasts/context";
import { isAxiosError } from "axios";

interface EntitySecuritiesCellProps {
  entity: TEntity;
}

export function EntitySecuritiesCell({ entity }: EntitySecuritiesCellProps) {
  const [editing, setEditing] = useState(false);
  const { register, handleSubmit, control, reset, formState } = useForm({
    defaultValues: {
      securities: entity.securities,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "securities",
  });

  function onClose(v?: Pick<TEntity, "securities">) {
    setEditing(false);
    reset(
      v || {
        securities: entity.securities,
      },
    );
  }

  const dispatchErrorToast = useDispatchErrorToast();
  async function onSubmit(v: Pick<TEntity, "securities">) {
    try {
      const updatedEntity = await updateEntity(entity.id, v);
      onClose(updatedEntity);
    } catch (err) {
      if (isAxiosError(err)) {
        dispatchErrorToast(err.message);
      }
    }
  }

  const isValid = formState.isValid;
  const isSubmitting = formState.isSubmitting;

  return (
    <>
      <ButtonLink onClick={() => setEditing(true)}>
        ({entity.securities?.length})
      </ButtonLink>

      <Modal
        title="Edit securities"
        open={editing}
        onClose={() => setEditing(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2">
          {fields.map((field, index) => (
            <div key={field.id} className="flex items-center gap-2">
              <Field className="flex flex-col gap-2">
                <Label>Title</Label>
                <Input
                  {...register(`securities.${index}.title`, { required: true })}
                  data-form-type="other"
                />
              </Field>
              <Field className="flex flex-col gap-2">
                <Label>Ticker</Label>
                <Input
                  {...register(`securities.${index}.ticker`, {
                    required: true,
                  })}
                  data-form-type="other"
                />
              </Field>
              <Field className="flex flex-col gap-2">
                <Label>Exchange</Label>
                <Select
                  {...register(`securities.${index}.exchange`, {
                    required: true,
                  })}
                >
                  <option disabled>Select exchange</option>
                  <option>NASDAQ</option>
                  <option>NYSE</option>
                </Select>
              </Field>

              <div className="flex flex-col justify-end self-stretch pb-2">
                <button
                  className="cursor-pointer rounded p-2 text-red-500 hover:bg-red-500 hover:text-white"
                  type="button"
                  onClick={() => remove(index)}
                >
                  <TrashIcon className="size-4" />
                </button>
              </div>
            </div>
          ))}
          <ButtonLink
            onClick={() => append({ title: "", ticker: "", exchange: "" })}
          >
            <PlusIcon className="size-4" />
            <span>Add security</span>
          </ButtonLink>

          <ModalActions>
            <Button
              onClick={() => onClose()}
              variant="default"
              color="gray"
              type="button"
              className="flex-1"
            >
              Cancel
            </Button>
            <Button
              variant="default"
              color="blue"
              type="submit"
              className="flex-1"
              disabled={!isValid}
              loading={isSubmitting}
            >
              Save
            </Button>
          </ModalActions>
        </form>
      </Modal>
    </>
  );
}
