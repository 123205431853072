import { useState } from "react";
import { isAxiosError } from "axios";

import { TFiling } from "../../_common/api/filings";
import { PlusIcon } from "../../_common/components/icons/PlusIcon";
import { Modal } from "../../_common/components/Modal/Modal";
import {
  useDispatchErrorToast,
  useDispatchInfoToast,
} from "../../_common/components/Toasts/context";
import {
  createReferenceDocument,
  CreateReferenceDocumentParams,
  UpdateReferenceDocumentParams,
} from "../../_common/api/document";
import { Status6b5Enum } from "../../../generated/types";
import { ReferenceExhibitForm } from "./ReferenceExhibitForm";

interface AddReferenceCtaProps {
  filing: TFiling;
}
export function AddReferenceCta({ filing }: AddReferenceCtaProps) {
  const [open, setOpen] = useState(false);
  const dispatchErrorToast = useDispatchErrorToast();
  const dispatchInfoToast = useDispatchInfoToast();

  async function onSubmit(
    data: CreateReferenceDocumentParams | UpdateReferenceDocumentParams
  ) {
    if (!("filing" in data)) {
      return;
    }

    try {
      await createReferenceDocument(data);
      dispatchInfoToast("Reference added successfully", 3000);
      setOpen(false);
    } catch (e) {
      if (isAxiosError(e)) {
        dispatchErrorToast(e.message);
      } else {
        dispatchErrorToast("Error adding reference", 3000);
      }
    }
  }

  function onCancel() {
    setOpen(false);
  }

  return (
    <>
      <button
        className="text-sm flex items-center gap-2 hover:underline text-brandBlue whitespace-nowrap"
        onClick={() => setOpen(true)}
      >
        <PlusIcon className="size-3" />
        <span>Add reference</span>
      </button>

      <Modal open={open} onClose={onCancel} title="Add reference">
        <ReferenceExhibitForm
          initialValues={{
            filing: filing.id,
            status: Status6b5Enum.active,
            link_text: "",
            linked_url: "",
          }}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </Modal>
    </>
  );
}
