import { HtmlHTMLAttributes } from "react";

export function Logo({ ...rest }: HtmlHTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="87"
      height="30"
      viewBox="0 0 87 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_7_14)">
        <path
          d="M36.755 4.31115H34.9214C33.6024 4.31115 32.943 4.97247 32.943 6.29585V6.86495H36.755V10.7371H32.943V23.3295H28.3266V10.7371H25.994V6.86495H28.3266V5.80754C28.3266 3.99657 28.8412 2.57596 29.8703 1.54501C30.9002 0.515479 32.198 0 33.7636 0H36.755V4.31115Z"
          fill="#232323"
        />
        <path
          d="M41.6293 30H38.3644V25.7704H40.1824C41.4151 25.7704 42.2626 25.1898 42.7235 24.0295L42.9808 23.3295L36.8525 6.86566H41.823L44.6058 14.8702C45.0023 15.9548 45.2434 16.9743 45.3296 17.9288L49.1416 6.86566H53.9827L46.8734 26.0306C45.8979 28.6766 44.1499 30 41.63 30H41.6293Z"
          fill="#232323"
        />
        <path d="M59.2113 23.3295H54.5949V0H59.2113V23.3295Z" fill="#232323" />
        <path
          d="M76.6561 15.4715V16.2036H64.5769C64.6737 17.3211 65.0886 18.2212 65.823 18.904C66.5574 19.5875 67.4339 19.9292 68.4525 19.9292C69.3855 19.9292 70.1786 19.704 70.8331 19.2543C71.4869 18.8046 71.916 18.1998 72.1196 17.4405H76.5267C76.1302 19.4581 75.2268 21.0224 73.8167 22.1341C72.4065 23.2459 70.6182 23.8014 68.4525 23.8014C66.79 23.8014 65.3106 23.416 64.0135 22.646C62.7158 21.876 61.7212 20.8322 61.03 19.5146C60.338 18.1969 59.9923 16.7241 59.9923 15.0976C59.9923 13.4711 60.3754 11.9254 61.1423 10.5913C61.9093 9.25717 62.9328 8.22693 64.2143 7.50054C65.4958 6.77415 66.8974 6.41024 68.4207 6.41024C71.0155 6.41024 73.037 7.19096 74.4847 8.75313C75.9323 10.3153 76.6561 12.5545 76.6561 15.4722V15.4715ZM68.5013 10.005C67.6644 10.005 66.9144 10.2488 66.2493 10.7371C65.5841 11.2254 65.1021 11.8817 64.8017 12.7054H72.345C72.0984 11.8596 71.6502 11.1983 71.0021 10.7207C70.3532 10.2438 69.5191 10.005 68.5013 10.005Z"
          fill="#232323"
        />
        <path
          d="M84.9897 6.86566H87V11.3069H84.555C82.7964 11.3069 81.9171 12.1963 81.9171 13.9751V23.3295H77.3007V6.86566H81.5955V9.94066C81.7665 8.89969 82.1666 8.12683 82.7936 7.62208C83.4205 7.11804 84.1528 6.86566 84.989 6.86566H84.9897Z"
          fill="#232323"
        />
        <path
          d="M6.21595 17.2074H3.02669C1.3551 17.2074 0 18.5779 0 20.2685C0 21.959 1.3551 23.3295 3.02669 23.3295H6.21595C7.88755 23.3295 9.24264 21.959 9.24264 20.2685C9.24264 18.5779 7.88755 17.2074 6.21595 17.2074Z"
          fill="#191919"
        />
        <path
          d="M15.4593 8.6037H3.02669C1.3551 8.6037 0 9.97418 0 11.6648C0 13.3553 1.3551 14.7258 3.02669 14.7258H15.4593C17.1309 14.7258 18.486 13.3553 18.486 11.6648C18.486 9.97418 17.1309 8.6037 15.4593 8.6037Z"
          fill="#191919"
        />
        <path
          d="M18.8705 0H3.02669C1.35509 0 0 1.37048 0 3.06106C0 4.75163 1.35509 6.12211 3.02669 6.12211H18.8705C20.5421 6.12211 21.8972 4.75163 21.8972 3.06106C21.8972 1.37048 20.5421 0 18.8705 0Z"
          fill="#191919"
        />
        <path
          d="M6.21595 17.2074H3.02669C1.3551 17.2074 0 18.5779 0 20.2685C0 21.959 1.3551 23.3295 3.02669 23.3295H6.21595C7.88755 23.3295 9.24264 21.959 9.24264 20.2685C9.24264 18.5779 7.88755 17.2074 6.21595 17.2074Z"
          fill="url(#paint0_linear_7_14)"
        />
        <path
          d="M15.4593 8.6037H3.02669C1.3551 8.6037 0 9.97418 0 11.6648C0 13.3553 1.3551 14.7258 3.02669 14.7258H15.4593C17.1309 14.7258 18.486 13.3553 18.486 11.6648C18.486 9.97418 17.1309 8.6037 15.4593 8.6037Z"
          fill="url(#paint1_linear_7_14)"
        />
        <path
          d="M18.8705 0H3.02669C1.35509 0 0 1.37048 0 3.06106C0 4.75163 1.35509 6.12211 3.02669 6.12211H18.8705C20.5421 6.12211 21.8972 4.75163 21.8972 3.06106C21.8972 1.37048 20.5421 0 18.8705 0Z"
          fill="url(#paint2_linear_7_14)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_7_14"
          x1="-2.87967"
          y1="16.0449"
          x2="21.7519"
          y2="1.98567"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32AAE3" />
          <stop offset="1" stopColor="#FFA500" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7_14"
          x1="-2.87967"
          y1="16.0449"
          x2="21.7519"
          y2="1.98567"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32AAE3" />
          <stop offset="1" stopColor="#FFA500" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7_14"
          x1="-2.87967"
          y1="16.0449"
          x2="21.7519"
          y2="1.98567"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32AAE3" />
          <stop offset="1" stopColor="#FFA500" />
        </linearGradient>
        <clipPath id="clip0_7_14">
          <rect width="87" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
