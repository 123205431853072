import { isAxiosError } from "axios";
import { updateDocument } from "../api/document";
import { TDocument } from "../api/filings";
import { FileInput } from "./FileInput";
import { useDispatchErrorToast } from "./Toasts/context";
import { useState } from "react";
import clsx from "clsx";
import { Spinner } from "./Spinner";

interface AddFileToDocumentCtaProps {
  document: TDocument;
}

export function AddFileToDocumentCta({ document }: AddFileToDocumentCtaProps) {
  const dispatchErrorToast = useDispatchErrorToast();
  const [loading, setLoading] = useState(false);
  async function handleInputChange(files: File[]) {
    const file = files[0];

    if (!file) return;

    try {
      setLoading(true);
      await updateDocument(document.id, { uploaded_file: file });
    } catch (err) {
      if (isAxiosError(err)) {
        dispatchErrorToast(err.message);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <FileInput
      dropzoneProps={{
        onDrop: handleInputChange,
        accept: {
          "application/pdf": [".pdf"],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx"],
        },
      }}
    >
      <div className="flex gap-2 items-center">
        {loading && <Spinner color="brand" className="size-4" />}

        <span
          className={clsx("text-red-500 hover:underline cursor-pointer", {
            "opacity-70 cursor-not-allowed pointer-events-none": loading,
          })}
        >
          Add file
        </span>
      </div>
    </FileInput>
  );
}
