import { Link, useParams } from "react-router-dom";
import { useMemo, useState } from "react";

import { useStubbedData } from "../hooks/useStubbedData";
import { Table } from "../../_common/components/Table/Table";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ReportingOwnerSecurities } from "./Section16Index/ReportingOwnerSecurities";

export function EditOwnershipForm() {
  const { id } = useParams<{ id: string }>();
  const [ownerSecuritiesDrawerOpen, setOwnerSecuritiesDrawerOpen] =
    useState(false);

  const scheduledFilings = useStubbedData((s) => s.scheduledFilings);
  const derivativeTransactions = useStubbedData(
    (s) => s.derivativeTransactions
  );
  const nonDerivativeTransactions = useStubbedData(
    (s) => s.nonDerivativeTransactions
  );

  const filing = useMemo(
    () => scheduledFilings.find((f) => f.id === parseInt(id || "-1")),
    [id, scheduledFilings]
  );
  if (!filing) return <div>Not found</div>;

  return (
    <div className="p-4 flex gap-2 h-full relative">
      <div className="flex-1 space-y-4">
        <div className="flex gap-2 items-end">
          <h2 className="text-lg font-semibold">{filing.label}</h2>
          <Link to="preview" className="text-brandBlue hover:underline">
            Preview
          </Link>
        </div>
        <button
          className="absolute right-4 top-0 text-gray-600 hover:text-gray-300 hover:bg-gray-700 transition rounded p-3"
          onClick={() => setOwnerSecuritiesDrawerOpen((p) => !p)}
        >
          {ownerSecuritiesDrawerOpen ? (
            <XMarkIcon className="size-4" />
          ) : (
            <Bars3Icon className="size-4" />
          )}
        </button>

        <h3 className="font-semibold">Table 1 - Derivative Transactions</h3>

        <Table
          data={derivativeTransactions}
          rowKey="id"
          emptyState={
            <div className="pl-7 py-2 text-gray-400 text-sm">
              No derivative transactions
            </div>
          }
          actions={[]}
          headers={[
            {
              label: "Security",
              getStringContent: (tra) => tra.security_title,
              hasTooltip: false,
            },
            {
              label: "Number of shares",
              getStringContent: (tra) =>
                tra.number_of_derivative_securities_disposed ||
                tra.number_of_derivative_securities_acquired,
              hasTooltip: false,
            },
            {
              label: "Execution Date",
              getStringContent: (tra) => tra.transaction_date,
              hasTooltip: false,
            },
          ]}
        />

        <h3 className="font-semibold">Table 2 - Non-Derivative Transactions</h3>

        <Table
          data={nonDerivativeTransactions}
          rowKey="id"
          emptyState={
            <div className="pl-7 py-2 text-gray-400 text-sm">
              No non-derivative transactions
            </div>
          }
          actions={[]}
          headers={[
            {
              label: "Security",
              getStringContent: (tra) => tra.security_title,
              hasTooltip: false,
            },
            {
              label: "Number of shares",
              getStringContent: (tra) => tra.transaction_amount,
              hasTooltip: false,
            },
            {
              label: "Execution Date",
              getStringContent: (tra) => tra.transaction_date,
              hasTooltip: false,
            },
          ]}
        />
      </div>
      {ownerSecuritiesDrawerOpen && (
        <div className="p-2 h-full border rounded flex-1 bg-white">
          <ReportingOwnerSecurities
            reportingOwnerId={filing.reportingOwnerId}
          />
        </div>
      )}
    </div>
  );
}
