import { Button } from "./Button";
import { FilingsIcon } from "./icons/FilingsIcon";
import { FileInput } from "./FileInput";
import clsx from "clsx";

interface FileDropZoneProps {
  onFiles: (f: File[]) => void;
  disabled?: boolean;
}
export function FileDropZone({ onFiles, disabled }: FileDropZoneProps) {
  return (
    <FileInput
      className="h-full"
      dropzoneProps={{
        onDrop: onFiles,
        multiple: false,
        disabled,
        accept: {
          "application/pdf": [".pdf"],
          // "text/html": [".html", ".htm"],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx"],
        },
      }}
    >
      <div
        className={clsx(
          "flex flex-col items-center justify-center h-full gap-3 p-4 border-2 border-dashed border-gray-300 rounded-md text-black/50 text-xs font-medium",
          {
            "cursor-not-allowed opacity-50": disabled,
          },
        )}
      >
        <FilingsIcon className="size-14 text-gray-300" />
        <div>Drop your primary document here</div>

        <Button
          variant="default"
          color="gray"
          className="text-xs"
          disabled={disabled}
        >
          Upload File
        </Button>
      </div>
    </FileInput>
  );
}
