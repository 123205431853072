import clsx from "clsx";
import { FocusEventHandler } from "react";

interface EditableTextProps {
  valueHandlerOnBlur: (v: string) => void;
  content: string | number;
  className?: string;
  validator?: (v: string) => boolean;
}
export function EditableText({
  valueHandlerOnBlur,
  content,
  className,
  validator,
}: EditableTextProps) {
  const onBlur: FocusEventHandler<HTMLDivElement> = (e) => {
    if (e.currentTarget instanceof HTMLElement) {
      const value = e.currentTarget.textContent || "";
      const isValid = validator ? validator(value) : true;
      if (!isValid) {
        e.currentTarget.textContent = String(content);
      } else if (value !== content) {
        valueHandlerOnBlur(value);
      }
    }
  };

  return (
    <div
      contentEditable
      onBlur={onBlur}
      data-form-type="other"
      className={clsx(
        "focus-within:text-black focus-within:whitespace-normal focus-within:shadow-sm focus:outline-none cursor-text",
        className
      )}
    >
      {content}
    </div>
  );
}
