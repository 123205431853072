import { axios} from "./client";
import { ID } from "../types/id";

interface EdgarPreviewResponse {
  url: string;
}


export async function previewEdgar(filingId: ID): Promise<string> {
  const response = await axios.get<EdgarPreviewResponse>(`filings/${filingId}/render-edgar/`);
  return response.data.url;
}
