import {
  StubbedDerivativeTransaction,
  StubbedNonDerivativeTransaction,
} from "../../hooks/useStubbedData";

interface TransactionLineProps {
  transaction: StubbedDerivativeTransaction | StubbedNonDerivativeTransaction;
  reportingOwnerName: string;
}
export function TransactionLine({
  transaction,
  reportingOwnerName,
}: TransactionLineProps) {
  if ("acquired_disposed" in transaction) {
    return (
      <li className="flex gap-1 items-center">
        <span>•</span>
        <span className="underline font-medium">{reportingOwnerName}</span>

        <span className="font-normal">
          {transaction.acquired_disposed === "A" ? "bought" : "sold"}{" "}
          {transaction.transaction_amount}
        </span>

        <span className="font-medium">{transaction.security_title}</span>
      </li>
    );
  }

  const amountSold = transaction.number_of_derivative_securities_disposed;
  const amountBought = transaction.number_of_derivative_securities_acquired;
  return (
    <li className="flex gap-1 items-center">
      <span>•</span>
      <span className="underline font-medium">{reportingOwnerName}</span>

      <span className="font-normal">
        {amountSold && `sold ${amountSold}`}
        {amountBought && `bought ${amountBought}`}
      </span>

      <span className="font-medium">{transaction.security_title}</span>
    </li>
  );
}
