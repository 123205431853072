import { useMemo } from "react";
import { DocumentTypeEnum } from "../../../generated/types";
import { TFiling } from "../../_common/api/filings";

function getFilingValidationErrors(filing: TFiling) {
  const errors: Record<string, string> = {};

  if (!filing.name) {
    errors.name = "Name is required";
  }

  if (!filing.reporting_date) {
    errors.reporting_date = "Reporting date is required";
  }

  return errors;
}

function getExhibitValidationErrors(filing: TFiling) {
  const errors: Record<string, string> = {};

  const exhibitDocuments = filing.documents.filter(
    (doc) => doc.document_type === DocumentTypeEnum.exhibit,
  );

  exhibitDocuments.forEach((doc) => {
    if (!doc.uploaded_file) {
      errors.uploaded_file = "Some exhibit documents are missing a file";
    }

    if (!doc.exhibit_type) {
      errors.exhibit_type = "Some exhibit documents are missing a type";
    }

    if (!doc.exhibit_link_text) {
      errors.exhibit_link_text =
        "Some exhibit documents are missing a description";
    }

    if (!doc.exhibit_edgar_description) {
      errors.exhibit_edgar_description =
        "Some exhibit documents are missing an edgar description";
    }

    if (doc.exhibit_type === "UNKNOWN") {
      errors.unknown_exhibit_type =
        "Some documents have an unknown exhibit type";
    }

    const hasDuplicate = exhibitDocuments.some((d) => {
      return (
        d.id !== doc.id &&
        d.exhibit_type === doc.exhibit_type &&
        d.exhibit_type_suffix === doc.exhibit_type_suffix
      );
    });

    if (hasDuplicate) {
      errors.unique_exhibit_type =
        "Exhibit type and suffix must be unique per document";
    }
  });

  return errors;
}

function getReferenceDocumentValidationErrors(filing: TFiling) {
  const errors: Record<string, string> = {};

  const missingLinkedUrl = filing.reference_documents.some(
    (doc) => !doc.linked_url,
  );
  const missingLinkText = filing.reference_documents.some(
    (doc) => !doc.link_text,
  );
  if (missingLinkedUrl) {
    errors.linked_url = "Some reference documents are missing a linked url";
  }

  if (missingLinkText) {
    errors.link_text = "Some reference documents are missing a link text";
  }

  return errors;
}

function getPrimaryDocumentErrors(filing: TFiling) {
  const errors: Record<string, string> = {};

  const missingSignatures = filing.constructed_primary_documents.some(
    (s) => !s.signatures.length,
  );
  if (missingSignatures) {
    errors.signatures = "Some primary documents are unsigned";
  }

  return errors;
}

export function useFilingValidationErrors(filing?: TFiling) {
  return useMemo(() => {
    if (!filing) return {};
    const filingErrors = getFilingValidationErrors(filing);
    const primaryDocumentErrors = getPrimaryDocumentErrors(filing);
    const exhibitsErrors = getExhibitValidationErrors(filing);
    const referenceDocumentsErrors =
      getReferenceDocumentValidationErrors(filing);
    const reviewable = !!(
      filing.status === "in_review" ||
      filing.status === "scheduled" ||
      filing.status === "filed"
    );

    return {
      filingErrors,
      primaryDocumentErrors,
      exhibitsErrors,
      referenceDocumentsErrors,
      hassPrimaryDocumentErrors: Object.keys(primaryDocumentErrors).length > 0,
      hasExhibitsErrors: Object.keys(exhibitsErrors).length > 0,
      hasFilingErrors: Object.keys(filingErrors).length > 0,
      hasReferenceDocumentsErrors:
        Object.keys(referenceDocumentsErrors).length > 0,
      reviewable: reviewable,
    };
  }, [filing]);
}
