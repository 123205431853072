import { HtmlHTMLAttributes, ReactNode, useMemo, useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import clsx from "clsx";

import { HotDogIcon } from "../icons/HotDogIcon";
import { ButtonLink } from "../ButtonLink";
import { TableActionConfig, TableHeaderConfig } from "./types";
import { TableCell } from "./TableCell";

interface TableProps<T> extends HtmlHTMLAttributes<HTMLTableElement> {
  data: T[];
  headers: TableHeaderConfig<T>[];
  rowKey: string | number | ((item: T) => string | number);
  actions: TableActionConfig<T>[];
  emptyState: ReactNode;
  sortFn?: (t: T) => string | number;
  leftGutterIcon?: (t: T) => ReactNode;
  onSelectedItems?: (t: T[]) => void;
}
export function Table<T>({
  data,
  headers,
  rowKey,
  actions,
  leftGutterIcon,
  emptyState,
  sortFn,
  onSelectedItems,
  ...props
}: TableProps<T>) {
  const [selectedItems, setSelectedItems] = useState<Record<string, boolean>>(
    {},
  );
  const sortedData = useMemo(() => {
    if (!sortFn) return data;
    return data.sort((a, b) => {
      const aVal = sortFn(a);
      const bVal = sortFn(b);
      return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
    });
  }, [data, sortFn]);

  function rowKeyGetter(row: T) {
    if (typeof rowKey === "function") {
      return rowKey(row);
    }
    return row[rowKey as keyof T] as string;
  }

  function handleItemSelectToggle(row: T) {
    const key = rowKeyGetter(row);

    setSelectedItems((prev) => {
      const next = {
        ...prev,
        [key]: !prev[key],
      };

      const selectedRows = data.filter(d => next[rowKeyGetter(d)])
      onSelectedItems?.(selectedRows);

      return next;
    });
  }

  return (
    <table className="w-full rounded-lg bg-white table-fixed" {...props}>
      <thead className="border-b">
        <tr>
          {onSelectedItems && <th className="pl-7 w-11" />}
          {leftGutterIcon && <th className="pl-7 w-11"></th>}
          {headers.map((h) => (
            <th
              key={h.label}
              className={clsx(
                `font-medium text-left first:pl-7 last:pr-7 p-2 whitespace-nowrap`,
                h.className,
              )}
            >
              {h.label}
            </th>
          ))}
          {actions.length > 0 && <th></th>}
        </tr>
      </thead>
      <tbody className="divide-y">
        {!data.length && emptyState}
        {sortedData.map((d) => {
          const key = rowKeyGetter(d);
          return (
            <tr key={key} className="text-gray-500 text-sm">
              {onSelectedItems && (
                <td className="pl-7">
                  <input
                    type="checkbox"
                    onChange={() => handleItemSelectToggle(d)}
                    checked={selectedItems[key]}
                  />
                </td>
              )}
              {leftGutterIcon && <td className="pl-7">{leftGutterIcon(d)}</td>}

              {headers.map((h) => {
                return (
                  <TableCell config={h} rowData={d} key={`${key}_${h.label}`} />
                );
              })}
              {actions.length > 0 && (
                <td className="pr-7 text-right">
                  <Menu>
                    <MenuButton>
                      <HotDogIcon className="text-gray-400" />
                    </MenuButton>
                    <MenuItems
                      anchor="bottom"
                      className="rounded-lg bg-white shadow-lg divide-y"
                    >
                      {actions.map((a) => (
                        <MenuItem key={a.label}>
                          <ButtonLink
                            onClick={() => a.callback(d)}
                            className="text-gray-500 font-normal p-2 text-center"
                          >
                            {a.label}
                          </ButtonLink>
                        </MenuItem>
                      ))}
                    </MenuItems>
                  </Menu>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
