import {
  createBrowserRouter,
  Link,
  redirect,
  RouterProvider,
} from "react-router-dom";

import { Filing } from "../../Filing/Filing";
import { Home } from "../../Home/Home";
import { Filings } from "../../Filings/Filings";
import { Document } from "../../Document/Document";
import { Layout } from "./Layout";
import { BreadCrumbHandle } from "./Breadcrumbs/Breadcrumbs";
import { getFiling, listFilings, TFiling } from "../api/filings";
import { getUser } from "../api/user";
import { RootErrorElement } from "./RootErrorElement";
import { FilingBreadcrumb } from "./Breadcrumbs/FilingBreadcrumb";
import { DocumentBreadcrumb } from "./Breadcrumbs/DocumentBreadcrumb";
import { ID } from "../types/id";
import { Submission } from "../../Filings/Submission";
import { Profile } from "../../Profile/Profile";
import { Entities } from "../../Entities/Entities";
import { ConstructedDocumentBreadcrumb } from "./Breadcrumbs/ConstructedDocumentBreadcrumb";
import { ConstructedDocument } from "../../ConstructedDocument/ConstructedDocument";
import { PreviewHtml } from "../../ConstructedDocument/PreviewHtml";
import { Section16Index } from "../../Section16/components/Section16Index";
import { FiledExhibits } from "../../FiledExhibits/FiledExhibits";
import { NewOwnershipForm } from "../../Section16/components/NewOwnershipForm/NewOwnershipForm";
import { EditOwnershipForm } from "../../Section16/components/EditOwnershipForm";
import { OwnershipFormPreview } from "../../Section16/components/OwnershipFormPreview";
import { WrappedHome } from "../../Wrapped/components/WrappedHome";
import { WrappedFlow } from "../../Wrapped/components/WrappedFlow";
import { SecFilingsDump } from "../../SecFilingsDump/components/SecFilingsDump";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    loader: getUser,
    errorElement: <RootErrorElement />,
    children: [
      {
        index: true,
        element: <Home />,
        loader: async () => {
          return redirect("/filings");
        },
      },
      { path: "/profile", element: <Profile /> },
      { path: "/entities", element: <Entities /> },
      {
        path: "/filings",
        handle: {
          crumb: () => (
            <Link to="/filings" className="hover:underline">
              Filings
            </Link>
          ),
        } as BreadCrumbHandle,
        children: [
          {
            index: true,
            element: <Filings />,
            loader: listFilings,
          },
          {
            path: ":filingId",
            loader: ({ params }) => {
              return getFiling(params.filingId as ID);
            },
            element: <Filing />,
            handle: {
              crumb: (filing) => (
                <FilingBreadcrumb id={filing.id} asLink={false} />
              ),
            } as BreadCrumbHandle<TFiling>,
          },
          {
            path: ":filingId/submission",
            loader: ({ params }) => params,
            element: <Submission />,
            handle: {
              crumb: ({ filingId }) => [
                <FilingBreadcrumb asLink id={filingId} key="filing_link" />,
                <strong>Submission</strong>,
              ],
            } as BreadCrumbHandle<{ filingId: ID }>,
          },
          {
            path: ":filingId/documents/:documentId",
            loader: async ({ params }) => {
              return params;
            },
            handle: {
              crumb: ({ documentId, filingId }) => [
                <FilingBreadcrumb asLink id={filingId} key="filing_link" />,
                <DocumentBreadcrumb
                  filingId={filingId}
                  id={documentId}
                  key="document_crumb"
                />,
              ],
            } as BreadCrumbHandle<{ documentId: ID; filingId: ID }>,
            element: <Document />,
          },
          {
            path: ":filingId/constructed-documents/:documentId",
            loader: async ({ params }) => {
              return params;
            },
            handle: {
              crumb: ({ filingId }) => [
                <FilingBreadcrumb asLink id={filingId} key="filing_link" />,
                <ConstructedDocumentBreadcrumb
                  key="constructed_document_crumb"
                />,
              ],
            } as BreadCrumbHandle<{ filingId: ID }>,
            element: <ConstructedDocument />,
          },
          {
            path: ":filingId/constructed-documents/:documentId/preview",
            element: <PreviewHtml />,
          },
        ],
      },
      {
        path: "section-16",
        children: [
          {
            index: true,
            element: <Section16Index />,
          },
          {
            path: "new",
            element: <NewOwnershipForm />,
          },
          {
            path: ":id",
            element: <EditOwnershipForm />,
          },
          {
            path: ":id/preview",
            element: <OwnershipFormPreview />,
          },
        ],
      },
      { path: "/filed_exhibits", element: <FiledExhibits /> },
      { path: "/sec-filings-dump", element: <SecFilingsDump /> },
    ],
  },
  {
    path: "wrapped/:year",
    children: [
      {
        index: true,
        element: <WrappedHome />,
      },
      {
        path: ":ticker",
        element: <WrappedFlow />,
      },
    ],
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
