import { useEffect } from "react";
import { TToast, useToasts } from "./context";
import { ErrorToast } from "./ErrorToast";
import { InfoToast } from "./InfoToast";

export function Toasts() {
  const [toastState] = useToasts();

  return (
    <div className="fixed inset-0 top-0 right-0 flex flex-col items-end pr-5 pt-2 gap-2 pointer-events-none">
      {toastState.toasts.map((t, i) => (
        <Toast key={i} index={i} toast={t} />
      ))}
    </div>
  );
}

interface ToastProps {
  toast: TToast;
  index: number;
}
function Toast({ toast, index }: ToastProps) {
  const dispatchToastAction = useToasts()[1];

  useEffect(() => {
    if (toast.autoDismissAfter) {
      setTimeout(() => {
        dispatchToastAction({ type: "dismiss_toast", payload: index });
      }, toast.autoDismissAfter);
    }
  }, [toast, index, dispatchToastAction]);

  return (
    <button
      onClick={() =>
        dispatchToastAction({ type: "dismiss_toast", payload: index })
      }
      className="animate-fadeInUp pointer-events-auto"
    >
      {toast.intent === "error" && <ErrorToast {...toast} />}
      {toast.intent === "info" && <InfoToast {...toast} />}
    </button>
  );
}
