import { buildLookup } from "../../_common/utils/list";
const agentsList = [
  {
    cik: "0001354457",
    name: "Nasdaq Stock Market LLC",
    category: "Filing Agent",
    website: "nasdaq.com",
  },
  {
    cik: "0000876661",
    name: "New York Stock Exchange LLC",
    category: "Filing Agent",
    website: "nyse.com",
  },
  {
    cik: "0001143362",
    name: "NYSE Arca, Inc.",
    category: "Filing Agent",
    website: "nyse.com/markets/nyse-arca",
  },
  {
    cik: "0001172661",
    name: "Adviser Compliance Associates LLC",
    category: "Filing Agent",
    website: "acallc.com",
  },
  {
    cik: "0001628280",
    name: "Workiva Inc",
    category: "Filing Agent",
    website: "workiva.com",
  },
  {
    cik: "0001376474",
    name: "Advanced Computer Innovations, Inc.",
    category: "Filing Agent",
    website: "advcomputerinnov.com",
  },
  {
    cik: "0001085146",
    name: "Advisor Consultant Network Inc",
    category: "Filing Agent",
    website: "advisorconsultant.com",
  },
  {
    cik: "0001133228",
    name: "Appatura",
    category: "Filing Agent",
    website: "appatura.com",
  },
  {
    cik: "0001435109",
    name: "Atlantic Fund Administration, LLC",
    category: "Filing Agent",
    website: "atlanticfundservices.com",
  },
  {
    cik: "0001086364",
    name: "BlackRock Advisors LLC",
    category: "Filing Agent",
    website: "blackrock.com",
  },
  {
    cik: "0001580642",
    name: "Blu Giant, LLC",
    category: "Filing Agent",
    website: "blugiant.com",
  },
  {
    cik: "0001654954",
    name: "Blueprint",
    category: "Filing Agent",
    website: "blueprinttech.com",
  },
  {
    cik: "0001019056",
    name: "Borer Financial Communications, Inc.",
    category: "Filing Agent",
    website: "borerfinancial.com",
  },
  {
    cik: "0001140361",
    name: "Broadridge Financial Solutions, Inc.",
    category: "Filing Agent",
    website: "broadridge.com",
  },
  {
    cik: "0001567619",
    name: "Broadridge Financial Solutions, Inc.",
    category: "Filing Agent",
    website: "broadridge.com",
  },
  {
    cik: "0001157523",
    name: "Business Wire",
    category: "Filing Agent",
    website: "businesswire.com",
  },
  {
    cik: "0001279569",
    name: "CNW Group Ltd",
    category: "Filing Agent",
    website: "newswire.ca",
  },
  {
    cik: "0000930413",
    name: "Command Financial",
    category: "Filing Agent",
    website: "commandfinancial.com",
  },
  {
    cik: "0001741773",
    name: "Command Financial - CAPS",
    category: "Filing Agent",
    website: "commandfinancial.com",
  },
  {
    cik: "0001174947",
    name: "Computershare Communication Services",
    category: "Filing Agent",
    website: "computershare.com",
  },
  {
    cik: "0001127602",
    name: "Computershare Inc.",
    category: "Filing Agent",
    website: "computershare.com",
  },
  {
    cik: "0001973379",
    name: "Computershare Inc.",
    category: "Filing Agent",
    website: "computershare.com",
  },
  {
    cik: "0001752724",
    name: "Confluence Technologies Inc.",
    category: "Filing Agent",
    website: "confluence.com",
  },
  {
    cik: "0001225208",
    name: "CT's HCUE",
    category: "Filing Agent",
    website: "ct.wolterskluwer.com",
  },
  {
    cik: "0001575872",
    name: "DataTracks Services Ltd",
    category: "Filing Agent",
    website: "datatracks.com",
  },
  {
    cik: "0001145549",
    name: "DFIN Solutions FundSuiteArc",
    category: "Filing Agent",
    website: "dfinsolutions.com",
  },
  {
    cik: "0001765380",
    name: "Dinsmore Compliance Services LLC",
    category: "Filing Agent",
    website: "dinsmore.com",
  },
  {
    cik: "0001477932",
    name: "Discount EDGAR",
    category: "Filing Agent",
    website: "discountedgar.com",
  },
  {
    cik: "0001193805",
    name: "E-Data Systems, Inc.",
    category: "Filing Agent",
    website: "edatasystems.com",
  },
  {
    cik: "0001213900",
    name: "EDGAR Agents LLC",
    category: "Filing Agent",
    website: "edgaragents.com",
  },
  {
    cik: "0001079973",
    name: "EDGAR Technology & Business Services Inc",
    category: "Filing Agent",
    website: "edgartbs.com",
  },
  {
    cik: "0001161697",
    name: "EdgarBiz Inc",
    category: "Filing Agent",
    website: "edgarbiz.com",
  },
  {
    cik: "0001420506",
    name: "EdgarFilingAgent.com",
    category: "Filing Agent",
    website: "edgarfilingagent.com",
  },
  {
    cik: "0001731122",
    name: "Electro Filings LLC",
    category: "Filing Agent",
    website: "electrofilings.com",
  },
  {
    cik: "0001171520",
    name: "Electronic Publishing Services Inc",
    category: "Filing Agent",
    website: "epublishingservices.com",
  },
  {
    cik: "0001829126",
    name: "Empire Filings",
    category: "Filing Agent",
    website: "empirefilings.com",
  },
  {
    cik: "0001593968",
    name: "Equity Administration Solutions Inc.",
    category: "Filing Agent",
    website: "easisolutions.com",
  },
  {
    cik: "0001185185",
    name: "Federal Filings, LLC",
    category: "Filing Agent",
    website: "federalfilings.com",
  },
  {
    cik: "0001623632",
    name: "Federated Administrative Services",
    category: "Filing Agent",
    website: "federatedinvestors.com",
  },
  {
    cik: "0001398344",
    name: "FilePoint",
    category: "Filing Agent",
    website: "filepoint.com",
  },
  {
    cik: "0001528621",
    name: "Financial Graphic Service, Inc.",
    category: "Filing Agent",
    website: "fgs.com",
  },
  {
    cik: "0001445546",
    name: "Fitzgerald Marketing & Communications, LLC",
    category: "Filing Agent",
    website: "fitzgeraldmarketing.com",
  },
  {
    cik: "0001753926",
    name: "Genesis Filings LLC",
    category: "Filing Agent",
    website: "genesisfilings.com",
  },
  {
    cik: "0001171843",
    name: "GlobeNewswire Inc.",
    category: "Filing Agent",
    website: "globenewswire.com",
  },
  {
    cik: "0001683168",
    name: "GlobeOne Filings, Inc.",
    category: "Filing Agent",
    website: "globeonefilings.com",
  },
  {
    cik: "0001072613",
    name: "Go2 EDGAR Solutions LLC",
    category: "Filing Agent",
    website: "go2edgar.com",
  },
  {
    cik: "0001667731",
    name: "GoFiler Online",
    category: "Filing Agent",
    website: "gofileronline.com",
  },
  {
    cik: "0001493152",
    name: "M2 Compliance",
    category: "Filing Agent",
    website: "m2compliance.com",
  },
  {
    cik: "0000928816",
    name: "McMunn Associates Inc",
    category: "Filing Agent",
    website: "mcmunnassociates.com",
  },
  {
    cik: "0001162044",
    name: "Mutual Shareholder Services LLC",
    category: "Filing Agent",
    website: "mutualss.com",
  },
  {
    cik: "0001292814",
    name: "MZ Technologies",
    category: "Filing Agent",
    website: "mzgroup.com",
  },
  {
    cik: "0001062993",
    name: "Newsfile Corp",
    category: "Filing Agent",
    website: "newsfilecorp.com",
  },
  {
    cik: "0001539497",
    name: "Nuvo Group, Inc.",
    category: "Filing Agent",
    website: "nuvogroup.com",
  },
  {
    cik: "0001640334",
    name: "Pubco Reporting Solutions, Inc.",
    category: "Filing Agent",
    website: "pubco.com",
  },
  {
    cik: "0001999371",
    name: "Quality EDGAR",
    category: "Filing Agent",
    website: "qes.com",
  },
  {
    cik: "0002000324",
    name: "Quality EDGAR",
    category: "Filing Agent",
    website: "qualityedgar.com",
  },
  {
    cik: "0001839882",
    name: "Quality EDGAR",
    category: "Filing Agent",
    website: "qualityedgar.com",
  },
  {
    cik: "0001437749",
    name: "RDG Filings",
    category: "Filing Agent",
    website: "rdgfilings.com",
  },
  {
    cik: "0001415889",
    name: "SEC Connect",
    category: "Filing Agent",
    website: "secconnect.com",
  },
  {
    cik: "0001214659",
    name: "Securex Filings",
    category: "Filing Agent",
    website: "securexfilings.com",
  },
  {
    cik: "0001562180",
    name: "Solium Capital Inc",
    category: "Filing Agent",
    website: "shareworks.com",
  },
  {
    cik: "0001096906",
    name: "Southridge Services Inc",
    category: "Filing Agent",
    website: "southridgeservices.com",
  },
  {
    cik: "0001754960",
    name: "Wealthfluent, LLC",
    category: "Filing Agent",
    website: "wealthfluent.com",
  },
  {
    cik: "0001178913",
    name: "Z-K Global Ltd",
    category: "Filing Agent",
    website: "zkglobal.com",
  },
  {
    cik: "0000940400",
    name: "BNY Mellon Investment Servicing (US) Inc.",
    category: "Filing Agent",
    website: "bnymellon.com",
  },
  {
    cik: "0001949846",
    name: "Charles Schwab Corp Restricted Stock Services",
    category: "Filing Agent",
    website: "schwab.com",
  },
  {
    cik: "0001628297",
    name: "Citibank, N.A. - Citibank Agency & Trust",
    category: "Filing Agent",
    website: "citi.com",
  },
  {
    cik: "0000887343",
    name: "Columbia Banking System, Inc.",
    category: "Filing Agent",
    website: "columbiabank.com",
  },
  {
    cik: "0001723596",
    name: "Columbia Financial, Inc.",
    category: "Filing Agent",
    website: "columbiafinancial.com",
  },
  {
    cik: "0000028412",
    name: "Comerica Inc",
    category: "Filing Agent",
    website: "comerica.com",
  },
  {
    cik: "0000745544",
    name: "Delaware Life Insurance Co",
    category: "Filing Agent",
    website: "delawarelife.com",
  },
  {
    cik: "0001972362",
    name: "E*TRADE Financial Corp LLC ET Middle Office",
    category: "Filing Agent",
    website: "etrade.com",
  },
  {
    cik: "0001959173",
    name: "Fidelity Brokerage Services LLC",
    category: "Filing Agent",
    website: "fidelity.com",
  },
  {
    cik: "0000769993",
    name: "Goldman Sachs & Co. LLC",
    category: "Filing Agent",
    website: "goldmansachs.com",
  },
  {
    cik: "0001958244",
    name: "Goldman Sachs & Co. LLC",
    category: "Filing Agent",
    website: "goldmansachs.com",
  },
  {
    cik: "0001968582",
    name: "J.P. Morgan Securities LLC",
    category: "Filing Agent",
    website: "jpmorgan.com",
  },
  {
    cik: "0001974158",
    name: "Jefferies LLC",
    category: "Filing Agent",
    website: "jefferies.com",
  },
  {
    cik: "0000019617",
    name: "JPMorgan Chase & Co",
    category: "Filing Agent",
    website: "jpmorgan.com",
  },
  {
    cik: "0001410578",
    name: "Merrill Corp FA",
    category: "Filing Agent",
    website: "merrillcorp.com",
  },
  {
    cik: "0001965353",
    name: "Merrill Lynch",
    category: "Filing Agent",
    website: "ml.com",
  },
  {
    cik: "0002002698",
    name: "Merrill Lynch",
    category: "Filing Agent",
    website: "ml.com",
  },
  {
    cik: "0001971857",
    name: "Merrill Lynch",
    category: "Filing Agent",
    website: "ml.com",
  },
  {
    cik: "0002007317",
    name: "Merrill Lynch Inc.",
    category: "Filing Agent",
    website: "ml.com",
  },
  {
    cik: "PES",
    name: "Merrill Lynch, Pierce, Fenner & Smith Inc",
    category: "Filing Agent",
    website: "ml.com",
  },
  {
    cik: "0000895421",
    name: "Morgan Stanley",
    category: "Filing Agent",
    website: "morganstanley.com",
  },
  {
    cik: "0001950047",
    name: "Morgan Stanley Smith Barney LLC Executive Services",
    category: "Filing Agent",
    website: "morganstanley.com",
  },
  {
    cik: "0001965301",
    name: "National Financial Services LLC",
    category: "Filing Agent",
    website: "nationalfinancial.com",
  },
  {
    cik: "0001669191",
    name: "NetCapital Funding Portal Inc.",
    category: "Filing Agent",
    website: "netcapital.com",
  },
  {
    cik: "0001976408",
    name: "Oppenheimer Israel OPCO Ltd.",
    category: "Filing Agent",
    website: "oppenheimer.com",
  },
  {
    cik: "0000318300",
    name: "Peoples Bancorp Inc",
    category: "Filing Agent",
    website: "peoplesbancorp.com",
  },
  {
    cik: "0001967940",
    name: "Piper Sandler & Co. Corporate & Venture Services FA",
    category: "Filing Agent",
    website: "pipersandler.com",
  },
  {
    cik: "0001974078",
    name: "Raymond James & Associates, Inc.",
    category: "Filing Agent",
    website: "raymondjames.com",
  },
  {
    cik: "0001665160",
    name: "StartEngine Capital LLC",
    category: "Filing Agent",
    website: "startengine.com",
  },
  {
    cik: "0000093751",
    name: "State Street Corp",
    category: "Filing Agent",
    website: "statestreet.com",
  },
  {
    cik: "0001972481",
    name: "Stifel, Nicolaus & Company, Inc CIAS",
    category: "Filing Agent",
    website: "stifel.com",
  },
  {
    cik: "0001853620",
    name: "U.S. Bank National Association-Structured Finance",
    category: "Filing Agent",
    website: "usbank.com",
  },
  {
    cik: "0001969223",
    name: "UBS Financial Services Inc Restricted Sale Group",
    category: "Filing Agent",
    website: "ubs.com",
  },
  {
    cik: "0001973141",
    name: "UBS Financial Services Inc. - 10b5-1 Group",
    category: "Filing Agent",
    website: "ubs.com",
  },
  {
    cik: "0000894189",
    name: "US Bancorp Fund Services LLC",
    category: "Filing Agent",
    website: "usbank.com",
  },
  {
    cik: "0001961863",
    name: "Venture Services Group Merrill Lynch",
    category: "Filing Agent",
    website: "ml.com",
  },
  {
    cik: "0001957799",
    name: "Wells Fargo Clearing Services, LLC, Executive Services",
    category: "Filing Agent",
    website: "wellsfargo.com",
  },
  {
    cik: "0001532155",
    name: "Bertan Laurence H",
    category: "Individual",
    website: "N/A",
  },
  {
    cik: "0001246991",
    name: "DiLuglio Kelli A",
    category: "Individual",
    website: "N/A",
  },
  {
    cik: "0001690001",
    name: "Kays Jacki",
    category: "Individual",
    website: "N/A",
  },
  {
    cik: "0001965021",
    name: "Mahaney Mindy M.",
    category: "Individual",
    website: "N/A",
  },
  {
    cik: "0001327749",
    name: "Miller Jeffrey Dean",
    category: "Individual",
    website: "N/A",
  },
  {
    cik: "0001398889",
    name: "Miller Molly A",
    category: "Individual",
    website: "N/A",
  },
  {
    cik: "0001137439",
    name: "Pietrzykowski Kristopher R",
    category: "Individual",
    website: "N/A",
  },
  {
    cik: "0001968202",
    name: "Ravella Donald",
    category: "Individual",
    website: "N/A",
  },
  {
    cik: "0001443984",
    name: "Uttley Kathy L",
    category: "Individual",
    website: "N/A",
  },
  {
    cik: "0001422848",
    name: "Capital Research Global Investors",
    category: "Filing Agent",
    website: "capitalgroup.com",
  },
  {
    cik: "0001977454",
    name: "DCG International Investments Ltd.",
    category: "Filing Agent",
    website: "dcg.co",
  },
  {
    cik: "0000088053",
    name: "Deutsche DWS International Fund, Inc.",
    category: "Filing Agent",
    website: "dws.com",
  },
  {
    cik: "0000354204",
    name: "Dimensional Fund Advisors LP",
    category: "Filing Agent",
    website: "dimensional.com",
  },
  {
    cik: "0000315066",
    name: "FMR LLC",
    category: "Filing Agent",
    website: "fidelity.com",
  },
  {
    cik: "0000807249",
    name: "GAMCO Investors, Inc. et al",
    category: "Filing Agent",
    website: "gabelli.com",
  },
  {
    cik: "0001076809",
    name: "Glazer Capital, LLC",
    category: "Filing Agent",
    website: "glazercapital.com",
  },
  {
    cik: "0001393825",
    name: "Hudson Bay Capital Management LP",
    category: "Filing Agent",
    website: "hudsonbaycapital.com",
  },
  {
    cik: "0000914208",
    name: "Invesco Ltd.",
    category: "Filing Agent",
    website: "invesco.com",
  },
  {
    cik: "0000051931",
    name: "Investment Co of America",
    category: "Filing Agent",
    website: "americanfunds.com",
  },
  {
    cik: "0001274173",
    name: "Janus Henderson Group PLC",
    category: "Filing Agent",
    website: "janushenderson.com",
  },
  {
    cik: "0000912938",
    name: "Massachusetts Financial Services Co",
    category: "Filing Agent",
    website: "mfs.com",
  },
  {
    cik: "0001326389",
    name: "Polar Asset Management Partners Inc.",
    category: "Filing Agent",
    website: "polaramg.com",
  },
  {
    cik: "0000080255",
    name: "Price T Rowe Associates Inc",
    category: "Filing Agent",
    website: "troweprice.com",
  },
  {
    cik: "0000869392",
    name: "Putnam Arizona Tax Exempt Income Fund",
    category: "Filing Agent",
    website: "putnam.com",
  },
  {
    cik: "0001037389",
    name: "Renaissance Technologies LLC",
    category: "Filing Agent",
    website: "rentec.com",
  },
  {
    cik: "0000906304",
    name: "Royce & Associates LP",
    category: "Filing Agent",
    website: "royceinvest.com",
  },
  {
    cik: "0001510281",
    name: "Saba Capital Management, L.P.",
    category: "Filing Agent",
    website: "sabacapital.com",
  },
  {
    cik: "0000769317",
    name: "SIT Investment Associates Inc",
    category: "Filing Agent",
    website: "sitinvest.com",
  },
  {
    cik: "0000356787",
    name: "SIT Mid Cap Growth Fund Inc",
    category: "Filing Agent",
    website: "sitfunds.com",
  },
  {
    cik: "0001604488",
    name: "Vivaldi Asset Management, LLC",
    category: "Filing Agent",
    website: "vivaldicap.com",
  },
  {
    cik: "0000814133",
    name: "Wasatch Advisors Inc",
    category: "Filing Agent",
    website: "wasatchglobal.com",
  },
  {
    cik: "0000902219",
    name: "Wellington Management Group LLP",
    category: "Filing Agent",
    website: "wellington.com",
  },
  {
    cik: "0001315863",
    name: "Akin Gump Strauss Hauer & Feld LLP",
    category: "Law Firm Filing Agent",
    website: "akingump.com",
  },
  {
    cik: "0001011438",
    name: "Akin Gump Strauss Hauer & Feld LLP Los Angeles Office",
    category: "Law Firm Filing Agent",
    website: "akingump.com",
  },
  {
    cik: "0000908834",
    name: "Barnes & Thornburg LLP",
    category: "Law Firm Filing Agent",
    website: "btlaw.com",
  },
  {
    cik: "0000929638",
    name: "Bingham McCutchen LLP",
    category: "Law Firm Filing Agent",
    website: "morganlewis.com",
  },
  {
    cik: "0000939057",
    name: "Breyer & Associates PC",
    category: "Law Firm Filing Agent",
    website: "breyerlaw.com",
  },
  {
    cik: "0001021771",
    name: "Certilman Balin Adler & Hyman LLP",
    category: "Law Firm Filing Agent",
    website: "certilmanbalin.com",
  },
  {
    cik: "0000950157",
    name: "Cravath Swaine & Moore 01",
    category: "Law Firm Filing Agent",
    website: "cravath.com",
  },
  {
    cik: "0000950103",
    name: "Davis Polk & Wardwell LLP",
    category: "Law Firm Filing Agent",
    website: "davispolk.com",
  },
  {
    cik: "000912282",
    name: "Dorsey & Whitney LLP",
    category: "Law Firm Filing Agent",
    website: "dorsey.com",
  },
  {
    cik: "0000897069",
    name: "Foley & Lardner",
    category: "Law Firm Filing Agent",
    website: "foley.com",
  },
  {
    cik: "0000895345",
    name: "Fried Frank Harris Shriver & Jacobson",
    category: "Law Firm Filing Agent",
    website: "friedfrank.com",
  },
  {
    cik: "0000898432",
    name: "K&L Gates LLP",
    category: "Law Firm Filing Agent",
    website: "klgates.com",
  },
  {
    cik: "0000892251",
    name: "Keating Muething & Klekamp",
    category: "Law Firm Filing Agent",
    website: "kmklaw.com",
  },
  {
    cik: "0001013594",
    name: "Kleinberg Kaplan Wolff & Cohen PC",
    category: "Law Firm Filing Agent",
    website: "kkwc.com",
  },
  {
    cik: "0001610717",
    name: "Latham & Watkins LLP (Filing Agent)",
    category: "Law Firm Filing Agent",
    website: "lw.com",
  },
  {
    cik: "0001474506",
    name: "Nelson Mullins Riley & Scarborough, LLP",
    category: "Law Firm Filing Agent",
    website: "nelsonmullins.com",
  },
  {
    cik: "0000921895",
    name: "Olshan Frome Wolosky LLP",
    category: "Law Firm Filing Agent",
    website: "olshanlaw.com",
  },
  {
    cik: "0000950142",
    name: "Paul Weiss Rifkind Wharton & Garrison LLP",
    category: "Law Firm Filing Agent",
    website: "paulweiss.com",
  },
  {
    cik: "0000904454",
    name: "Ropes & Gray LLP",
    category: "Law Firm Filing Agent",
    website: "ropesgray.com",
  },
  {
    cik: "0000902664",
    name: "Schulte Roth & Zabel LLP",
    category: "Law Firm Filing Agent",
    website: "srz.com",
  },
  {
    cik: "0001008886",
    name: "Scudder Law Firm PC",
    category: "Law Firm Filing Agent",
    website: "scudderlaw.com",
  },
  {
    cik: "0000919574",
    name: "Seward & Kissel LLP",
    category: "Law Firm Filing Agent",
    website: "sewkis.com",
  },
  {
    cik: "0000935836",
    name: "Shartsis Friese LLP",
    category: "Law Firm Filing Agent",
    website: "sflaw.com",
  },
  {
    cik: "0000947871",
    name: "Shearman & Sterling LLP",
    category: "Law Firm Filing Agent",
    website: "shearman.com",
  },
  {
    cik: "0000905148",
    name: "Sidley Austin LLP",
    category: "Law Firm Filing Agent",
    website: "sidley.com",
  },
  {
    cik: "0000950172",
    name: "Skadden Arps Slate Meagher & Flom LLP",
    category: "Law Firm Filing Agent",
    website: "skadden.com",
  },
  {
    cik: "0000905729",
    name: "Warner Norcross & Judd",
    category: "Law Firm Filing Agent",
    website: "wnj.com",
  },
  {
    cik: "0000899140",
    name: "Willkie Farr & Gallagher",
    category: "Law Firm Filing Agent",
    website: "willkie.com",
  },
  {
    cik: "0001578563",
    name: "Wyrick Robbins Yates & Ponton LLP",
    category: "Law Firm Filing Agent",
    website: "wyrick.com",
  },
  {
    cik: "0000897101",
    name: "American Financial Printing Inc",
    category: "Filing Agent",
    website: "afpi.com",
  },
  {
    cik: "0001206774",
    name: "DG3",
    category: "Filing Agent",
    website: "dg3.com",
  },
  {
    cik: "0001683863",
    name: "Donnelley Financial",
    category: "Filing Agent",
    website: "dfinsolutions.com",
  },
  {
    cik: "0000950170",
    name: "Donnelley Financial Solutions",
    category: "Filing Agent",
    website: "dfinsolutions.com",
  },
  {
    cik: "0001193125",
    name: "Donnelley Financial Solutions",
    category: "Filing Agent",
    website: "dfinsolutions.com",
  },
  {
    cik: "0000950123",
    name: "Donnelley Financial Solutions",
    category: "Filing Agent",
    website: "dfinsolutions.com",
  },
  {
    cik: "0001258897",
    name: "Donnelley Financial Solutions",
    category: "Filing Agent",
    website: "dfinsolutions.com",
  },
  {
    cik: "0000950131",
    name: "Donnelley Financial Solutions",
    category: "Filing Agent",
    website: "dfinsolutions.com",
  },
  {
    cik: "0000899243",
    name: "Donnelley Financial Solutions",
    category: "Filing Agent",
    website: "dfinsolutions.com",
  },
  {
    cik: "0001209191",
    name: "Donnelley Financial Solutions",
    category: "Filing Agent",
    website: "dfinsolutions.com",
  },
  {
    cik: "0001104659",
    name: "Toppan Merrill",
    category: "Filing Agent",
    website: "toppanmerrill.com",
  },
  {
    cik: "0001558370",
    name: "Toppan Merrill",
    category: "Filing Agent",
    website: "toppanmerrill.com",
  },
] as const;

export const FILING_AGENTS_LOOKUP = buildLookup([...agentsList], (c) => c.cik);

export type FilingAgent = (typeof FILING_AGENTS_LOOKUP)[string];
