import clsx from "clsx";
import { useMultistageFlowContext } from "../../../_common/components/MultistageFlow/context";
import { WrappedData } from "../../types";

export function Stepper() {
  const { stages, currentStage, goTo } =
    useMultistageFlowContext<WrappedData>();

  return (
    <div className="flex w-full justify-end gap-2 md:w-auto md:flex-col md:justify-center md:gap-8">
      {stages.map((s) => (
        <button
          className={clsx(
            "flex size-8 items-center justify-center rounded-full text-xs transition-all md:size-10 md:text-base",
            {
              "bg-[#AFB1FF]": currentStage.id !== s.id,
              "bg-gradient-to-br from-[#FFCF4A] via-[#BFB2B6] to-[#5CC5D8]":
                currentStage.id === s.id,
            },
          )}
          onClick={() => goTo(s.id)}
        >
          {s.id}
        </button>
      ))}
    </div>
  );
}
