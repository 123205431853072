import { axios } from "./client";

export interface ExtractedReportingOwner {
  reporting_owner_name: string;
  address_street_1: string;
  address_street_2?: string;
  address_city: string;
  address_state: string;
  address_zipcode: string;
  is_director: boolean;
  is_officer: boolean;
  is_ten_percent_owner: boolean;
  is_other: boolean;
}

export interface ExtractedNonDerivativeTransaction {
  security_title: string;
  transaction_date: string;
  transaction_code: string;
  transaction_amount: string;
  acquired_disposed: string;
  price: string;
  amount_owned: string;
  indicator_10b5_1: string;
  ownership_form: string;
}

export interface ExtractedDerivativeTransaction {
  security_title: string;
  conversion_or_exercise_price: string;
  transaction_date: string;
  transaction_code: string;
  number_of_derivative_securities_acquired: string;
  number_of_derivative_securities_disposed: string;
  date_exercisable: string;
  expiration_date: string;
  title_of_underlying_security: string;
  number_of_shares_of_underlying_security: string;
  amount_beneficially_owned: string;
  indicator_10b5_1: string;
  ownership_form: string;
}

export type ExtractedFidelityTransaction =
  | ExtractedDerivativeTransaction
  | ExtractedNonDerivativeTransaction;

export interface FidelityForm4ExtractResult {
  non_derivative_transactions: ExtractedNonDerivativeTransaction[];
  derivative_transactions: ExtractedDerivativeTransaction[];
  reporting_owner: ExtractedReportingOwner;
}

export async function fidelityExtract(uploaded_file: File) {
  const fd = new FormData();
  fd.append("uploaded_file", uploaded_file);

  const { data } = await axios.post<FidelityForm4ExtractResult>(
    "section_16/parse_fidelity_form_4/",
    fd
  );

  return data;
}

export function isFidelityDerivativeTransaction(
  t: ExtractedFidelityTransaction
) {
  return "title_of_underlying_security" in t;
}
