import { components } from "../../../generated/types";
import { axios } from "./client";
import { FilterBackendApiResponse } from "./types";

export type TEditableBlockTemplate =
  components["schemas"]["EditableBlockTemplate"];

export async function searchEditableBlockTemplates(query: string) {
  const { data } = await axios.get<
    FilterBackendApiResponse<TEditableBlockTemplate>
  >(`editable_block_templates/`, {
    params: {
      search: query,
    },
  });

  return data.results;
}
