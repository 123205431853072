import { Select } from "@headlessui/react";
import { ChangeEventHandler } from "react";
import clsx from "clsx";

import { TDocument, TReferenceDocument } from "../../../_common/api/filings";
import {
  isReferenceDocument,
  updateDocument,
  updateReferenceDocument,
} from "../../../_common/api/document";
import { useExhibitTypeOptions } from "../../../_common/hooks/useExhibitTypeOptions";

export function ExhibitTypeCell(doc: TDocument | TReferenceDocument) {
  const options = useExhibitTypeOptions();

  const onChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const val = e.currentTarget.value;
    if (!val || val === doc.exhibit_type) {
      return;
    }

    if (isReferenceDocument(doc)) {
      updateReferenceDocument(doc.id, {
        exhibit_type: val,
      });
    } else {
      updateDocument(doc.id, {
        exhibit_type: val,
      });
    }
  };

  return (
    <Select
      value={doc.exhibit_type}
      onChange={onChange}
      className={clsx(
        "w-full overflow-hidden text-ellipsis whitespace-nowrap",
        {
          "text-red-500": doc.exhibit_type === "UNKNOWN",
        },
      )}
    >
      {options.map(({ label, value }) => (
        <option value={value} key={value}>
          {label}
        </option>
      ))}
    </Select>
  );
}
