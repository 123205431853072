import { Link } from "react-router-dom";
import { AnimatedGrowthCard } from "../../_common/components/AnimatedGrowthCard";
import { Button } from "../../_common/components/Button";
import { buildLookup } from "../../_common/utils/list";
import { useStubbedData } from "../hooks/useStubbedData";
import { ReportingOwnersPanel } from "./Section16Index/ReportingOwnersPanel";
import { ScheduledSubmissionsPanel } from "./Section16Index/ScheduledSubmissionsPanel";
import { SecuritiesPanel } from "./Section16Index/SecuritiesPanel";
import { SixteenBMonitoringPanel } from "./Section16Index/SixteenBMonitoringPanel";
import { TransactionHistoryPanel } from "./Section16Index/TransactionHistoryPanel";
import { UnreportedTransactionsPanel } from "./Section16Index/UnreportedTransactionsPanel";
import { FidelityExtractUploadButton } from "./FidelityExtractUploadButton/FidelityExtractUploadButton";
import { TransactionLine } from "./Section16Index/TransactionLine";

export function Section16Index() {
  const {
    derivativeTransactions,
    nonDerivativeTransactions,
    reportingOwners,
    securityTypes,
    scheduledFilings,
    transactionHistory,
  } = useStubbedData();
  const reportingOwnersLookup = buildLookup(reportingOwners, (k) => k.id);

  return (
    <div className="grid grid-cols-4 gap-2 h-full">
      <div className="col-span-4 row-span-1 flex justify-between items-center">
        <h2 className="font-semibold">Section 16 Dashboard</h2>
        <div className="flex items-center gap-2">
          <FidelityExtractUploadButton />
          <Link to="new">
            <Button variant="default" color="blue">
              Create report
            </Button>
          </Link>
        </div>
      </div>
      <div className="col-span-2 row-span-4">
        <AnimatedGrowthCard
          modalContent={<UnreportedTransactionsPanel />}
          className="border rounded w-full h-full shadow bg-white hover:shadow-lg transition"
        >
          <div className="p-4 space-y-2 overflow-auto text-left flex-1">
            <p className="text-gray-500 border-b">Unreported Transactions</p>
            <ul className="text-gray-600 text-sm bg-bgSecondary p-2 rounded space-y-2">
              {[...nonDerivativeTransactions, ...derivativeTransactions].map(
                (transaction) => (
                  <TransactionLine
                    key={transaction.id}
                    transaction={transaction}
                    reportingOwnerName={
                      reportingOwnersLookup[transaction.reportingOwnerId]
                        .reporting_owner_name
                    }
                  />
                )
              )}
            </ul>
          </div>
        </AnimatedGrowthCard>
      </div>
      <div className="col-span-2 row-span-4">
        <AnimatedGrowthCard
          modalContent={<ScheduledSubmissionsPanel />}
          className="border rounded w-full h-full shadow bg-white hover:shadow-lg transition"
        >
          <div className="p-4 space-y-2 overflow-auto text-left flex-1">
            <p className="text-gray-500">Submission Queue</p>
            <ul className="text-gray-600 text-sm bg-bgSecondary p-2 rounded space-y-2">
              {scheduledFilings.map((filing) => (
                <li className="flex gap-1 items-center" key={filing.id}>
                  <span className="underline font-medium">{filing.label}</span>

                  <span className="font-normal">status:</span>

                  <span className="font-medium">{filing.status}</span>
                </li>
              ))}
            </ul>
          </div>
        </AnimatedGrowthCard>
      </div>
      <div className="col-span-2 row-span-2">
        <AnimatedGrowthCard
          modalContent={<ReportingOwnersPanel />}
          className="border rounded w-full h-full shadow bg-white hover:shadow-lg transition"
        >
          <div className="p-4 space-y-2 overflow-auto text-left flex-1">
            <p className="text-gray-500">Reporting Owners</p>
            <p className="text-gray-600 text-3xl text-center">
              {reportingOwners.length}
            </p>
          </div>
        </AnimatedGrowthCard>
      </div>
      <div className="col-span-2 row-span-2">
        <AnimatedGrowthCard
          modalContent={<SecuritiesPanel />}
          className="border rounded w-full h-full shadow bg-white hover:shadow-lg transition"
        >
          <div className="p-4 space-y-2 overflow-auto text-left flex-1">
            <p className="text-gray-500">Securities</p>
            <p className="text-gray-600 text-3xl text-center">
              {securityTypes.length}
            </p>
          </div>
        </AnimatedGrowthCard>
      </div>
      <div className="col-span-1 row-span-4">
        <AnimatedGrowthCard
          modalContent={<SixteenBMonitoringPanel />}
          className="border rounded w-full h-full shadow bg-white hover:shadow-lg transition"
        >
          <div className="p-4 space-y-2 overflow-auto text-left flex-1">
            <p className="text-gray-500">16(b) Monitor</p>
            <p className="text-gray-400 text-sm">
              No 16(b) transactions to report.
            </p>
          </div>
        </AnimatedGrowthCard>
      </div>
      <div className="col-span-3 row-span-4">
        <AnimatedGrowthCard
          modalContent={<TransactionHistoryPanel />}
          className="border rounded w-full h-full shadow bg-white hover:shadow-lg transition"
        >
          <div className="p-4 space-y-2 overflow-auto text-left flex-1">
            <p className="text-gray-500">Transaction History</p>
            <ul className="text-gray-600 text-sm bg-bgSecondary p-2 rounded">
              {transactionHistory.map((transaction) => (
                <TransactionLine
                  key={transaction.id}
                  transaction={transaction}
                  reportingOwnerName={
                    reportingOwnersLookup[transaction.reportingOwnerId]
                      .reporting_owner_name
                  }
                />
              ))}
            </ul>
          </div>
        </AnimatedGrowthCard>
      </div>
    </div>
  );
}
