import { useRef } from "react";
import { EditorProvider, EditorEvents, AnyExtension } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import { EditorProps } from "@tiptap/pm/view";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TextAlign from "@tiptap/extension-text-align";

import { MenuBar } from "./MenuBar";
import { TableMenuControls } from "./TableMenuControls";
import { TextIndent } from "./TextIndent";

// define your extension array
const extensions: AnyExtension[] = [
  StarterKit.configure({ heading: { levels: [1] } }),
  Underline,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  TextIndent.configure({
    types: ["paragraph"],
  })
];

const editorProps: EditorProps = {
  attributes: { class: `p-2 cursor-text` },
};

interface RichTextEditorProps {
  initialContent: string;
  onContentUpdate: (html: string) => void;
}
export function RichTextEditor({
  initialContent,
  onContentUpdate,
}: RichTextEditorProps) {
  const timeoutRef = useRef<number>();
  function handleUpdate(e: EditorEvents["update"]) {
    clearTimeout(timeoutRef.current);

    const html = e.editor.getHTML();

    timeoutRef.current = setTimeout(() => {
      onContentUpdate(html);
    }, 2000);
  }

  return (
    <div className="bg-gray-50 shadow-inner rounded-md rich_text">
      <EditorProvider
        content={initialContent}
        extensions={extensions}
        onUpdate={handleUpdate}
        slotBefore={<MenuBar />}
        editorProps={editorProps}
      >
        <TableMenuControls />
      </EditorProvider>
    </div>
  );
}
