import { useForm } from "react-hook-form";
import { merge, omitBy } from "remeda";
import { useCallback, useEffect, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { TEntity, TEntitySecData } from "../../_common/api/entity";
import { Input } from "../../_common/components/Input";
import { Label } from "../../_common/components/Label";
import { Field, Fieldset } from "@headlessui/react";
import { Button } from "../../_common/components/Button";

interface EditEntitySecDataFormProps {
  entity?: TEntity;
  onSubmit: (data: Partial<TEntitySecData>) => void;
  onCancel: () => void;
}

export function EditEntitySecDataForm({
  entity,
  onSubmit,
  onCancel,
}: EditEntitySecDataFormProps) {
  /*
  - Default values should be set to the entity.sec_data
  - Placeholder values should be set to the result of the getEntitySecData call
  - This form should always be updating the full entity.sec_data
  */
  const { register, handleSubmit, reset } = useForm<TEntitySecData>({
    defaultValues: initialValues(entity?.sec_data),
  });

  const { data: secApiCompanyData } = useQuery<TEntitySecData>({
    queryKey: [`entities/${entity?.id}/sec-data`],
    enabled: !!entity,
  });

  useEffect(() => {
    reset(initialValues(entity?.sec_data));
  }, [entity, reset]);

  const secAddressData = useMemo(
    () => Object.values(secApiCompanyData?.addresses || {})[0],
    [secApiCompanyData],
  );

  const submitWrapper = useCallback(
    (data: TEntitySecData) => {
      const nonEmptyData = omitBy(
        data,
        (value) => value === "" || value === undefined,
      );

      const hasCustomAddressData = Object.values(
        data.addresses?.custom || {},
      ).some((v) => v);

      if (hasCustomAddressData) {
        const nonEmptyAddressData = omitBy(
          data.addresses?.custom || {},
          (v) => v === "" || v === undefined,
        );

        nonEmptyData.addresses = {
          custom: merge(secAddressData, nonEmptyAddressData),
        };
      } else {
        delete nonEmptyData.addresses;
      }

      onSubmit(nonEmptyData);
    },
    [onSubmit, secAddressData],
  );

  return (
    <form
      onSubmit={handleSubmit(submitWrapper)}
      className="flex flex-col h-[400px]"
    >
      <div className="space-y-4 overflow-auto flex-1 p-2">
        <Field className="flex flex-col gap-2 items-stretch">
          <Label>Name</Label>
          <Input {...register("name")} placeholder={secApiCompanyData?.name} />
        </Field>
        <Field className="flex flex-col gap-2 items-stretch">
          <Label>EIN</Label>
          <Input {...register("ein")} placeholder={secApiCompanyData?.ein} />
        </Field>
        <Field className="flex flex-col gap-2 items-stretch">
          <Label>File Number</Label>
          <Input {...register("sic")} placeholder={secApiCompanyData?.sic} />
        </Field>
        <Field className="flex flex-col gap-2 items-stretch">
          <Label>State of Incorporation</Label>
          <Input
            {...register("stateOfIncorporation")}
            placeholder={secApiCompanyData?.stateOfIncorporation}
          />
        </Field>
        <Field className="flex flex-col gap-2 items-stretch">
          <Label>Phone Number</Label>
          <Input
            {...register(`phone`)}
            placeholder={secApiCompanyData?.phone}
          />
        </Field>

        <Fieldset className="space-y-2 flex flex-col gap-2 items-stretch">
          <div className="grid grid-cols-2 gap-2">
            <Field className="flex flex-col gap-2 items-stretch col-span-2">
              <Label>Street 1</Label>
              <Input
                {...register(`addresses.custom.street1`)}
                placeholder={secAddressData?.street1}
              />
            </Field>
            <Field className="flex flex-col gap-2 items-stretch col-span-2">
              <Label>Street 2</Label>
              <Input
                {...register(`addresses.custom.street2`)}
                placeholder={secAddressData?.street2}
              />
            </Field>
            <Field className="flex flex-col gap-2 items-stretch col-span-1">
              <Label>City</Label>
              <Input
                {...register(`addresses.custom.city`)}
                placeholder={secAddressData?.city}
              />
            </Field>
            <Field className="flex flex-col gap-2 items-stretch col-span-1">
              <Label>State</Label>
              <Input
                {...register(`addresses.custom.stateOrCountry`)}
                placeholder={secAddressData?.stateOrCountry}
              />
            </Field>
            <Field className="flex flex-col gap-2 items-stretch col-span-1">
              <Label>Zip</Label>
              <Input
                {...register(`addresses.custom.zipCode`)}
                placeholder={secAddressData?.zipCode}
              />
            </Field>
          </div>
        </Fieldset>
      </div>

      <div className="flex justify-between gap-2 flex-0 p-2">
        <Button
          onClick={onCancel}
          type="button"
          variant="default"
          color="gray"
          className="flex-1"
        >
          Cancel
        </Button>
        <Button type="submit" variant="default" color="blue" className="flex-1">
          Save
        </Button>
      </div>
    </form>
  );
}

function initialValues(secData?: TEntitySecData): Partial<TEntitySecData> {
  if (!secData) return {};

  return {
    cik: secData?.cik,
    name: secData?.name,
    sic: secData?.sic,
    ein: secData?.ein,
    stateOfIncorporation: secData?.stateOfIncorporation,
    phone: secData?.phone,
    addresses: {
      custom: {
        street1: secData?.addresses?.custom?.street1 || "",
        street2: secData?.addresses?.custom?.street2 || "",
        city: secData?.addresses?.custom?.city || "",
        stateOrCountry: secData?.addresses?.custom?.stateOrCountry || "",
        stateOrCountryDescription:
          secData?.addresses?.custom?.stateOrCountryDescription || "",
        zipCode: secData?.addresses?.custom?.zipCode || "",
      },
    },
  };
}
