import { Table } from "../../_common/components/Table/Table";
import {
  TableActionConfig,
  TableHeaderConfig,
} from "../../_common/components/Table/types";
import {
  TEntity,
  TEntitySecData,
  updateEntity,
} from "../../_common/api/entity";

import { EditableEntityTableCell } from "./EditableEntityTableCell";
import { EntityLogoCell } from "./EntityLogoCell";
import { useState } from "react";
import { Modal } from "../../_common/components/Modal/Modal";
import { EditEntitySecDataForm } from "./EditEntitySecDataForm";
import {
  useDispatchErrorToast,
  useDispatchInfoToast,
} from "../../_common/components/Toasts/context";
import { isAxiosError } from "axios";
import { Description } from "@headlessui/react";
import { EntitySecuritiesCell } from "./EntitySecuritiesCell";
import { useCurrentUser } from "../../_common/hooks/useCurrentUser";
import { PlusIcon } from "../../_common/components/icons/PlusIcon";
import { ButtonLink } from "../../_common/components/ButtonLink";
import { AddEntityModal } from "./AddEntityModal";

interface EntityTableProps {
  entities: TEntity[];
}

export function EntityTable({ entities }: EntityTableProps) {
  const user = useCurrentUser();
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [stagedEntity, setStagedEntity] = useState<TEntity>();
  const entityActions: TableActionConfig<TEntity>[] = [
    {
      label: "Edit",
      callback: (entity) => {
        setStagedEntity(entity);
        setIsEditing(true);
      },
    },
  ];

  function handleClose() {
    setIsEditing(false);
    setIsAdding(false);
  }

  const dispatchErrorToast = useDispatchErrorToast();
  const dispatchSuccessToast = useDispatchInfoToast();

  async function handleSubmitEditEntityModal(data: Partial<TEntitySecData>) {
    if (!stagedEntity) return;

    try {
      await updateEntity(stagedEntity.id, { sec_data: data });
      dispatchSuccessToast("Entity updated successfully!", 2000);
    } catch (err) {
      if (isAxiosError(err)) {
        dispatchErrorToast(`Failed to update entity: ${err.message}`, 3000);
      } else {
        dispatchErrorToast("Failed to update entity", 3000);
      }
    } finally {
      handleClose();
    }
  }

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-semibold">Entities</h2>
          {user?.is_staff && (
            <ButtonLink onClick={() => setIsAdding(true)}>
              <PlusIcon />
              <span>Add</span>
            </ButtonLink>
          )}
      </div>
        <Table
          data={entities}
          headers={headerConfigs}
          rowKey="id"
          actions={entityActions}
          emptyState={<div className="py-2 pl-7">No Entities</div>}
        />

        <AddEntityModal open={isAdding} onClose={handleClose} />

        <Modal open={isEditing} onClose={handleClose} title="Edit Entity">
          <div className="w-[400px] space-y-4">
            <Description className="text-sm text-gray-500">
              Changes to this data will not be reflected in documents uploaded
              prior to changing. You will need to re-upload documents to see the
              new changes reflected.
            </Description>
            <EditEntitySecDataForm
              entity={stagedEntity}
              onSubmit={handleSubmitEditEntityModal}
              onCancel={handleClose}
            />
          </div>
        </Modal>
      </div>
    </>
  );
}

const headerConfigs: TableHeaderConfig<TEntity>[] = [
  {
    label: "Name",
    getStringContent: (entity) => entity.name,
    renderCellContent: (entity) => (
      <EditableEntityTableCell entity={entity} keyName="name" />
    ),
    hasTooltip: false,
  },
  {
    label: "CIK",
    getStringContent: (entity) => entity.cik,
    renderCellContent: (entity) => (
      <EditableEntityTableCell entity={entity} keyName="cik" />
    ),
    hasTooltip: false,
  },
  // TODO: Uncomment when we support hiding the field value until clicked
  // {
  //   label: "CCC",
  //   getStringContent: (entity) => entity.ccc,
  //   renderCellContent: (entity) => (
  //     <EditableEntityTableCell entity={entity} keyName="ccc" />
  //   ),
  //   hasTooltip: false,
  // },
  {
    label: "Logo",
    getStringContent: () => "",
    renderCellContent: (entity) => <EntityLogoCell entity={entity} />,
    hasTooltip: false,
  },
  {
    label: "Securities",
    getStringContent: () => "",
    renderCellContent: (entity) => <EntitySecuritiesCell entity={entity} />,
    hasTooltip: false,
  },
];
