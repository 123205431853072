import { CommonIconProps } from "./_common";

interface HotDogIconProps extends CommonIconProps {}

export function HotDogIcon(props: HotDogIconProps) {
  return (
    <svg
      width="24"
      height="6"
      viewBox="0 0 24 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="21" cy="3" r="3" fill="currentColor" />
      <circle cx="12" cy="3" r="3" fill="currentColor" />
      <circle cx="3" cy="3" r="3" fill="currentColor" />
    </svg>
  );
}
