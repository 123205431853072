import { CommonIconProps } from "./_common";

interface PaperClipIconProps extends CommonIconProps {}

export function PaperClipIcon({ ...props }: PaperClipIconProps) {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.82472 13.6454L10.9621 7.60279C11.6989 6.87734 11.6989 5.70109 10.9621 4.9756C10.2252 4.25011 9.03054 4.25011 8.29368 4.9756L2.20079 10.9745C0.800749 12.3529 0.800749 14.5877 2.20079 15.9661C3.60082 17.3446 5.87073 17.3446 7.27075 15.9661L13.4526 9.87975C15.5158 7.84839 15.5158 4.55489 13.4526 2.52352C11.3894 0.492159 8.04425 0.492159 5.98103 2.52352L1 7.42767"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
