import { useMemo } from "react";
import { isDerivative, useStubbedData } from "../../hooks/useStubbedData";
import { TransactionLine } from "./TransactionLine";

interface ReportingOwnerSecuritiesProps {
  reportingOwnerId: number;
}
export function ReportingOwnerSecurities({
  reportingOwnerId,
}: ReportingOwnerSecuritiesProps) {
  const {
    transactionHistory,
    derivativeTransactions,
    nonDerivativeTransactions,
    reportingOwners,
  } = useStubbedData();

  const reportingOwner = useMemo(
    () => reportingOwners.find((d) => d.id === reportingOwnerId),
    [reportingOwnerId, reportingOwners]
  );

  const ownerReportedTransactions = [
    ...derivativeTransactions,
    ...nonDerivativeTransactions,
  ].filter((t) => t.reportingOwnerId === reportingOwnerId);

  const reportedHoldings = useMemo(() => {
    const ownerReportedHoldings: Record<string, number> = {};
    for (const transaction of transactionHistory) {
      if (transaction.reportingOwnerId !== reportingOwnerId) {
        continue;
      }
      const securityTitle = transaction.security_title;

      ownerReportedHoldings[securityTitle] =
        ownerReportedHoldings[securityTitle] || 0;

      if (isDerivative(transaction)) {
        ownerReportedHoldings[securityTitle] +=
          parseInt(transaction.number_of_derivative_securities_acquired) || 0;
        ownerReportedHoldings[securityTitle] -=
          parseInt(transaction.number_of_derivative_securities_acquired) || 0;
      } else if (transaction.acquired_disposed === "A") {
        ownerReportedHoldings[securityTitle] += parseInt(
          transaction.transaction_amount
        );
      } else {
        ownerReportedHoldings[securityTitle] -= parseInt(
          transaction.transaction_amount
        );
      }
    }

    return ownerReportedHoldings;
  }, [transactionHistory, reportingOwnerId]);

  return (
    <div className="space-y-4">
      <h3 className="font-semibold text-lg text-center">Holdings Snapshot</h3>

      <div className="space-y-2">
        <h3 className="font-semibold">Totals by Security</h3>
        <div className="divide-y">
          {Object.keys(reportedHoldings).map((title) => (
            <dl key={title} className="grid grid-cols-2 gap-2 text-sm py-2">
              <dt className="text-gray-500">Title</dt>
              <dd>{title}</dd>
              <dt className="text-gray-500">Reported holdings</dt>
              <dd>{reportedHoldings[title]}</dd>
            </dl>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <h3 className="font-semibold">Transactions</h3>
        <ul className="text-gray-500">
          {ownerReportedTransactions.map((transaction) => (
            <TransactionLine
              key={transaction.id}
              transaction={transaction}
              reportingOwnerName={reportingOwner?.reporting_owner_name || "N/A"}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}
