import { useQuery } from "@tanstack/react-query";

import { filingQueryKey, TFiling } from "../_common/api/filings";
import { useParams } from "react-router-dom";
import { FullscreenLoader } from "../_common/components/FullscreenLoader";
import { Breadcrumbs } from "../_common/components/Breadcrumbs/Breadcrumbs";
import { Card } from "../_common/components/Card";
import { FilingPreview } from "./components/FilingPreview";
import { ID } from "../_common/types/id";
import { TSubmission } from "../_common/api/submission";

export function Submission() {
  const { filingId } = useParams();
  const { data: submissionData } = useQuery<TSubmission>({
    queryKey: [`filings/${filingId}/submission`],
  });

  const { data: filingData } = useQuery<TFiling>({
    queryKey: [filingQueryKey(filingId as ID)],
  });

  if (!filingData || !submissionData) return <FullscreenLoader />;

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs className="flex-none" />
      <div className="flex-1 border-t py-4 space-y-8">
        <Card className="py-5">
          <h3 className="text-lg font-semibold px-5">Submission Details</h3>

          <dl className="divide-y mt-2">
            <div className="flex py-2 px-5">
              <dt className="w-1/3 font-semibold text-sm">Name</dt>
              <dd className="w-2/3 text-gray-500 text-xs">{filingData.name}</dd>
            </div>

            <div className="flex py-2 px-5">
              <dt className="w-1/3 font-semibold text-sm">Submission Type</dt>
              <dd className="w-2/3 text-gray-500 text-xs">
                {submissionData.submission_type}
              </dd>
            </div>

            <div className="flex py-2 px-5">
              <dt className="w-1/3 font-semibold text-sm">Submission Status</dt>
              <dd className="w-2/3 text-gray-500 text-xs">
                {submissionData.status}
              </dd>
            </div>

            {submissionData.accession_number && (
              <div className="flex py-2 px-5">
                <dt className="w-1/3 font-semibold text-sm">
                  Accession Number
                </dt>
                <dd className="w-2/3 text-gray-500 text-xs">
                  {submissionData.accession_number}
                </dd>
              </div>
            )}
          </dl>
        </Card>

        <FilingPreview filingId={filingId as ID} />
      </div>
    </div>
  );
}
