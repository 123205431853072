import { PropsWithChildren } from "react";

import star from "./Star.svg";
import clsx from "clsx";

type StatBlockProps = PropsWithChildren<{
  // sm:  185px, 80px |
  // md: 210px, 80px | 120px, 55px
  size: "md" | "sm" | "custom";
  className?: string
}>;

export function StatBlock({ children, size, className }: StatBlockProps) {
  return (
    <div className="inline-block bg-gradient-to-br from-[#FFD256] to-[#67C8D9] p-6 md:p-8">
      <div
        className={clsx(
          "flex items-center justify-center bg-cover font-semibold text-[#8E3FDA]",
          className,
          {
            "size-24 text-3xl md:size-48 md:text-7xl": size === "sm",
            "size-48 text-4xl md:text-7xl": size === "md",
          },
        )}
        style={{
          backgroundImage: `url("${star}")`,
        }}
      >
        {children}
      </div>
    </div>
  );
}
