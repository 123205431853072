import { HtmlHTMLAttributes, PropsWithChildren } from "react";
import bgGradient from './bg_gradient.png'
import clsx from "clsx";

type GradientTextProps = PropsWithChildren<HtmlHTMLAttributes<HTMLSpanElement>>
export function GradientText({ children, className, ...rest }: GradientTextProps) {


  return <span {...rest} style={{ backgroundImage: `url(${bgGradient})` }} className={clsx(className, "bg-clip-text text-transparent bg-center bg-cover bg-no-repeat")}>{children}</span>
}
