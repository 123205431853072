import { ID } from "../types/id";
import { axios, queryClient } from "./client";
import { TEditableBlock } from "./constructedDocument";
import {
  filingQueryKey,
  TConstructedPrimaryDocument,
  TFiling,
} from "./filings";

export interface CreateEditableBlockParams {
  title: string;
  content: string;
  source_template?: ID;
}

export async function createEditableBlock(
  filingId: ID,
  documentId: ID,
  params: CreateEditableBlockParams
) {
  const { data } = await axios.post("editable_blocks/", {
    ...params,
    document_id: documentId,
  });

  _updateEditableBlockInCache(filingId, documentId, data);

  return data;
}

interface UpdateEditableBlockParams {
  title?: string;
  content?: string;
}

export async function updateEditableBlock(
  filingId: ID,
  documentId: ID,
  id: ID,
  params: UpdateEditableBlockParams
) {
  const { data } = await axios.patch(`editable_blocks/${id}/`, params);

  _updateEditableBlockInCache(filingId, documentId, data);

  return data;
}

function _updateEditableBlockInCache(
  filingId: ID,
  documentId: ID,
  data: TEditableBlock
) {
  const existingFilingData = queryClient.getQueryData<TFiling>([
    filingQueryKey(filingId),
  ]);
  if (!existingFilingData) return;

  const existingDocumentData =
    existingFilingData.constructed_primary_documents.find(
      (d) => d.id === documentId
    );

  if (!existingDocumentData) {
    return;
  }

  let existing = false;
  const updatedEditableBlocks = existingDocumentData.editable_blocks.map(
    (eb) => {
      if (data.id === eb.id) {
        existing = true;
        return data;
      }

      return eb;
    }
  );
  if (!existing) {
    updatedEditableBlocks.push(data);
  }

  const updatedDocumentData: TConstructedPrimaryDocument = {
    ...existingDocumentData,
    editable_blocks: updatedEditableBlocks,
  };

  const updatedFilingData: TFiling = {
    ...existingFilingData,
    constructed_primary_documents:
      existingFilingData.constructed_primary_documents.map((d) => {
        if (d.id === documentId) return updatedDocumentData;

        return d;
      }),
  };

  queryClient.setQueryData([filingQueryKey(filingId)], updatedFilingData);
}

function _removeEditableBlockFromCache(
  filingId: ID,
  documentId: ID,
  blockId: ID
) {
  const existingFilingData = queryClient.getQueryData<TFiling>([
    filingQueryKey(filingId),
  ]);
  if (!existingFilingData) return;

  const existingDocumentData =
    existingFilingData.constructed_primary_documents.find(
      (d) => d.id === documentId
    );

  if (!existingDocumentData) {
    return;
  }

  const updatedEditableBlocks = existingDocumentData.editable_blocks.filter(
    (b) => b.id !== blockId
  );

  const updatedDocumentData: TConstructedPrimaryDocument = {
    ...existingDocumentData,
    editable_blocks: updatedEditableBlocks,
  };

  const updatedFilingData: TFiling = {
    ...existingFilingData,
    constructed_primary_documents:
      existingFilingData.constructed_primary_documents.map((d) => {
        if (d.id === documentId) return updatedDocumentData;

        return d;
      }),
  };

  queryClient.setQueryData([filingQueryKey(filingId)], updatedFilingData);
}

export async function deleteEditableBlock(
  filingId: ID,
  documentId: ID,
  blockId: ID
) {
  await axios.delete(`editable_blocks/${blockId}/`);

  _removeEditableBlockFromCache(filingId, documentId, blockId);
}
