import { useParams } from "react-router-dom"
import { useMultistageFlowContext } from "../../../_common/components/MultistageFlow/context"
import { useTrack } from "../../../_common/utils/tracking"

export function WrappedStepTracking() {
  const { ticker = "unknown", year = 'unknown' } = useParams<{ ticker: string, year: string }>()
  const { currentStage } = useMultistageFlowContext()

  useTrack({
    name: 'wrapped_step_viewed', params: {
      stepName: currentStage.id,
      ticker,
      year

    }
  })
  return null
}
