import { TEntity } from "../../../_common/api/entity";
import { StubbedReportingOwner } from "../../hooks/useStubbedData";
import { formatDate } from "../../utils/formatDate";

interface ReportingOwnerTableProps {
  reportingOwner: StubbedReportingOwner;
  earliestTransactionDate?: Date | null;
  issuer: TEntity;
}
export function ReportingOwnerTable({
  reportingOwner,
  earliestTransactionDate,
  issuer,
}: ReportingOwnerTableProps) {
  return (
    <table style={{ width: "100%" }} border={1} cellSpacing="0" cellPadding="4">
      <tbody>
        <tr>
          <td rowSpan={4} style={{ width: "35%" }} valign="top">
            <span className="MedSmallFormText">
              1. Name and Address of Reporting Person<sup>*</sup>
            </span>
            <table border={0} style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>
                    <a href="/cgi-bin/browse-edgar?action=getcompany&amp;CIK=0001513142">
                      {reportingOwner.reporting_owner_name}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr style={{ width: "98%" }} />
            <table border={0} style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: "33%" }} className="MedSmallFormText">
                    (Last)
                  </td>
                  <td style={{ width: "33%" }} className="MedSmallFormText">
                    (First)
                  </td>
                  <td style={{ width: "33%" }} className="MedSmallFormText">
                    (Middle)
                  </td>
                </tr>
              </tbody>
            </table>
            <table border={0} style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>
                    <span className="FormData">
                      {reportingOwner.address_street_1}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="FormData">
                      {reportingOwner.address_street_2}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr style={{ width: "98%" }} />
            <span className="MedSmallFormText">(Street)</span>
            <table border={0} style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: "33%" }}>
                    <span className="FormData">
                      {reportingOwner.address_city}
                    </span>
                  </td>
                  <td style={{ width: "33%" }}>
                    <span className="FormData">
                      {reportingOwner.address_state}
                    </span>
                  </td>
                  <td style={{ width: "33%" }}>
                    <span className="FormData">
                      {reportingOwner.address_zipcode}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <hr style={{ width: "98%" }} />
            <table border={0} style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: "33%" }} className="MedSmallFormText">
                    (City)
                  </td>
                  <td style={{ width: "33%" }} className="MedSmallFormText">
                    (State)
                  </td>
                  <td style={{ width: "33%" }} className="MedSmallFormText">
                    (Zip)
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td style={{ width: "35%" }} valign="top">
            <span className="MedSmallFormText">
              2. Issuer Name <b>and</b> Ticker or Trading Symbol
            </span>
            <br />
            <a href="/cgi-bin/browse-edgar?action=getcompany&amp;CIK=0000789019">
              {issuer.name}
            </a>
            [ <span className="FormData">{issuer.securities?.[0]?.ticker}</span>{" "}
            ]
          </td>
          <td rowSpan={2} valign="top">
            <span className="MedSmallFormText">
              5. Relationship of Reporting Person(s) to Issuer
            </span>
            <br />
            <span className="MedSmallFormText">(Check all applicable)</span>
            <table border={0} style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: "15%" }} align="center">
                    <span className="FormData">
                      {reportingOwner.is_director ? "X" : ""}
                    </span>
                  </td>
                  <td style={{ width: "35%" }} className="MedSmallFormText">
                    Director
                  </td>
                  <td style={{ width: "15%" }} align="center">
                    <span className="FormData">
                      {reportingOwner.is_ten_percent_owner ? "X" : ""}
                    </span>
                  </td>
                  <td style={{ width: "35%" }} className="MedSmallFormText">
                    10% Owner
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <span className="FormData">
                      {reportingOwner.is_officer ? "X" : ""}
                    </span>
                  </td>
                  <td className="MedSmallFormText">
                    Officer (give title below)
                  </td>
                  <td align="center">
                    <span className="FormData">
                      {reportingOwner.is_other ? "X" : ""}
                    </span>
                  </td>
                  <td className="MedSmallFormText">Other (specify below)</td>
                </tr>
                <tr>
                  <td style={{ width: "15%" }} align="center"></td>
                  <td align="left" style={{ width: "35%", color: "blue" }}></td>
                  <td style={{ width: "15%" }} align="center"></td>
                  <td align="left" style={{ width: "35%", color: "blue" }}></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <span className="MedSmallFormText">
              3. Date of Earliest Transaction (Month/Day/Year)
            </span>
            <br />
            <span className="FormData">
              {earliestTransactionDate
                ? formatDate(earliestTransactionDate)
                : "N/A"}
            </span>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <span className="MedSmallFormText">
              4. If Amendment, Date of Original Filed (Month/Day/Year)
            </span>
            <br />
          </td>
          <td valign="top">
            <span className="MedSmallFormText">
              6. Individual or Joint/Group Filing (Check Applicable Line)
            </span>
            <table border={0} style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: "15%" }} align="center">
                    <span className="FormData">X</span>
                  </td>
                  <td style={{ width: "85%" }} className="MedSmallFormText">
                    Form filed by One Reporting Person
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "15%" }} align="center"></td>
                  <td style={{ width: "85%" }} className="MedSmallFormText">
                    Form filed by More than One Reporting Person
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
