import { Link } from "react-router-dom";
import { Table } from "../../../_common/components/Table/Table";
import { useStubbedData } from "../../hooks/useStubbedData";

export function ScheduledSubmissionsPanel() {
  const { scheduledFilings } = useStubbedData();
  console.log(scheduledFilings);

  return (
    <div className="p-4">
      <div className="space-y-4">
        <h3 className="font-semibold">Scheduled Filings</h3>

        <Table
          data={scheduledFilings}
          actions={[]}
          rowKey="id"
          emptyState="No Scheduled Filings"
          headers={[
            {
              label: "Label",
              getStringContent: (d) => d.label,
              renderCellContent: (d) => (
                <Link to={`${d.id}`} className="text-brandBlue hover:underline">
                  {d.label}
                </Link>
              ),
              hasTooltip: false,
            },
            {
              label: "Status",
              getStringContent: (d) => d.status,
              hasTooltip: false,
            },
          ]}
        />
      </div>
    </div>
  );
}
