import { Controller, useForm } from "react-hook-form";
import {
  CreateReferenceDocumentParams,
  UpdateReferenceDocumentParams,
} from "../../_common/api/document";
import { Field, Fieldset } from "@headlessui/react";
import { Label } from "../../_common/components/Label";
import { Input } from "../../_common/components/Input";
import { ModalActions } from "../../_common/components/Modal/ModalActions";
import { Button } from "../../_common/components/Button";
import { Select } from "../../_common/components/Select";
import { useExhibitTypeOptions } from "../../_common/hooks/useExhibitTypeOptions";
import { TReferenceDocument } from "../../_common/api/filings";

interface ReferenceExhibitFormProps<
  T = CreateReferenceDocumentParams | UpdateReferenceDocumentParams,
> {
  initialValues: Partial<T>;
  onSubmit: (data: T) => void;
  onCancel: () => void;
}
export function ReferenceExhibitForm({
  initialValues,
  onSubmit,
  onCancel,
}: ReferenceExhibitFormProps) {
  const options = useExhibitTypeOptions();
  const { register, handleSubmit, setValue, formState, control } = useForm({
    defaultValues: initialValues,
    reValidateMode: "onBlur",
  });

  function cleanAndReplaceLinkUrlOnBlur(e: React.FocusEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    if (!checkIsValidTextLinkUrl(value)) {
      e.currentTarget.value = "";
      setValue("linked_url", "");
    } else {
      const cleaned = cleanSecUrlValue(value);
      e.currentTarget.value = cleaned;
      setValue("linked_url", cleaned);
    }
  }
  const valid = formState.isValid;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-[400px] max-h-[400px] flex flex-col gap-4">
        <Field className="flex flex-col gap-2">
          <Label>Link text*</Label>
          <Input
            autoComplete="off"
            data-form-type="other"
            {...register("link_text", { required: true })}
          />
        </Field>
        <Field className="flex flex-col gap-2">
          <Label>
            Link URL{" "}
            <span className="text-gray-400 font-normal">
              (must be a valid sec.gov hosted document)
            </span>
          </Label>
          <Input
            autoComplete="off"
            data-form-type="other"
            {...register("linked_url", {
              required: true,
              onBlur: cleanAndReplaceLinkUrlOnBlur,
              pattern: SEC_URL_REGEX,
            })}
          />
        </Field>
        <Fieldset className="flex gap-2">
          <Field className="flex flex-col gap-2">
            <Label>Exhibit type</Label>
            <Controller
              name="exhibit_type"
              control={control}
              render={({ field }) => (
                <Select {...field}>
                  {options.map(({ label, value }) => (
                    <option value={value} key={value}>
                      {label}
                    </option>
                  ))}
                </Select>
              )}
            />
          </Field>
          <Field className="flex flex-col gap-2">
            <Label>Suffix</Label>
            <Input
              autoComplete="off"
              data-form-type="other"
              {...register("exhibit_type_suffix", {
                maxLength: 255,
              })}
            />
          </Field>
        </Fieldset>
      </div>
      <ModalActions>
        <Button
          className="flex-1"
          variant="default"
          color="gray"
          type="button"
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button
          className="flex-1"
          variant="default"
          color="blue"
          type="submit"
          disabled={!valid}
        >
          Save
        </Button>
      </ModalActions>
    </form>
  );
}

const SEC_URL_REGEX =
  /^(?:https?:\/\/)?(?:www\.)?sec\.gov\/?(?:ix\?doc=)?\/Archives\/edgar\/data/i;
function checkIsValidTextLinkUrl(value: string) {
  return SEC_URL_REGEX.test(value);
}

function cleanSecUrlValue(value: string) {
  const cleaned = value.replace(SEC_URL_REGEX, "");

  return `https://www.sec.gov/Archives/edgar/data${cleaned}`;
}

export function initialValuesUpdate(
  data?: TReferenceDocument,
): UpdateReferenceDocumentParams {
  if (!data) {
    return {};
  }

  return {
    exhibit_type: data.exhibit_type,
    exhibit_type_suffix: data.exhibit_type_suffix,
    linked_url: data.linked_url,
    link_text: data.link_text,
    status: data.status,
  };
}
