import { useMemo } from "react";
import { useMultistageFlowContext } from "../../../_common/components/MultistageFlow/context";
import { WrappedData } from "../../types";
import { formatStat } from "../../utils/formatStat";
import { StatBlock } from "../WrappedFlow/StatBlock/StatBlock";
import { GradientText } from "../GradientText/GradientText";

export function Section16Reporting() {
  const {
    contextData: { total_insider_reports_filings },
  } = useMultistageFlowContext<WrappedData>();

  const hoursSpent = useMemo(() => {
    return Math.floor(total_insider_reports_filings * 0.5);
  }, [total_insider_reports_filings]);

  return (
    <div className="mx-auto flex h-full flex-col items-center justify-between pb-16 pt-6 text-center md:py-6">
      <div className="space-y-3">
        <h3 className="text-4xl uppercase md:text-5xl">
          You went{" "}
          <GradientText>
            old-school
          </GradientText>{" "}
          with insider reporting
        </h3>
      </div>

      <div className="space-y-6 max-w-min">
        <StatBlock size="md">
          <div className="text-center">
            <div className="leading-none">{formatStat(hoursSpent)}</div>
            <div className="text-lg uppercase">hours</div>
          </div>
        </StatBlock>

        <div className="text:2xl">
          Manually entering Section 16 transactions can be pretty time
          consuming!
        </div>
      </div>

      <div className="text-xl text-gray-500">
        (Did you spend even more time getting your holdings ledger right?)
      </div>
    </div>
  );
}
