import { ID } from "../types/id";
import { omit } from "./object";

interface Normalized<T> {
  ids: ID[];
  records: Record<ID, T>;
}

type IdGetter<T> = (record: T) => ID;

export function listToNormalized<T>(
  list: T[],
  getId: IdGetter<T>
): Normalized<T> {
  const records: Normalized<T>["records"] = {};
  const ids: Normalized<T>["ids"] = [];

  for (const record of list) {
    const id = getId(record);
    records[id] = record;
    ids.push(id);
  }

  return {
    records,
    ids,
  };
}

export function normalizedToList<T>(normalized: Normalized<T>): T[] {
  return normalized.ids.map((id) => normalized.records[id]);
}

export function removeFromNormalized<T>(
  normalized: Normalized<T>,
  delId: ID
): Normalized<T> {
  return {
    ids: normalized.ids.filter((id) => id !== delId),
    records: omit(normalized.records, delId),
  };
}

export function upsertToNormalized<T>(
  normalized: Normalized<T>,
  record: T,
  getID: IdGetter<T>
): Normalized<T> {
  const id = getID(record);
  const nextRecords = {
    ...normalized.records,
    [id]: record,
  };
  const nextIds = normalized.ids.includes(id)
    ? normalized.ids
    : [...normalized.ids, id];

  return {
    ids: nextIds,
    records: nextRecords,
  };
}
