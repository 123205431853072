import {
  ArrowsUpDownIcon,
  CheckBadgeIcon,
  ClockIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { formatDistanceToNow } from "date-fns/formatDistanceToNow";
import clsx from "clsx";

import { TFilingListFiling } from "../../../_common/api/filings";
import { Status101Enum } from "../../../../generated/types";
import { Button } from "../../../_common/components/Button";

interface FilingsListItemProps {
  filing: TFilingListFiling;
}
export function FilingsListItem({ filing }: FilingsListItemProps) {
  return (
    <div className="p-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <StatusIcon filing={filing} />
          <div className="text-sm font-semibold">{filing.entity.name}</div>
          <div className="text-xs">•</div>
          <div className="text-sm">{filing.filing_type}</div>
          <StatusBadge filing={filing} />
        </div>

        <Cta filing={filing} />
      </div>

      <div className="max-w-4xl whitespace-wrap text-xs text-gray-500">
        {filing.items_8k.length > 0 ? filing.items_8k.map((i) => i.body).join(", ") : "No Items Found"}
      </div>

      {/* <div className="text-xs text-gray-500">{filing.name}</div> */}
      <div className="mt-2 flex items-center gap-1">
        <ClockIcon className="size-4" />
        <div className="text-xs text-gray-500">
          {formatDistanceToNow(filing.last_action_created_at)} ago by{" "}
          {filing.last_action_committed_by_name}
        </div>
      </div>
    </div>
  );
}

function StatusIcon({ filing }: FilingsListItemProps) {
  if (filing.status === Status101Enum.draft)
    return <ArrowsUpDownIcon className="size-4 text-brandBlue" />;

  if (filing.status === Status101Enum.in_review)
    return <UserIcon className="size-4 text-brandOrange" />;

  if (filing.status === Status101Enum.filed)
    return <CheckBadgeIcon className="size-4 text-green-400" />;

  return <ClockIcon className="size-4 text-purple-400" />;
}

function StatusBadge({ filing }: FilingsListItemProps) {
  return (
    <div
      className={clsx("rounded-full px-2 py-1 text-xs font-semibold", {
        "bg-blue-300 text-blue-600": filing.status === Status101Enum.draft,
        "bg-yellow-300 text-yellow-600":
          filing.status === Status101Enum.in_review,
        "bg-green-300 text-green-600": filing.status === Status101Enum.filed,
        "bg-purple-300 text-purple-600":
          filing.status === Status101Enum.scheduled,
      })}
    >
      {statusCopies[filing.status]}
    </div>
  );
}
const statusCopies: Record<Status101Enum, string> = {
  in_review: "In Review",
  scheduled: "Scheduled",
  draft: "Draft",
  filed: "Filed",
  archived: "Archived",
};

function Cta({ filing }: FilingsListItemProps) {
  if (filing.status === Status101Enum.filed) {
    return (
      <Link to={filing.id.toString()}>
        <Button variant="outline" color="gray" className="text-xs">
          View Filing
        </Button>
      </Link>
    );
  }
  return (
    <Link to={filing.id.toString()}>
      <Button variant="outline" color="gray" className="text-xs">
        Continue
      </Button>
    </Link>
  );
}
