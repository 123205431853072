import { StubbedDerivativeTransaction } from "../../hooks/useStubbedData";
import { formatDate } from "../../utils/formatDate";

interface DerivativeTransactionsTableProps {
  transactions: StubbedDerivativeTransaction[];
}
export function DerivativeTransactionsTable({
  transactions,
}: DerivativeTransactionsTableProps) {
  return (
    <table style={{ width: "100%" }} border={1} cellSpacing={0} cellPadding={4}>
      <thead>
        <tr>
          <th
            style={{ width: "100%", verticalAlign: "top" }}
            colSpan={16}
            align="center"
            className="FormTextC"
          >
            <b>
              Table II - Derivative Securities Acquired, Disposed of, or
              Beneficially Owned
            </b>
            <br />
            <b>
              (e.g., puts, calls, warrants, options, convertible securities)
            </b>
          </th>
        </tr>
        <tr>
          <th
            style={{ width: "13%", verticalAlign: "top" }}
            rowSpan={2}
            align="left"
            className="SmallFormText"
          >
            1. Title of Derivative Security (Instr. 3)
          </th>
          <th
            style={{ width: "5%", verticalAlign: "top" }}
            rowSpan={2}
            align="left"
            className="SmallFormText"
          >
            2. Conversion or Exercise Price of Derivative Security
          </th>
          <th
            style={{ width: "5%", verticalAlign: "top" }}
            rowSpan={2}
            align="left"
            className="SmallFormText"
          >
            3. Transaction Date (Month/Day/Year)
          </th>
          <th
            style={{ width: "5%", verticalAlign: "top" }}
            rowSpan={2}
            align="left"
            className="SmallFormText"
          >
            3A. Deemed Execution Date, if any (Month/Day/Year)
          </th>
          <th
            style={{ width: "9%", verticalAlign: "top" }}
            colSpan={2}
            align="left"
            className="SmallFormText"
          >
            4. Transaction Code (Instr. 8)
          </th>
          <th
            style={{ width: "10%", verticalAlign: "top" }}
            colSpan={2}
            align="left"
            className="SmallFormText"
          >
            5. Number of Derivative Securities Acquired (A) or Disposed of (D)
            (Instr. 3, 4 and 5)
          </th>
          <th
            style={{ width: "9%", verticalAlign: "top" }}
            colSpan={2}
            align="left"
            className="SmallFormText"
          >
            6. Date Exercisable and Expiration Date (Month/Day/Year)
          </th>
          <th
            style={{ width: "17%", verticalAlign: "top" }}
            colSpan={2}
            align="left"
            className="SmallFormText"
          >
            7. Title and Amount of Securities Underlying Derivative Security
            (Instr. 3 and 4)
          </th>
          <th
            style={{ width: "6%", verticalAlign: "top" }}
            rowSpan={2}
            align="left"
            className="SmallFormText"
          >
            8. Price of Derivative Security (Instr. 5)
          </th>
          <th
            style={{ width: "6%", verticalAlign: "top" }}
            rowSpan={2}
            align="left"
            className="SmallFormText"
          >
            9. Number of derivative Securities Beneficially Owned Following
            Reported Transaction(s) (Instr. 4)
          </th>
          <th
            style={{ width: "6%", verticalAlign: "top" }}
            rowSpan={2}
            align="left"
            className="SmallFormText"
          >
            10. Ownership Form: Direct (D) or Indirect (I) (Instr. 4)
          </th>
          <th
            style={{ width: "7%", verticalAlign: "top" }}
            rowSpan={2}
            align="left"
            className="SmallFormText"
          >
            11. Nature of Indirect Beneficial Ownership (Instr. 4)
          </th>
        </tr>
        <tr>
          <th
            style={{ width: "4%", verticalAlign: "bottom" }}
            align="center"
            className="SmallFormText"
          >
            Code
          </th>
          <th
            style={{ width: "4%", verticalAlign: "bottom" }}
            align="center"
            className="SmallFormText"
          >
            V
          </th>
          <th
            style={{ width: "5%", verticalAlign: "bottom" }}
            align="center"
            className="SmallFormText"
          >
            (A)
          </th>
          <th
            style={{ width: "5%", verticalAlign: "bottom" }}
            align="center"
            className="SmallFormText"
          >
            (D)
          </th>
          <th
            style={{ width: "5%", verticalAlign: "bottom" }}
            align="center"
            className="SmallFormText"
          >
            Date Exercisable
          </th>
          <th
            style={{ width: "4%", verticalAlign: "bottom" }}
            align="center"
            className="SmallFormText"
          >
            Expiration Date
          </th>
          <th
            style={{ width: "10%", verticalAlign: "bottom" }}
            align="center"
            className="SmallFormText"
          >
            Title
          </th>
          <th
            style={{ width: "7%", verticalAlign: "bottom" }}
            align="center"
            className="SmallFormText"
          >
            Amount or Number of Shares
          </th>
        </tr>
        {transactions.map((transaction) => (
          <DerivativeTransactionRow
            transaction={transaction}
            key={transaction.id}
          />
        ))}
      </thead>
    </table>
  );
}

interface DerivativeTransactionRowProps {
  transaction: StubbedDerivativeTransaction;
}
function DerivativeTransactionRow({
  transaction,
}: DerivativeTransactionRowProps) {
  return (
    <tr>
      <td align="left">
        <span className="FormData">{transaction.security_title}</span>
      </td>
      <td align="center">{transaction.conversion_or_exercise_price}</td>
      <td align="center">
        <span className="FormData">
          {formatDate(transaction.transaction_date)}
        </span>
      </td>
      <td></td>
      <td align="center">
        <span className="SmallFormData">{transaction.transaction_code}</span>
      </td>
      <td align="center"></td>
      <td align="center">
        <span className="FormData">
          {transaction.number_of_derivative_securities_acquired}
        </span>
      </td>
      <td align="center">
        <span className="FormData">
          {transaction.number_of_derivative_securities_disposed}
        </span>
      </td>
      {/* TODO: need to fix these as they don't exist in data model ATM */}
      <td align="center">
        <span className="FormData">{transaction.date_exercisable}</span>
      </td>
      <td align="center">
        <span className="FormData">{transaction.expiration_date}</span>
      </td>
      <td align="center">
        <span className="FormData">
          {transaction.title_of_underlying_security}
        </span>
      </td>
      <td align="left">{transaction.amount_beneficially_owned}</td>
      <td align="left">$0.00</td>
      <td align="left"></td>
      <td align="left">
        {transaction.ownership_form} {/* Direct Ownerhship */}
      </td>
      <td align="left"></td>
    </tr>
  );
}
