import { useHotkeys } from "react-hotkeys-hook";
import { useMultistageFlowContext } from "../../_common/components/MultistageFlow/context";

export function KeyboardShortcuts() {
  const { next, previous, hasNext, hasPrevious } = useMultistageFlowContext();

  useHotkeys("right", () => hasNext && next());
  useHotkeys("left", () => hasPrevious && previous());

  return <></>;
}
