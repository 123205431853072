import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import topLeftFlower from "./WrappedFlow/topLeftFlower.png";
import bottomRightFlower from "./WrappedFlow/bottomRightFlower.png";
import brand from "./WrappedFlow/brand.png";
import mobileTopFlowers from "./WrappedFlow/mobileTopFlowers.png";

import { WrappedData } from "../types";
import { MultistageFlowContent } from "../../_common/components/MultistageFlow/MultistageFlow";
import { CountsBreakdown } from "./steps/CountsBreakdown";
import { BusiestMonth } from "./steps/BusiestMonth";
import { VendorsBreakdown } from "./steps/VendorsBreakdown";
import { Section16Reporting } from "./steps/Section16Reportings";
import { Introducing } from "./steps/IntroducingFyler";
import { Snarky } from "./steps/Snarky";
import { MultistageFlowContextProvider } from "../../_common/components/MultistageFlow/context";
import { Stepper } from "./WrappedFlow/Stepper";
import { NextButton } from "./WrappedFlow/NextButton";
import { TickerLogo } from "./WrappedFlow/TickerLogo";
import { getQueryKey } from "../queries/wrappedData";
import { Spinner } from "../../_common/components/Spinner";
import { KeyboardShortcuts } from "./KeyboardShortcuts";
import { WrappedStepTracking } from "./WrappedFlow/WrappedStepTracking";

export function WrappedFlow() {
  const { ticker = "", year = "" } = useParams<{
    ticker: string;
    year: string;
  }>();
  const { data, isLoading } = useQuery<WrappedData>({
    queryKey: getQueryKey(year, ticker),
  });

  if (isLoading && !data)
    return (
      <div className="flex h-dvh w-screen items-center justify-center bg-[#EEEFE7]">
        <Spinner className="size-10" color="gray" />
      </div>
    );

  if (!data) return <div>No wrapped data found!</div>;

  return (
    <div className="relative h-dvh w-screen bg-[#EEEFE7] font-instagram">
      <img
        className="absolute left-0 top-0 hidden md:block"
        src={topLeftFlower}
        alt="green flower"
      />
      <img
        className="absolute bottom-0 right-0 hidden md:block"
        src={bottomRightFlower}
        alt="purple flower"
      />
      <img
        className="absolute left-0 top-0 w-full md:hidden"
        src={mobileTopFlowers}
        alt="green and purple flowers"
      />

      <a href="https://fyler.us" target="_blank">
        <img
          className="absolute right-1/2 top-10 h-6 translate-x-1/2 md:right-10 md:h-10 md:translate-x-0"
          src={brand}
          alt="fyler brand"
        />
      </a>

      <MultistageFlowContextProvider
        initialContextData={data}
        stages={[
          {
            id: "01",
            component: CountsBreakdown,
          },
          {
            id: "02",
            component: BusiestMonth,
          },
          {
            id: "03",
            component: VendorsBreakdown,
          },
          { id: "04", component: Section16Reporting },
          { id: "05", component: Snarky },

          {
            id: "🔮",
            component: Introducing,
          },
        ]}
        onComplete={() => null}
      >
        <div className="flex h-full flex-col-reverse items-center justify-between px-6 pb-4 pt-20 md:flex-row">
          <Stepper />
          <MultistageFlowContent />
          <WrappedStepTracking />

          <NextButton />
        </div>

        <TickerLogo />
        <KeyboardShortcuts />
      </MultistageFlowContextProvider>
    </div>
  );
}
