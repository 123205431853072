import { Table } from "../../../_common/components/Table/Table";
import { buildLookup } from "../../../_common/utils/list";
import { isDerivative, useStubbedData } from "../../hooks/useStubbedData";

export function TransactionHistoryPanel() {
  const { transactionHistory, reportingOwners } = useStubbedData();

  const reportingOwnerLookup = buildLookup(reportingOwners, (r) => r.id);

  return (
    <div className="p-4">
      <div className="space-y-4">
        <h3 className="font-semibold">Security Types</h3>

        <Table
          data={transactionHistory}
          actions={[]}
          rowKey="id"
          emptyState="No transactions"
          headers={[
            {
              label: "Owner",
              getStringContent: (tra) =>
                reportingOwnerLookup[tra.reportingOwnerId].reporting_owner_name,
              hasTooltip: false,
            },
            {
              label: "Number of shares",
              getStringContent: (tra) =>
                isDerivative(tra)
                  ? tra.number_of_derivative_securities_acquired ||
                    tra.number_of_derivative_securities_disposed
                  : tra.transaction_amount,
              hasTooltip: false,
            },
            {
              label: "Security",
              getStringContent: (tra) => tra.security_title,
              hasTooltip: false,
            },
            {
              label: "Execution Date",
              getStringContent: (tra) => tra.transaction_date,
              hasTooltip: false,
            },
          ]}
        />
      </div>
    </div>
  );
}
