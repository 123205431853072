import clsx, { ClassValue } from "clsx";
import { TFiling } from "../../_common/api/filings";

interface StatusBadgeProps {
  filing: TFiling;
}

const statusCssLookup: Record<TFiling["status"], ClassValue> = {
  draft: "bg-blue-300 text-blue-600",
  archived: "bg-red-300 text-red-600",
  scheduled: "bg-purple-300 text-purple-600",
  in_review: "bg-yellow-300 text-yello-600",
  filed: "bg-green-300 text-green-600",
};
export function StatusBadge({ filing }: StatusBadgeProps) {
  return (
    <div
      className={clsx(
      "px-3 py-1 font-semibold rounded uppercase",
      statusCssLookup[filing.status]
      )}
    >
      {filing.status.replace(/_/g, " ")}
    </div>
  );
}
