import { useCallback, useState } from "react";
import clsx from "clsx";

import { ButtonLink } from "../../../_common/components/ButtonLink";
import { FileInput } from "../../../_common/components/FileInput";
import { fidelityExtract } from "../../../_common/api/fidelity_extract";
import { useStubbedData } from "../../hooks/useStubbedData";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../../_common/components/Spinner";

export function FidelityExtractUploadButton() {
  const [loading, setLoading] = useState(false);
  const addDerivativeTransactions = useStubbedData(
    (s) => s.addDerivativeTransactions
  );
  const addNonDerivativeTransactions = useStubbedData(
    (s) => s.addNonDerivativeTransactions
  );
  const addReportingOwner = useStubbedData((s) => s.addReportingOwner);
  const reportingOwners = useStubbedData((s) => s.reportingOwners);
  const addFiling = useStubbedData((s) => s.addFiling);
  const upsertSecurityType = useStubbedData((s) => s.upsertSecurityType);
  const securityTypes = useStubbedData((s) => s.securityTypes);

  const navigate = useNavigate();
  const handleFiles = useCallback(
    async (files: File[]) => {
      const [uploaded_file] = files;
      if (!uploaded_file) return;

      try {
        setLoading(true);
        const response = await fidelityExtract(uploaded_file);

        const reportingOwner = response.reporting_owner;
        const reportingOwnerName = reportingOwner.reporting_owner_name;
        const existingReportingOwner = reportingOwners.find(
          (f) => f.reporting_owner_name === reportingOwnerName
        );

        let existingReportingOwnerId = existingReportingOwner?.id;
        if (!existingReportingOwnerId) {
          existingReportingOwnerId = addReportingOwner(reportingOwner);
        }

        const [
          derivativeSecurityTitleToIdMap,
          nonDerivativeSecurityTitleToIdMap,
        ] = securityTypes.reduce<
          [Record<string, number>, Record<string, number>]
        >(
          (acc, curr) => {
            if (curr.derivesFromId) {
              acc[0][curr.title] = curr.id;
            } else {
              acc[1][curr.title] = curr.id;
            }
            return acc;
          },
          [{}, {}]
        );

        const newNonDerivativeTransactionPayloads =
          response.non_derivative_transactions.map((i) => {
            const title = i.security_title;
            if (!nonDerivativeSecurityTitleToIdMap[title]) {
              nonDerivativeSecurityTitleToIdMap[title] =
                upsertSecurityType(title);
            }

            return {
              ...i,
              reportingOwnerId: existingReportingOwnerId,
            };
          });

        const newDerivativeTransactionPayloads =
          response.derivative_transactions.map((i) => {
            const title = i.security_title;
            const underlyingTitle = i.title_of_underlying_security;
            if (!nonDerivativeSecurityTitleToIdMap[underlyingTitle]) {
              nonDerivativeSecurityTitleToIdMap[underlyingTitle] =
                upsertSecurityType(underlyingTitle);
            }
            if (!derivativeSecurityTitleToIdMap[title]) {
              derivativeSecurityTitleToIdMap[title] = upsertSecurityType(
                title,
                nonDerivativeSecurityTitleToIdMap[underlyingTitle]
              );
            }

            return {
              ...i,
              reportingOwnerId: existingReportingOwnerId,
            };
          });

        const createdDerivativeTransactionIds = addDerivativeTransactions(
          newDerivativeTransactionPayloads
        );
        const createdNonDerivativeTransactionIds = addNonDerivativeTransactions(
          newNonDerivativeTransactionPayloads
        );

        const filingId = addFiling(
          existingReportingOwnerId,
          createdDerivativeTransactionIds,
          createdNonDerivativeTransactionIds
        );

        setLoading(false);
        navigate(filingId + "/preview");
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [
      addDerivativeTransactions,
      addFiling,
      addNonDerivativeTransactions,
      addReportingOwner,
      navigate,
      reportingOwners,
      securityTypes,
      upsertSecurityType,
    ]
  );

  return (
    <FileInput
      className={clsx(loading && "opacity-70")}
      dropzoneProps={{
        onDrop: handleFiles,
        disabled: loading,
        multiple: false,
        accept: {
          "application/pdf": [".pdf"],
        },
      }}
    >
      <ButtonLink>
        {loading && <Spinner color="brand" className="size-4" />}
        <span>Import Fidelity Form 4 Transactions</span>
      </ButtonLink>
    </FileInput>
  );
}
