import { CommonIconProps } from "./_common";

interface CircleXIconProps extends CommonIconProps {}

export function CircleXIcon({ ...props }: CircleXIconProps) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.25 11C19.25 15.5563 15.5563 19.25 11 19.25C6.44365 19.25 2.75 15.5563 2.75 11C2.75 6.44365 6.44365 2.75 11 2.75C15.5563 2.75 19.25 6.44365 19.25 11ZM6.62623 15.3738C6.2357 14.9832 6.2357 14.3501 6.62623 13.9596L9.58579 11L6.62623 8.04044C6.2357 7.64992 6.2357 7.01675 6.62623 6.62623C7.01675 6.2357 7.64992 6.2357 8.04044 6.62623L11 9.58579L13.9596 6.62623C14.3501 6.2357 14.9832 6.2357 15.3738 6.62623C15.7643 7.01675 15.7643 7.64992 15.3738 8.04044L12.4142 11L15.3738 13.9596C15.7643 14.3501 15.7643 14.9832 15.3738 15.3738C14.9832 15.7643 14.3501 15.7643 13.9596 15.3738L11 12.4142L8.04044 15.3738C7.64992 15.7643 7.01675 15.7643 6.62623 15.3738Z"
        fill="currentColor"
        fill-opacity="0.6"
      />
    </svg>
  );
}
