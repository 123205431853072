import clsx from "clsx";
import { PropsWithChildren } from "react";

interface HeadingProps {
  size: "sm" | "lg";
}
export function Heading({ size, children }: PropsWithChildren<HeadingProps>) {
  return (
    <h3
      className={clsx("font-semibold uppercase", {
        "text-2xl md:text-3xl lg:text-4xl": size === "sm",
        "text-3xl md:text-3xl lg:text-5xl": size === "lg",
      })}
    >
      {children}
    </h3>
  );
}
