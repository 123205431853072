import { TDocument, TReferenceDocument } from "../../../_common/api/filings";
import {
  isReferenceDocument,
  updateDocument,
  updateReferenceDocument,
} from "../../../_common/api/document";
import {
  useDispatchErrorToast,
  useDispatchInfoToast,
} from "../../../_common/components/Toasts/context";
import { EditableText } from "../../../_common/components/EditableText";
import { isAxiosError } from "axios";

interface EditableTextCellProps {
  document: TDocument | TReferenceDocument;
  keyName:
    | "exhibit_link_text"
    | "exhibit_edgar_description"
    | "link_text"
    | "exhibit_type_suffix";
}
export function EditableTextCell({ document, keyName }: EditableTextCellProps) {
  const dispatchErrorToast = useDispatchErrorToast();
  const dispatchInfoToast = useDispatchInfoToast();
  const handleBlur = async (v: string) => {
    const value = v.trim();
    if (value.length > 255) {
      dispatchErrorToast(
        "Cannot use a value longer than 255 characters for this field"
      );
      return;
    }

    try {
      if (isReferenceDocument(document)) {
        await updateReferenceDocument(document.id, { [keyName]: value || "" });
      } else {
        await updateDocument(document.id, { [keyName]: value || "" });
      }

      dispatchInfoToast("Document updated successfully", 3000);
    } catch (error) {
      if (isAxiosError(error)) {
        dispatchErrorToast(error.message, 3000);
      } else {
        dispatchErrorToast(
          "Failed to update document. Please try again later.",
          3000
        );
      }
    }
  };

  function validator(v: string) {
    if (!v && keyName !== "exhibit_type_suffix") return false;
    if (v.length > 255 && keyName === "exhibit_edgar_description") return false;

    if (keyName === "exhibit_type_suffix" && parseInt(v) <= 0) {
      return false;
    }

    return true;
  }

  return (
    <EditableText
      valueHandlerOnBlur={handleBlur}
      content={document[keyName as keyof typeof document] || ""}
      validator={validator}
    />
  );
}
