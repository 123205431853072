import { HtmlHTMLAttributes } from "react";
import clsx from "clsx";

import { TDocument } from "../../_common/api/filings";
import { DocumentTypeEnum } from "../../../generated/types";

interface DocumentTypeBadgeProps extends HtmlHTMLAttributes<HTMLDivElement> {
  document: TDocument;
}

const labelMapping: Record<DocumentTypeEnum, string> = {
  [DocumentTypeEnum.exhibit]: "Exhibit",
  [DocumentTypeEnum.primary_document]: "Primary Document",
  [DocumentTypeEnum.submission_file]: "Submission File",
  [DocumentTypeEnum.graphic]: "Graphic",
};
export function DocumentTypeBadge({
  document,
  className,
  ...props
}: DocumentTypeBadgeProps) {
  return (
    <div
      className={clsx(
        "text-gray-500 rounded uppercase font-sm font-medium px-2 border border-gray-400 whitespace-nowrap",
        className
      )}
      {...props}
    >
      {document.document_type
        ? labelMapping[document.document_type]
        : "Unknown"}
    </div>
  );
}
