import { StubbedNonDerivativeTransaction } from "../../hooks/useStubbedData";
import { formatDate } from "../../utils/formatDate";

interface NonDerivativeTransactionsTableProps {
  transactions: StubbedNonDerivativeTransaction[];
}
export function NonDerivativeTransactionsTable({
  transactions,
}: NonDerivativeTransactionsTableProps) {
  return (
    <table style={{ width: "100%" }} border={1} cellSpacing={0} cellPadding={4}>
      <thead>
        <tr>
          <th
            style={{ width: "100%", verticalAlign: "top" }}
            colSpan={11}
            align="center"
            className="FormTextC"
          >
            <b>
              Table I - Non-Derivative Securities Acquired, Disposed of, or
              Beneficially Owned
            </b>
          </th>
        </tr>
        <tr>
          <th
            style={{ width: "36%", verticalAlign: "top" }}
            rowSpan={2}
            align="left"
            className="MedSmallFormText"
          >
            1. Title of Security (Instr. 3)
          </th>
          <th
            style={{ width: "6%", verticalAlign: "top" }}
            rowSpan={2}
            align="left"
            className="SmallFormText"
          >
            2. Transaction Date (Month/Day/Year)
          </th>
          <th
            style={{ width: "5%", verticalAlign: "top" }}
            rowSpan={2}
            align="left"
            className="SmallFormText"
          >
            2A. Deemed Execution Date, if any (Month/Day/Year)
          </th>
          <th
            style={{ width: "7%", verticalAlign: "top" }}
            colSpan={2}
            align="left"
            className="SmallFormText"
          >
            3. Transaction Code (Instr. 8)
          </th>
          <th
            style={{ width: "19%", verticalAlign: "top" }}
            colSpan={3}
            align="left"
            className="SmallFormText"
          >
            4. Securities Acquired (A) or Disposed Of (D) (Instr. 3, 4 and 5)
          </th>
          <th
            style={{ width: "11%", verticalAlign: "top" }}
            rowSpan={2}
            align="left"
            className="SmallFormText"
          >
            5. Amount of Securities Beneficially Owned Following Reported
            Transaction(s) (Instr. 3 and 4)
          </th>
          <th
            style={{ width: "9%", verticalAlign: "top" }}
            rowSpan={2}
            align="left"
            className="SmallFormText"
          >
            6. Ownership Form: Direct (D) or Indirect (I) (Instr. 4)
          </th>
          <th
            style={{ width: "8%", verticalAlign: "top" }}
            rowSpan={2}
            align="left"
            className="SmallFormText"
          >
            7. Nature of Indirect Beneficial Ownership (Instr. 4)
          </th>
        </tr>
        <tr>
          <th style={{ width: "4%" }} align="center" className="SmallFormText">
            Code
          </th>
          <th style={{ width: "3%" }} align="center" className="SmallFormText">
            V
          </th>
          <th style={{ width: "8%" }} align="center" className="SmallFormText">
            Amount
          </th>
          <th style={{ width: "5%" }} align="center" className="SmallFormText">
            (A) or (D)
          </th>
          <th style={{ width: "6%" }} align="center" className="SmallFormText">
            Price
          </th>
        </tr>
      </thead>
      <tbody>
        {transactions.map((transaction) => (
          <NonDerivativeTransactionRow
            transaction={transaction}
            key={transaction.id}
          />
        ))}
      </tbody>
    </table>
  );
}

interface NonDerivativeTransactionRowProps {
  transaction: StubbedNonDerivativeTransaction;
}
function NonDerivativeTransactionRow({
  transaction,
}: NonDerivativeTransactionRowProps) {
  return (
    <tr>
      <td align="left">
        <span className="FormData">{transaction.security_title}</span>
      </td>
      <td align="center">
        <span className="FormData">
          {formatDate(transaction.transaction_date)}
        </span>
      </td>
      <td align="center"></td>
      <td align="center">
        <span className="SmallFormData">{transaction.transaction_code}</span>
      </td>
      <td align="center"></td>
      <td align="center">
        <span className="FormData">{transaction.transaction_amount}</span>
      </td>
      <td align="center">
        <span className="FormData">{transaction.acquired_disposed}</span>
      </td>
      <td align="center">
        <span className="FormData">{transaction.price}</span>
      </td>
      <td align="center">
        <span className="FormData">
          {/* Beneficially Owned Following Transaction */}
          {transaction.amount_owned}
        </span>
      </td>
      <td align="center">
        <span className="FormData">D</span>
      </td>
      <td align="left"></td>
    </tr>
  );
}
