import { GradientText } from "../GradientText/GradientText";

export function Introducing() {
  return (
    <div className="mx-auto flex h-full flex-col items-center justify-between pb-16 pt-6 text-center md:py-6">
      <div>
        <h3 className="text-4xl uppercase md:text-5xl">
          Introducing <GradientText>Fyler ai</GradientText>
        </h3>
        <h4 className="text-xl uppercase md:text-3xl">
          Your modern AI Powered SEC Filing Software
        </h4>
      </div>

      <div className="space-y-2">
        <iframe
          src="https://www.loom.com/embed/745a6b7f113249b2997c93e6d3535f90?sid=3ebe6f36-6948-4003-a119-0e6858a430a3"
          className="h-[200px] w-full rounded md:h-[400px]"
        />
        <div className="flex justify-between items-center gap-2 md:text-base text-xs">
          <div>Automatic Tagging & Typesetting</div>
          <span>•</span>
          <div>Co-pilot for Section 16 Insider Reports</div>
          <span>•</span>
          <div>Seamless compliance & submission</div>
        </div>
      </div>

      <a target="_blank" href="https://cal.com/team/fyler/demo">
        <button className="group rounded bg-black from-brandOrange to-brandBlue p-4 transition-all duration-700 hover:bg-gradient-to-br">
          <div className="bg-gradient-to-r from-brandOrange to-brandBlue bg-clip-text text-transparent transition-all group-hover:text-black">
            Sign up for demo{" "}
            <span className="inline-block transition-all group-hover:translate-x-1">
              →
            </span>
          </div>
        </button>
      </a>
    </div>
  );
}
