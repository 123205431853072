import { useState } from "react";
import { PencilIcon, XMarkIcon } from "@heroicons/react/16/solid";

import { TEntity, updateEntity } from "../../_common/api/entity";
import { PlusIcon } from "../../_common/components/icons/PlusIcon";
import { Modal } from "../../_common/components/Modal/Modal";
import {
  useDispatchErrorToast,
  useDispatchInfoToast,
} from "../../_common/components/Toasts/context";
import { FileInput } from "../../_common/components/FileInput";

interface EntityLogoCellProps {
  entity: TEntity;
}

export function EntityLogoCell({ entity }: EntityLogoCellProps) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const dispatchInfoToast = useDispatchInfoToast();
  const dispatchErrorToast = useDispatchErrorToast();
  async function handleFileChange(files: File[]) {
    const [file] = files;

    if (!file) return;

    try {
      await updateEntity(entity.id, { logo: file });
      dispatchInfoToast("Logo updated", 3000);
    } catch (err) {
      dispatchErrorToast("Failed to update logo", 3000);
    }
  }

  async function handleRemoveLogo() {
    await updateEntity(entity.id, { logo: null });
    setEditModalOpen(false);
  }

  if (!entity.logo) {
    return (
      <FileInput
        dropzoneProps={{
          onDrop: handleFileChange,
          multiple: false,
          accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
          },
        }}
      >
        <span className="text-xs hover:underline text-brandBlue flex gap-1 items-center cursor-pointer">
          <PlusIcon className="size-3" />
          <span>Add</span>
        </span>
      </FileInput>
    );
  }
  return (
    <>
      <img
        src={entity.logo}
        className="object-cover size-10 rounded-full cursor-pointer"
        onClick={() => setEditModalOpen(true)}
      />
      <Modal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        title="Edit logo"
      >
        <div className="flex flex-col gap-2 w-80">
          <img src={entity.logo} className="w-full object-contain" />
          <div className="w-full flex items-center justify-end gap-2  pt-2">
            <button
              className="text-xs hover:underline text-red-500 flex gap-1 items-center cursor-pointer"
              onClick={handleRemoveLogo}
            >
              <XMarkIcon className="size-3" />
              <span>Remove</span>
            </button>

            <FileInput
              dropzoneProps={{
                onDrop: handleFileChange,
                multiple: false,
                accept: {
                  "image/png": [".png"],
                  "image/jpeg": [".jpg", ".jpeg"],
                },
              }}
            >
              <span className="text-xs hover:underline text-brandBlue flex gap-1 items-center cursor-pointer">
                <PencilIcon className="size-3" />
                <span>Change</span>
              </span>
            </FileInput>
          </div>
        </div>
      </Modal>
    </>
  );
}
