import { DocumentTypeEnum, Status6b5Enum } from "../../../generated/types";
import { ID } from "../types/id";
import {
  upsertToNormalized,
  listToNormalized,
  removeFromNormalized,
  normalizedToList,
} from "../utils/normalized";
import { axios, queryClient } from "./client";
import {
  filingQueryKey,
  TDocument,
  TFiling,
  TReferenceDocument,
} from "./filings";

interface CreateDocumentParams {
  uploaded_file: File;
  filing_id: number;
  document_type: DocumentTypeEnum;
  status: Status6b5Enum;
}
export async function createDocument(params: CreateDocumentParams) {
  const fd = new FormData();
  fd.append("uploaded_file", params.uploaded_file);
  fd.append("filing_id", params.filing_id.toString());
  fd.append("document_type", params.document_type);
  fd.append("status", params.status);

  const { data } = await axios.post<TDocument>("documents/", fd);

  _updateDocumentInCache(data);

  return data;
}

export function getEstimatedDocumentProcessingTime(file: File) {
  let time = 3000;

  if (file.name.endsWith(".pdf")) {
    time += 5000;
  } else if (file.name.endsWith(".docx")) {
    time += 3000;
  } else {
    time += 2000;
  }

  return time;
}

export interface UpdateDocumentParams {
  exhibit_type?: string;
  exhibit_edgar_description?: string;
  exhibit_link_text?: string;
  exhibit_type_suffix?: string;
  status?: Status6b5Enum;
  uploaded_file?: File;
  parsed_html?: string;
}
export async function updateDocument(id: ID, params: UpdateDocumentParams) {
  const payload = new FormData();
  Object.entries(params).forEach(([key, value]) => {
    payload.append(key, value);
  });

  const { data } = await axios.patch<TDocument>(`documents/${id}/`, payload);

  _updateDocumentInCache(data);

  return data;
}

export async function removeExhibitHeader(id: ID) {
  const { data } = await axios.post<TDocument>(
    `documents/${id}/remove_exhibit_header/`,
  );

  _updateDocumentInCache(data);

  return data;
}

function _updateDocumentInCache(data: TDocument) {
  const existingFilingsData = queryClient.getQueryData<TFiling>([
    filingQueryKey(data.filing_id),
  ]);

  if (!existingFilingsData) {
    return;
  }
  let normDocuments = listToNormalized(
    existingFilingsData.documents,
    (d) => d.id,
  );

  if (data.status === Status6b5Enum.archived) {
    normDocuments = removeFromNormalized(normDocuments, data.id);
  } else {
    normDocuments = upsertToNormalized(normDocuments, data, (d) => d.id);
  }

  const updatedFilingData = {
    ...existingFilingsData,
    documents: normalizedToList(normDocuments),
  };

  queryClient.setQueryData([filingQueryKey(data.filing_id)], updatedFilingData);
}

export interface UpdateReferenceDocumentParams {
  status?: Status6b5Enum;
  exhibit_type?: string;
  exhibit_type_suffix?: string;
  linked_url?: string;
  link_text?: string;
}
export async function updateReferenceDocument(
  id: ID,
  params: UpdateReferenceDocumentParams,
) {
  const { data } = await axios.patch<TReferenceDocument>(
    `reference_documents/${id}/`,
    params,
  );

  _updateReferenceDocumentInCache(data);

  return data;
}

function _updateReferenceDocumentInCache(data: TReferenceDocument) {
  const existingFilingsData = queryClient.getQueryData<TFiling>([
    filingQueryKey(data.filing),
  ]);

  if (!existingFilingsData) {
    return;
  }

  let normReferences = listToNormalized(
    existingFilingsData.reference_documents,
    (d) => d.id,
  );

  if (data.status === Status6b5Enum.archived) {
    normReferences = removeFromNormalized(normReferences, data.id);
  } else {
    normReferences = upsertToNormalized(normReferences, data, (d) => d.id);
  }

  const updatedFilingData = {
    ...existingFilingsData,
    reference_documents: normalizedToList(normReferences),
  };

  queryClient.setQueryData([filingQueryKey(data.filing)], updatedFilingData);
}

export interface CreateReferenceDocumentParams {
  exhibit_type: string;
  exhibit_type_suffix?: string;
  linked_url: string;
  link_text: string;
  status: Status6b5Enum;
  filing: ID;
}
export async function createReferenceDocument(
  params: CreateReferenceDocumentParams,
) {
  const { data } = await axios.post<TReferenceDocument>(
    `reference_documents/`,
    params,
  );

  _updateReferenceDocumentInCache(data);

  return data;
}

export function isReferenceDocument(doc: TDocument | TReferenceDocument) {
  return "linked_url" in doc;
}
