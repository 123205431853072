import { useMultistageFlowContext } from "../../../_common/components/MultistageFlow/context";

export function NextButton() {
  const { hasNext, next } = useMultistageFlowContext();

  if (!hasNext) return <div />;

  return (
    <button
      className="hidden size-10 items-center justify-center rounded-full bg-[#AFB1FF] hover:opacity-90 md:flex"
      onClick={next}
    >
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="size-6"
      >
        <path
          d="M12.164 15.344V13.168C12.164 12.144 12.3987 11.2693 12.868 10.544C13.3587 9.79733 14.052 9.296 14.948 9.04H0.1V6.48H14.948C14.052 6.224 13.3587 5.73333 12.868 5.008C12.3987 4.26133 12.164 3.376 12.164 2.352V0.175999H14.916V2.064C14.916 3.38667 15.268 4.464 15.972 5.296C16.676 6.128 17.6573 6.544 18.916 6.544H19.3V8.976H18.916C17.6573 8.976 16.676 9.392 15.972 10.224C15.268 11.056 14.916 12.1333 14.916 13.456V15.344H12.164Z"
          fill="#EEEFE7"
        />
      </svg>
    </button>
  );
}
