import { useParams } from "react-router-dom";
import { useMultistageFlowContext } from "../../../_common/components/MultistageFlow/context";
import { useState } from "react";

export function TickerLogo() {
  const { ticker } = useParams<{ ticker: string }>();
  const { currentStage } = useMultistageFlowContext();
  const [emptyLogo, setEmptyLogo] = useState(false);

  if (currentStage.id === "01") return null;
  if (emptyLogo) return null;

  return (
    <img
      onError={() => setEmptyLogo(true)}
      className="absolute bottom-6 left-6 h-10 md:h-20"
      src={`https://img.logo.dev/ticker/${ticker}?token=pk_Q-nim_IsRpWqIXx9w-WAxA`}
      alt={ticker}
    />
  );
}
