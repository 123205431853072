// import { components } from "../../../generated/types";
// import { ID } from "../types/id";
import { components } from "../../../generated/types";
import { axios } from "./client";

export type TFiledExhibit = components["schemas"]["FiledExhibitList"]; // Adjust this type based on your actual schema
export type TPaginatedFiledExhibitList = components["schemas"]["PaginatedFiledExhibitListList"]; // Adjust this type based on your actual schema


export async function getFiledExhibits(search: string = ""): Promise<TPaginatedFiledExhibitList> {
  const { data } = await axios.get<TPaginatedFiledExhibitList>(`/filed_exhibits/`, {
    params: { search },
  });
  return data;
}
// export async function getFiledExhibit(id: ID) {
//   return queryClient.fetchQuery<TFiledExhibit>({
//     queryKey: [`filedExhibits/${id}`],
//     queryFn: async () => {
//       const { data } = await axios.get(`/filed_exhibits/${id}/`);
//       return data;
//     },
//   });
// }
