import { Dialog, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import {
  HtmlHTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useMainContentRect } from "../hooks/useMainPanelContentRect";

interface AnimatedGrowthCardProps
  extends HtmlHTMLAttributes<HTMLButtonElement> {
  modalContent: ReactNode;
}
export function AnimatedGrowthCard({
  children,
  modalContent,
  className,
  ...rest
}: PropsWithChildren<AnimatedGrowthCardProps>) {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [buttonCenterPosition, setButtonCenterPosition] = useState({
    left: 0,
    top: 0,
  });

  useLayoutEffect(() => {
    if (!buttonRef.current) return;
    const { top, left, width, height } =
      buttonRef.current.getBoundingClientRect();

    const center = {
      top: top + height / 2,
      left: left + width / 2,
    };

    setButtonCenterPosition(center);
  }, [open]);

  const mainContentRect = useMainContentRect();

  return (
    <>
      <button
        {...rest}
        className={clsx(className, "inline-flex items-start justify-start")}
        type="button"
        ref={buttonRef}
        onClick={() => setOpen(true)}
      >
        {children}
      </button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        transition
        style={{
          left: open
            ? mainContentRect.left + "px"
            : buttonCenterPosition.left + "px",
          top: open
            ? mainContentRect.top + "px"
            : buttonCenterPosition.top + "px",
        }}
        className="transition-[left_2s,top_2s,transform_1s] fixed ease-in data-[closed]:scale-0"
      >
        <DialogPanel
          style={{
            width: mainContentRect.width,
            height: mainContentRect.height,
          }}
          className="bg-white w-screen h-screen"
        >
          <div className="p-2 text-right">
            <button
              onClick={() => setOpen(false)}
              className="hover:text-gray-600 text-gray-500"
            >
              <XMarkIcon className="size-6" />
            </button>
          </div>
          <div className="data-[closed]:opacity-0 transition">
            {modalContent}
          </div>
        </DialogPanel>
      </Dialog>
    </>
  );
}
