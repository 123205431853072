import clsx from "clsx";
import { ReactNode } from "react";

export interface BaseToastProps {
  children: ReactNode;
  className?: string;
}

export function BaseToast({ children, className }: BaseToastProps) {
  return (
    <div
      className={clsx("bg-white shadow-lg p-3 max-w-md rounded-lg", className)}
    >
      {children}
    </div>
  );
}
