import clsx from "clsx";
import { useState } from "react";

import { PlusIcon } from "./icons/PlusIcon";
import { DocumentTypeEnum, Status6b5Enum } from "../../../generated/types";
import { createDocument } from "../api/document";
import { Spinner } from "./Spinner";
import { isAxiosError } from "axios";
import { useDispatchErrorToast } from "./Toasts/context";
import { SubmittingModal } from "./AddFilingModal/SubmittingModal";
import { FileInput } from "./FileInput";

interface UploadDocumentCtaProps {
  text: string;
  filingId: number;
  documentType: DocumentTypeEnum;
}
export function UploadDocumentCta({
  text,
  filingId,
  documentType,
}: UploadDocumentCtaProps) {
  const [loading, setLoading] = useState(false);
  const dispatchErrorToast = useDispatchErrorToast();
  async function handleChange(files: File[]) {
    const file = files[0];

    if (!file) return;

    setLoading(true);

    try {
      // upload the file
      await createDocument({
        uploaded_file: file,
        filing_id: filingId,
        document_type: documentType,
        status: Status6b5Enum.active,
      });
    } catch (err) {
      if (isAxiosError(err)) {
        dispatchErrorToast(err.message);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <FileInput
        dropzoneProps={{
          onDrop: handleChange,
          accept: {
            "application/pdf": [".pdf"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              [".docx"],
          },
        }}
      >
        <span
          className={clsx(
            "text-brandBlue hover:underline flex items-center gap-2 cursor-pointer text-sm whitespace-nowrap",
            { "opacity-50 cursor-not-allowed": loading }
          )}
        >
          {loading ? (
            <Spinner color="brand" className="size-3 flex-none" />
          ) : (
            <PlusIcon className="size-3 flex-none" />
          )}
          <span>{text}</span>
        </span>
      </FileInput>

      <SubmittingModal
        open={loading}
        processingTime={4000}
        completedMessage="Document ingested"
      />
    </>
  );
}
