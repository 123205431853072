import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { useEffect, useRef, useState } from "react";

import { Spinner } from "../Spinner";
import { LoadingBar } from "../LoadingBar";
import { FilingCompleteGraphic } from "./FilingCompleteGraphic";

interface SubmittingModalProps {
  open: boolean;
  processingTime: number;
  completedMessage: string;
}
export function SubmittingModal({
  open,
  processingTime,
  completedMessage,
}: SubmittingModalProps) {
  const stage = useSubmittingStages(open, processingTime);

  return (
    <Dialog
      open={open}
      onClose={() => null}
      transition
      className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0"
    >
      <DialogBackdrop className="fixed inset-0 bg-black/30" />

      <DialogPanel className="space-y-5 bg-white p-8 z-50 rounded">
        <div className="flex flex-col gap-5 justify-center items-center">
          {stage ? (
            <Spinner color="gray" className="size-24" />
          ) : (
            <FilingCompleteGraphic />
          )}
          <span className="text-center">
            {stage ? stage.label : completedMessage}
          </span>

          <LoadingBar
            percentage={stage?.percentage || 100}
            className="w-[400px]"
          />
        </div>
      </DialogPanel>
    </Dialog>
  );
}

const stages = [
  {
    label: "Analyzing Documents...",
    percentage: 0.1,
  },
  {
    label: "Analyzing Documents...",
    percentage: 10,
  },
  {
    label: "Parsing using FylerAI...",
    percentage: 50,
  },
  {
    label: "Tagging Documents",
    percentage: 75,
  },
];

function useSubmittingStages(open: boolean, processingTime: number) {
  const [stageIndex, setStageIndex] = useState(0);

  const intervalRef = useRef<number>();

  // Reset stage index when open changes
  useEffect(() => {
    function startInterval() {
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        setStageIndex((f) => {
          if (f >= stages.length) {
            clearInterval(intervalRef.current);

            return f;
          }
          return f + 1;
        });
      }, processingTime / stages.length);
    }

    setStageIndex(0);
    startInterval();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [open, processingTime]);

  return stages[stageIndex] || null;
}
