import { CommonIconProps } from "./_common";

interface SearchIconProps extends CommonIconProps {
  active?: boolean;
}

// TODO: the icon shrinks when active
export function SearchIcon({ active, ...props }: SearchIconProps) {
  if (active) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        {...props}
      >
        <defs>
          <linearGradient
            id="search-gradient"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="orange" />
            <stop offset="1" stopColor="#32aae3" />
          </linearGradient>
        </defs>
        <path
          d="M10.5 3.5a7 7 0 100 14 7 7 0 000-14zm0 12a5 5 0 110-10 5 5 0 010 10z"
          fill="url(#search-gradient)"
        />
        <path
          d="M20.3 20.3l-3.8-3.8a1 1 0 011.4-1.4l3.8 3.8a1 1 0 01-1.4 1.4z"
          fill="url(#search-gradient)"
        />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <circle cx="10.5" cy="10.5" r="7" />
      <line x1="15.8" y1="15.8" x2="21" y2="21" />
    </svg>
  );
}
