import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { DocumentTypeEnum, Status6b5Enum } from "../../../../generated/types";
import {
  filingQueryKey,
  TDocument,
  TFiling,
} from "../../../_common/api/filings";
import { Table } from "../../../_common/components/Table/Table";
import { PaperClipIcon } from "../../../_common/components/icons/PaperClipIcon";
import { UploadDocumentCta } from "../../../_common/components/UploadDocumentCta";
import { ExhibitTypeCell } from "./ExhibitTypeCell";
import { updateDocument } from "../../../_common/api/document";
import { EditExhibitModal } from "../EditExhibitModal";
import { EditableTextCell } from "./EditableTextCell";
import {
  TableActionConfig,
  TableHeaderConfig,
} from "../../../_common/components/Table/types";
import { PlusIcon } from "../../../_common/components/icons/PlusIcon";
import { AddFileToDocumentCta } from "../../../_common/components/AddFileToDocumentCta";
import { documentSortFn } from "../../../_common/utils/documentSort";

interface ExhibitDocumentsTableProps {
  filing: TFiling;
}
export function ExhibitDocumentsTable({ filing }: ExhibitDocumentsTableProps) {
  const { data = filing } = useQuery<TFiling>({
    queryKey: [filingQueryKey(filing.id)],
  });
  const [editingExhibit, setEditingExhibit] = useState<TDocument | null>(null);

  function onModalClose() {
    setEditingExhibit(null);
  }

  const exhibitDocuments = data.documents.filter(
    (d) => d.document_type === DocumentTypeEnum.exhibit
  );

  const documentActions: TableActionConfig<TDocument>[] = [
    {
      label: "Edit",
      callback: (doc) => {
        setEditingExhibit(doc);
      },
    },
    {
      label: "Archive",
      callback: (doc) =>
        updateDocument(doc.id, { status: Status6b5Enum.archived }),
    },
  ];

  return (
    <>
      <Table
        data={exhibitDocuments}
        headers={headerConfigs}
        rowKey="id"
        actions={documentActions}
        sortFn={documentSortFn}
        leftGutterIcon={(doc) =>
          doc.uploaded_file ? (
            <PaperClipIcon className="text-brandBlue" />
          ) : (
            <PlusIcon className="text-red-500" />
          )
        }
        emptyState={
          <div className="pl-7 py-2">
            <UploadDocumentCta
              text="Upload Exhibit Document"
              documentType={DocumentTypeEnum.exhibit}
              filingId={filing.id}
            />
          </div>
        }
      />
      <EditExhibitModal
        open={!!editingExhibit}
        onClose={onModalClose}
        exhibit={editingExhibit}
      />
    </>
  );
}

const headerConfigs: TableHeaderConfig<TDocument>[] = [
  {
    label: "File Name",
    renderCellContent: (doc) => {
      if (!doc.uploaded_file) {
        return <AddFileToDocumentCta document={doc} />;
      }

      return (
        <Link
          to={`/filings/${doc.filing_id}/documents/${doc.id}`}
          className="text-brandBlue hover:underline"
        >
          {/* @ts-expect-error - metadata is json and untyped */}
          {doc.metadata?.name || "-"}
        </Link>
      );
    },
    /* @ts-expect-error - metadata is json and untyped */
    getStringContent: (doc) => doc.metadata?.name || "-",
    hasTooltip: true,
    className: "w-1/6",
  },
  {
    label: "Type",
    renderCellContent: ExhibitTypeCell,
    getStringContent: (doc) => doc.exhibit_type || "-",
    hasTooltip: false,
    className: "w-1/12",
  },
  {
    label: "Suffix",
    getStringContent: (doc) => doc.exhibit_type_suffix || "-",
    renderCellContent: (doc) => (
      <EditableTextCell document={doc} keyName="exhibit_type_suffix" />
    ),
    hasTooltip: false,
    className: "w-1/12",
  },
  {
    label: "Description",
    renderCellContent: (doc) => (
      <EditableTextCell document={doc} keyName="exhibit_link_text" />
    ),
    getStringContent: (doc) => doc.exhibit_link_text || "-",
    hasTooltip: true,
  },
  {
    label: "EDGAR Description",
    renderCellContent: (doc) => (
      <EditableTextCell document={doc} keyName="exhibit_edgar_description" />
    ),
    getStringContent: (doc) => doc.exhibit_edgar_description || "-",
    hasTooltip: true,
  },
];
