import { useNavigate, useParams } from "react-router-dom";
import { useMemo, useState } from "react";

import { Breadcrumbs } from "../_common/components/Breadcrumbs/Breadcrumbs";
import { DocumentTypeBadge } from "./components/DocumentTypeBadge";
import { ButtonLink } from "../_common/components/ButtonLink";
import { DocumentHtmlViewer } from "./components/DocumentHtmlViewer/DocumentHtmlViewer";
import { DocumentTypeEnum } from "../../generated/types";
import { EditExhibitModal } from "../Filing/components/EditExhibitModal";
import { useDocument } from "./hooks/useDocument";
import { ID } from "../_common/types/id";
import { FullscreenLoader } from "../_common/components/FullscreenLoader";
import { removeExhibitHeader } from "../_common/api/document";
import { useFiling } from "../Filing/hooks/useFiling";
import { convertUploadedDocumentToConstructedDocument } from "../_common/api/constructedDocument";

export function Document() {
  const { filingId, documentId } = useParams();
  const document = useDocument(filingId as ID, documentId as ID, 0);
  const filing = useFiling(filingId as ID);

  const [editingExhibit, setEditingExhibit] = useState(false);

  const hasHeader = useMemo(() => {
    return !!document?.parsed_html?.includes(
      'id="davis-exhibit-document-header"'
    );
  }, [document?.parsed_html]);

  const convertedDoc = useMemo(
    () => filing?.constructed_primary_documents?.[0],
    [filing?.constructed_primary_documents]
  );
  const navigate = useNavigate();
  async function convertToConstructed() {
    if (!documentId) return;

    const doc = await convertUploadedDocumentToConstructedDocument(
      documentId as ID
    );

    navigate(`/filings/${filingId}/constructed-documents/${doc.id}`);
  }

  if (!document) return <FullscreenLoader />;

  return (
    <>
      <div className="flex flex-col gap-4">
        <Breadcrumbs className="flex-none" />

        <div className="flex-1 border-t py-4">
          <div className="flex justify-between items-center">
            <div className="flex gap-3 items-center">
              <h3 className="font-semibold text-xl max-w-[25ch] overflow-hidden text-ellipsis whitespace-nowrap">
                {/* @ts-expect-error - metadata is untyped */}
                {document.metadata?.name}
              </h3>
              <DocumentTypeBadge document={document} className="flex-none" />

              {document.document_type === DocumentTypeEnum.primary_document &&
                !convertedDoc && (
                  <>
                    <ButtonLink onClick={convertToConstructed}>
                      Convert to constructed
                    </ButtonLink>
                  </>
                )}

              {document.document_type === DocumentTypeEnum.exhibit && (
                <>
                  {/* Leftactions */}
                  <ButtonLink onClick={() => setEditingExhibit(true)}>
                    Edit
                  </ButtonLink>
                  <EditExhibitModal
                    exhibit={document}
                    open={editingExhibit}
                    onClose={() => setEditingExhibit(false)}
                  />
                </>
              )}
            </div>

            {hasHeader && (
              <ButtonLink
                onClick={() => removeExhibitHeader(document.id)}
                className="text-xs text-red-400"
              >
                Remove Header
              </ButtonLink>
            )}

            {/* <a
              href={`/api/documents/${document.id}/generate-pdf/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ButtonLink className="text-gray-500">
                <DownloadIcon />
                <span>Download as PDF</span>
              </ButtonLink>
            </a> */}
          </div>
        </div>

        <DocumentHtmlViewer document={document} />
      </div>
    </>
  );
}
