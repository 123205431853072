import { ChangeEventHandler } from "react";

import { Button } from "../../../../_common/components/Button";
import { useMultistageFlowContext } from "../../../../_common/components/MultistageFlow/context";
import { MultistageFlowNavButtons } from "../../../../_common/components/MultistageFlow/MultistageFlow";
import { Textarea } from "../../../../_common/components/Textarea";
import { AddBlockMultistageContextData } from "./types";
import { RichTextEditor } from "../../EditableBlock/RichTextEditor/RichTextEditor";

export function CollectUserInputStage() {
  const { setContextData, contextData, complete, submitting } =
    useMultistageFlowContext<AddBlockMultistageContextData>();

  const handleInputChange: ChangeEventHandler<HTMLTextAreaElement> = (e) =>
    setContextData({
      ...contextData,
      user_input: e.currentTarget.value,
    });

  function handleRichTextBlur(t: string) {
    setContextData({
      ...contextData,
      user_input: t,
    });
  }

  return (
    <div className="space-y-2">
      {contextData.strategy === "llm" && (
        <>
          <h3 className="text-lg">
            Describe what the disclosure should contain
          </h3>

          <Textarea onChange={handleInputChange} className="w-full" />
        </>
      )}
      {contextData.strategy === "custom" && (
        <>
          <h3 className="text-lg">Edit your content</h3>

          <RichTextEditor
            onContentUpdate={handleRichTextBlur}
            initialContent={
              contextData.disclosure_type_template?.default_content || ""
            }
          />
        </>
      )}
      <MultistageFlowNavButtons currentStageValid>
        <Button
          variant="default"
          color="blue"
          onClick={complete}
          className="flex-1"
          disabled={!contextData.user_input}
          loading={submitting}
        >
          Submit
        </Button>
      </MultistageFlowNavButtons>
    </div>
  );
}
