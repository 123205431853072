import { MouseEventHandler, useCallback } from "react";

interface ClickHandlerConfig {
  shouldFireFn: (element: Element) => boolean;
  callback: (element: Element) => void;
}

interface UseViewerClickHandlersParams {
  handlers: ClickHandlerConfig[];
}

export function useViewerClickHandler(params: UseViewerClickHandlersParams) {
  return useCallback<MouseEventHandler<HTMLElement>>(
    (evn) => {
      const target = evn.target as Element;
      params.handlers.forEach((h) => {
        if (h.shouldFireFn(target)) {
          h.callback(target);
        }
      });
    },
    [params.handlers]
  );
}
