import clsx from "clsx";
import { Input as HInput, InputProps } from "@headlessui/react";
import { forwardRef } from "react";

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    return (
      <HInput
        {...props}
        ref={ref}
        className={clsx(
          "outline outline-1 outline-gray-300 rounded px-2 text-sm h-11 disabled:text-gray-400",
          className
        )}
      />
    );
  }
);
