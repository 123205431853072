import clsx from "clsx";
import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
} from "@heroicons/react/24/outline";
import { BubbleMenu, useCurrentEditor } from "@tiptap/react";

export function TableMenuControls() {
  const { editor } = useCurrentEditor();

  return (
    <BubbleMenu
      shouldShow={({ editor }) => editor.isActive("table")}
      editor={editor}
    >
      <div className="p-2 shadow bg-white rounded flex gap-2 items-center">
        <button onClick={() => editor?.chain().focus().toggleHeaderRow().run()}>
          <span
            className={clsx("size-4 font-bold", {
              "text-brandBlue": editor?.isActive("tableHeader"),
            })}
          >
            H
          </span>
        </button>
        <button onClick={() => editor?.chain().focus().deleteColumn().run()}>
          <ArrowLeftIcon className={clsx("size-4")} />
        </button>
        <button onClick={() => editor?.chain().focus().addColumnAfter().run()}>
          <ArrowRightIcon className={clsx("size-4")} />
        </button>
        <button onClick={() => editor?.chain().focus().deleteRow().run()}>
          <ArrowUpIcon className={clsx("size-4")} />
        </button>
        <button onClick={() => editor?.chain().focus().addRowAfter().run()}>
          <ArrowDownIcon className={clsx("size-4")} />
        </button>
      </div>
    </BubbleMenu>
  );
}
