import { useMemo } from "react";
import { useMultistageFlowContext } from "../../../_common/components/MultistageFlow/context";
import { WrappedData } from "../../types";
import { FILING_AGENTS_LOOKUP, FilingAgent } from "../../utils/filingAgentData";

export function VendorsBreakdown() {
  const {
    contextData: {
      filing_agent_prepared_name_count_lookup,
      self_filed_software_count_lookup,
    },
  } = useMultistageFlowContext<WrappedData>();

  const filingAgentCiksList = useMemo(
    () => Object.keys(filing_agent_prepared_name_count_lookup),
    [filing_agent_prepared_name_count_lookup],
  );
  const agentsUsed = useMemo(() => {
    return filingAgentCiksList
      .map((k) => FILING_AGENTS_LOOKUP[k])
      .filter((k, i, list) => {
        return k && !list.slice(i + 1).find((el) => el?.name === k.name);
      });
  }, [filingAgentCiksList]);

  const softwareNameList = useMemo(
    () => Object.keys(self_filed_software_count_lookup),
    [self_filed_software_count_lookup],
  );

  const filingSoftwareUsed = useMemo(() => {
    if (softwareNameList.length >= 2) return [];

    return softwareNameList;
  }, [softwareNameList]);

  return (
    <div className="mx-auto flex h-full flex-col items-center justify-between text-center py-6">

      <h3 className="text-xl uppercase md:text-5xl">
        {getConditionalTopCopy(softwareNameList, agentsUsed)}
      </h3>

      <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between md:gap-10">
        {agentsUsed.slice(0, 2).map((agent) => (
          <div key={agent.cik} className="space-y-2">
            <div className="flex h-32 w-32 items-center justify-center bg-gradient-to-br from-[#F8CB26] to-[#52C3D5] p-10 md:h-72 md:w-80">
              <img
                src={`https://img.logo.dev/${agent.website}?token=pk_Q-nim_IsRpWqIXx9w-WAxA`}
                className="h-full object-contain"
              />
            </div>
            <div>
              <div className="text-xl">{agent.name}</div>
              <div className="text-gray-500">(Filing Agent)</div>
            </div>
          </div>
        ))}
        {agentsUsed.length < 2 &&
          filingSoftwareUsed.map((softwareName) => (
            <div key={softwareName} className="space-y-2">
              <div className="flex h-32 w-32 items-center justify-center bg-gradient-to-br from-[#F8CB26] to-[#52C3D5] p-10 md:h-72 md:w-80">
                <img
                  src={`/${softwareName}.png`}
                  alt={softwareName}
                  className="h-full object-contain"
                />
              </div>
              <div>
                <div className="text-xl">{softwareName}</div>
                <div className="text-gray-500">(Software)</div>
              </div>
            </div>
          ))}
      </div>

      <p className="text-2xl">
        {getConditionalBottomCopy(filingSoftwareUsed, agentsUsed)}
      </p>
    </div>
  );
}

function getConditionalTopCopy(
  softwareNameList: string[],
  agents: FilingAgent[],
) {
  const usesSoftware = !!softwareNameList.length;
  const usesFilingAgents = !!agents.length;

  if (usesSoftware && !usesFilingAgents)
    return "You were pretty consistent in using the same software to get your filings done!";

  if (usesSoftware && usesFilingAgents)
    return "Oops. You paid for a software subscription, but still relied on filing agents!";

  return "Relying on filing agents can be expensive and time consuming!";
}

function getConditionalBottomCopy(
  softwareNameList: string[],
  agents: FilingAgent[],
) {
  const usesSoftware = !!softwareNameList.length;
  const usesFilingAgents = !!agents.length;
  const isWorkiva = softwareNameList.includes("Workiva");

  if (usesSoftware && !usesFilingAgents)
    return isWorkiva
      ? "We know it was a lot of manual work, but we're glad you got over the line."
      : "Using your printer's software doesn't sound right. Why don't you try something new this year?";

  if (usesSoftware && usesFilingAgents)
    return "How about you get software that works?";

  return "What if you could save time and money with an exponentially better solution next year?";
}
