import { CommonIconProps } from "./_common";

interface FilingsIconProps extends CommonIconProps {
  active?: boolean;
}

export function FilingsIcon({ active, ...props }: FilingsIconProps) {
  if (active) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 1000"
        {...props}
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="958.55"
            y1="-1206.36"
            x2="-22.02"
            y2="-2073.79"
            gradientTransform="translate(0 -1112) scale(1 -1)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="orange" />
            <stop offset="1" stopColor="#32aae3" />
          </linearGradient>
        </defs>
        <path
          d="M506.28,75.71v223.62c0,15.99,0,31.54,1.72,44.36,1.94,14.45,6.68,30.94,20.37,44.64,13.7,13.7,30.19,18.43,44.64,20.38,12.82,1.72,28.36,1.72,44.36,1.72h234.62v259.29c0,120.01,0,180.01-38.66,217.29-38.66,37.28-100.89,37.28-225.34,37.28h-176c-124.45,0-186.68,0-225.34-37.28-38.66-37.28-38.66-97.28-38.66-217.29V330.29c0-120.01,0-180.01,38.66-217.29,38.66-37.28,100.89-37.28,225.34-37.28h94.29Zm75.43,.08v221.49c0,18.84,.08,29.18,1.04,36.36l.04,.27,.28,.04c7.17,.96,17.51,1.04,36.36,1.04h232.47c-.32-20.15-1.58-32.8-6.59-44.47-6.7-15.59-19.42-27.86-44.85-52.38l-116.9-112.73c-25.43-24.53-38.15-36.79-54.32-43.25-12.37-4.94-25.79-6.1-47.51-6.38Zm-251.43,466.63c0-20.83,16.89-37.71,37.72-37.71h264c20.83,0,37.71,16.88,37.71,37.71s-16.89,37.71-37.71,37.71H368c-20.83,0-37.72-16.88-37.72-37.71Zm37.72,132c-20.83,0-37.72,16.88-37.72,37.71s16.89,37.71,37.72,37.71h176c20.83,0,37.71-16.88,37.71-37.71s-16.88-37.71-37.71-37.71h-176Z"
          fill="url(#linear-gradient)"
          fillRule="evenodd"
        />
      </svg>
    );
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="60px"
        d="M555.23,104h-196.66c-88.89,0-133.34,0-160.96,25.77-27.62,25.77-27.62,67.26-27.62,150.23v440c0,82.97,0,124.45,27.62,150.23,27.62,25.77,72.06,25.77,160.96,25.77h282.86c88.89,0,133.34,0,160.96-25.77,27.61-25.77,27.61-67.26,27.61-150.23V360.45c0-17.98,0-26.98-3.59-35.06-3.59-8.09-10.4-14.44-24.02-27.16l-180.49-168.45c-13.63-12.72-20.44-19.08-29.1-22.43-8.66-3.35-18.3-3.35-37.57-3.35Z"
      />
      <path
        strokeLinecap="round"
        fill="none"
        stroke="currentColor"
        strokeWidth="60px"
        d="M358.57,544h282.86"
      />
      <path
        strokeLinecap="round"
        fill="none"
        stroke="currentColor"
        strokeWidth="60px"
        d="M358.57,720h188.57"
      />
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="60px"
        d="M547.14,104v176c0,41.48,0,62.23,13.81,75.11,13.81,12.89,36.03,12.89,80.48,12.89h188.57"
      />
    </svg>
  );
}
