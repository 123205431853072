import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { MultistageFlow } from "../../../../_common/components/MultistageFlow/MultistageFlow";
import { StageConfig } from "../../../../_common/components/MultistageFlow/context";
import { StrategyStage } from "./StrategyStage";
import { DisclosureTypeStage } from "./DisclosureTypeStage";
import { AddBlockMultistageContextData } from "./types";
import { CollectUserInputStage } from "./CollectUserInputStage";
import { CreateEditableBlockParams } from "../../../../_common/api/editableBlock";
import { generateContentForBlock } from "../../../../_common/api/llm";
import { ID } from "../../../../_common/types/id";
import { useDocumentBuilderContext } from "../context";

interface AddBlockDrawerProps {
  open: boolean;
  onClose: () => void;
  addBlock: (t: CreateEditableBlockParams) => void;
}

async function getBlockCreatePayload(
  t: AddBlockMultistageContextData,
  entityId: ID
): Promise<CreateEditableBlockParams | undefined> {
  if (!t.strategy) return; // Should never get here with a completed flow

  if (t.strategy === "custom") {
    return {
      title: t.disclosure_type_template?.default_title || "New block",
      content: t.disclosure_type_template?.default_content || "New content",
    };
  }

  if (t.strategy === "llm") {
    const payload = await generateContentForBlock({
      title: t.disclosure_type_template?.default_title || "Custom disclosure",
      description: t.disclosure_type_template?.description || "",
      user_input: t.user_input || "",
      entity_id: entityId,
    });
    return payload;
  }
}

export function AddBlockDrawer({
  open,
  onClose,
  addBlock,
}: AddBlockDrawerProps) {
  const { filing } = useDocumentBuilderContext();
  async function onComplete(t: AddBlockMultistageContextData) {
    if (!filing) return;

    const payload = await getBlockCreatePayload(t, filing.entity.id);
    if (!payload) return;

    addBlock(payload);
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-250 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-250 ease-in-out data-[closed]:translate-x-full sm:duration-500"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                      Add Text Block
                    </DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={onClose}
                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="relative pt-6 flex-1 px-4 sm:px-6">
                  <MultistageFlow
                    stages={stages}
                    initialContextData={{}}
                    onComplete={onComplete}
                  />
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

const stages: StageConfig[] = [
  { id: "disclosure_type", component: DisclosureTypeStage },
  { id: "strategy", component: StrategyStage },
  { id: "user_input", component: CollectUserInputStage },
];
