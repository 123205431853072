import clsx from "clsx";
import { PlusCircleIcon } from "@heroicons/react/16/solid";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { TConstructedPrimaryDocument } from "../../../_common/api/filings";
import { EditableBlock } from "../EditableBlock/EditableBlock";
import { useDocumentBuilderContext } from "./context";
import {
  createEditableBlock,
  CreateEditableBlockParams,
  deleteEditableBlock,
} from "../../../_common/api/editableBlock";
import { listToNormalized } from "../../../_common/utils/normalized";
import {
  TBallotValues,
  updateBlocksOrder,
  updateConstructedDocument,
} from "../../../_common/api/constructedDocument";
import { isAxiosError } from "axios";
import { useDispatchErrorToast } from "../../../_common/components/Toasts/context";
import { ID } from "../../../_common/types/id";
import { AddBlockDrawer } from "./AddBlockDrawer/AddBlockDrawer";
import { MouseSensor } from "./MouseSensor";
import { useViewerClickHandler } from "../../../Document/hooks/useViewerClickHandler";
import { useDebounced } from "../../../_common/hooks/useDebounced";
import { AddSignatureDrawer } from "./AddSignatureDrawer";
import {
  addSignatureToDocument,
  SignatureData,
} from "../../../_common/api/signatures";
import { EditableSignature } from "../EditableSignature";

interface DocumentBuilderProps {
  document: TConstructedPrimaryDocument;
}
export function DocumentBuilder({ document }: DocumentBuilderProps) {
  const { filing } = useDocumentBuilderContext();

  const normalizedBlocks = useMemo(
    () => listToNormalized(document.editable_blocks, (eb) => eb.id),
    [document]
  );

  const [ids, setIds] = useState(normalizedBlocks.ids);

  useEffect(() => {
    setIds(normalizedBlocks.ids);
  }, [normalizedBlocks.ids]);

  const sensors = useSensors(useSensor(MouseSensor));

  const dispatchErrorToast = useDispatchErrorToast();
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  async function handleDragEndEvent(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id == over?.id) return;
    const newIds = (() => {
      const oldIndex = ids.indexOf(active.id as number);
      const newIndex = ids.indexOf(over?.id as number);

      return arrayMove(ids, oldIndex, newIndex);
    })();

    try {
      setActiveId(null);
      setIds(newIds);
      await updateBlocksOrder(document.id, newIds);
    } catch (err) {
      if (isAxiosError(err)) {
        dispatchErrorToast(err.message, 3000);
      }
    }
  }

  function handleDragStartEvent(event: DragStartEvent) {
    setActiveId(event.active.id);
  }

  async function removeBlock(blockId: ID) {
    if (!filing || !document) return;

    try {
      await deleteEditableBlock(filing.id, document.id, blockId);
      setIds((prev) => prev.filter((id) => id !== blockId));
    } catch (err) {
      if (isAxiosError(err)) {
        dispatchErrorToast(err.message);
      }
    }
  }

  const [addBlockSearchOpen, setAddBlockSearchOpen] = useState(false);

  async function onAddBlock(params: CreateEditableBlockParams) {
    if (!filing || !document) return;
    try {
      createEditableBlock(filing.id, document.id, params);

      setAddBlockSearchOpen(false);
    } catch (err) {
      if (isAxiosError(err)) {
        dispatchErrorToast(err.message);
      }
    }
  }

  const coverPageRef = useRef<HTMLDivElement>(null);
  const debouncedConstructedDocumentUpdate = useDebounced(
    updateConstructedDocument
  );

  const handleBallotClick = useCallback(
    (e: Element) => {
      // Swap the text content of the element
      // Get the ballot values object
      // Call update
      e.textContent = e.textContent === "☑" ? "☐" : "☑";

      const ballotValues: Record<string, boolean> = {};
      for (const ballot of coverPageRef.current?.querySelectorAll(
        '[data-davis-annotated="davis-ballot"]'
      ) || []) {
        ballotValues[
          ballot.getAttribute("data-davis-constructed-ballot-key") || ""
        ] = ballot.textContent === "☑";
      }

      debouncedConstructedDocumentUpdate(document.id, {
        ballot_values: ballotValues as TBallotValues,
      });
    },
    [debouncedConstructedDocumentUpdate, document.id]
  );

  const coverPageClickHandler = useViewerClickHandler({
    handlers: [
      {
        shouldFireFn: (el) =>
          el.getAttribute("data-davis-annotated") === "davis-ballot",
        callback: handleBallotClick,
      },
    ],
  });

  const [addSignatureDrawerOpen, setAddSignatureDrawerOpen] = useState(false);
  async function addSignature(params: SignatureData) {
    if (!filing) return;

    try {
      await addSignatureToDocument(document.id, filing.id, params);
      setAddSignatureDrawerOpen(false);
    } catch (err) {
      if (isAxiosError(err)) {
        dispatchErrorToast(err.message, 2000);
      }
    }
  }

  return (
    <div className="bg-white rounded-lg p-12 shadow-lg">
      <div

        style={{ fontFamily: document.font_set.title }}
        dangerouslySetInnerHTML={{ __html: document.templated_cover_page }}
        onClick={coverPageClickHandler}
        ref={coverPageRef}
        className="fyler_document_viewer"
      />

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEndEvent}
        onDragStart={handleDragStartEvent}
      >
        <SortableContext items={ids} strategy={verticalListSortingStrategy}>
          <div className="space-y-2" style={{ fontFamily: document.font_set.title }}>
            {ids.map((id) => (
              <EditableBlock
                key={id}
                editableBlock={normalizedBlocks.records[id]}
                onDelete={() => removeBlock(id)}
              />
            ))}
          </div>
        </SortableContext>
        <DragOverlay>
          {activeId ? (
            <div
              className={clsx(
                "p-2 rounded-md space-y touch-none bg-white border border-gray-300 cursor-grabbing shadow-md font-semibold"
              )}
            >
              {normalizedBlocks.records[activeId as number].title}
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>

      <button
        className="relative bg-gray-500 text-gray-400 cursor-pointer hover:bg-brandBlue hover:text-brandBlue w-full h-1 rounded-lg transform"
        onClick={() => setAddBlockSearchOpen(true)}
      >
        <div className="bg-white p-2 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 flex gap-2 items-center">
          <PlusCircleIcon className="size-4 transform" />
          <span>Add block</span>
        </div>
      </button>

      <AddBlockDrawer
        open={addBlockSearchOpen}
        onClose={() => setAddBlockSearchOpen(false)}
        addBlock={onAddBlock}
      />

      <div
        dangerouslySetInnerHTML={{ __html: document.templated_exhibits_table }}
      />

      <div className="space-y-2 py-3 text-xs"
        style={{ fontFamily: document.font_set.title }}
      >
        <h3 className="font-semibold text-center">Signatures</h3>
        <p>
          Pursuant to the requirements of the Securities Exchange Act of 1934,
          the registrant has duly caused this report to be signed on its behalf
          by the undersigned hereunto duly authorized.
        </p>

        <p className="text-right font-semibold pr-[52px]">
          {filing?.entity.name}
        </p>

        {document.signatures.map((s) => (
          <EditableSignature key={s.id} signature={s} />
        ))}
      </div>

      <button
        className="relative bg-gray-500 text-gray-400 cursor-pointer hover:bg-brandBlue hover:text-brandBlue w-full h-1 rounded-lg transform"
        onClick={() => setAddSignatureDrawerOpen(true)}
      >
        <div className="bg-white p-2 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 flex gap-2 items-center">
          <PlusCircleIcon className="size-4 transform" />
          <span>Add signature</span>
        </div>
      </button>
      <AddSignatureDrawer
        open={addSignatureDrawerOpen}
        onClose={() => setAddSignatureDrawerOpen(false)}
        addSignature={addSignature}
      />
    </div>
  );
}
