import clsx from "clsx";
import { HtmlHTMLAttributes, ReactNode } from "react";

interface ModalActionsProps extends HtmlHTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}
export function ModalActions({
  children,
  className,
  ...props
}: ModalActionsProps) {
  return (
    <div
      className={clsx(
        "border-t pt-5 flex items-center justify-end gap-5",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}
