import { isAxiosError } from "axios";
import { isRouteErrorResponse, useRouteError } from "react-router";
import { FullscreenLoader } from "./FullscreenLoader";
import { useEffect } from "react";

export function RootErrorElement() {
  const error = useRouteError();

  useEffect(() => {
    if (isAuthError(error)) {
      window.location.replace("/login/");
    }
    // Send to sentry ?
  }, [error]);

  if (isAuthError(error)) {
    return <FullscreenLoader />;
  }

  if (isRouteErrorResponse(error)) {
    return <div>{error.statusText}</div>;
  }

  return <div>Unknown error: {String(error)}</div>;
}

function isAuthError(error: unknown) {
  if (!isAxiosError(error)) return false;

  return error.response?.status === 403;
}
