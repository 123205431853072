import { useEffect } from "react";
import ReactGA from "react-ga4";

ReactGA.initialize("G-EHMKCC4L7R");

type Event =
  | {
      name: "wrapped_landing_viewed";
    }
  | {
      name: "wrapped_step_viewed";
      params: {
        ticker: string;
        year: string;
        stepName: string;
      };
    };

export function trackEvent(event: Event) {
  // console.log("Tracking event!", event);
  if ("params" in event) {
    ReactGA.event(event.name, event.params);
  } else {
    ReactGA.event(event.name);
  }
}

// Tracks on mount in a useEffect
export function useTrack(event: Event) {
  useEffect(() => {
    trackEvent(event);
  }, [event]);
}

export function trackPageView(page: string) {
  ReactGA.send({ hitType: "pageview", page });
}
