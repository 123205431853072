import { create } from "zustand";
import { persist } from "zustand/middleware";
import {
  ExtractedDerivativeTransaction,
  ExtractedNonDerivativeTransaction,
  ExtractedReportingOwner,
} from "../../_common/api/fidelity_extract";
import { format } from "date-fns/format";

export interface StubbedReportingOwner extends ExtractedReportingOwner {
  id: number;
}

export interface StubbedNonDerivativeTransaction
  extends ExtractedNonDerivativeTransaction {
  id: number;
  reportingOwnerId: number;
}

export interface StubbedDerivativeTransaction
  extends ExtractedDerivativeTransaction {
  id: number;
  reportingOwnerId: number;
}

export type StubbedFiling = {
  id: number;
  label: string;
  status: "in_review" | "scheduled" | "draft";
  reportingOwnerId: number;
  derivativeTransactionIds: number[];
  nonDerivativeTransactionIds: number[];
};

export type StubbedSecurityType = {
  id: number;
  title: string;
  derivesFromId: null | number;
};

interface StubbedDataStore {
  addFiling: (
    reportingOwnerId: number,
    derivativeTransactionIds: number[],
    nonDerivativeTransactionIds: number[]
  ) => number;
  upsertSecurityType: (title: string, derivesFromId?: number) => number;
  addDerivativeTransactions: (
    transactions: Omit<StubbedDerivativeTransaction, "id">[]
  ) => number[];
  addNonDerivativeTransactions: (
    transactions: Omit<StubbedNonDerivativeTransaction, "id">[]
  ) => number[];
  addReportingOwner: (
    reportingOwner: Omit<StubbedReportingOwner, "id">
  ) => number;
  derivativeTransactions: StubbedDerivativeTransaction[];
  nonDerivativeTransactions: StubbedNonDerivativeTransaction[];
  scheduledFilings: StubbedFiling[];
  reportingOwners: StubbedReportingOwner[];
  securityTypes: StubbedSecurityType[];
  transactionHistory: (
    | StubbedDerivativeTransaction
    | StubbedNonDerivativeTransaction
  )[];
}

export const useStubbedData = create<StubbedDataStore>()(
  persist(
    (set, get) => ({
      addReportingOwner(reportingOwner) {
        const { reportingOwners } = get();
        const nextId = getNextId(reportingOwners);

        set((prev) => ({
          reportingOwners: [
            {
              ...reportingOwner,
              id: nextId,
            },
            ...prev.reportingOwners,
          ],
        }));

        return nextId;
      },
      addDerivativeTransactions(transactions) {
        const {
          derivativeTransactions,
          nonDerivativeTransactions,
          transactionHistory,
        } = get();
        let nextId = getNextId([
          ...derivativeTransactions,
          ...nonDerivativeTransactions,
          ...transactionHistory,
        ]);

        const newTransactions: StubbedDerivativeTransaction[] = [];
        const createdIds = [];
        for (const transaction of transactions) {
          newTransactions.push({
            ...transaction,
            id: nextId,
          });

          createdIds.push(nextId);

          nextId++;
        }

        set((prev) => ({
          derivativeTransactions: [
            ...newTransactions,
            ...prev.derivativeTransactions,
          ],
        }));

        return createdIds;
      },
      addNonDerivativeTransactions(transactions) {
        const {
          nonDerivativeTransactions,
          derivativeTransactions,
          transactionHistory,
        } = get();
        let nextId = getNextId([
          ...nonDerivativeTransactions,
          ...derivativeTransactions,
          ...transactionHistory,
        ]);

        const newTransactions: StubbedNonDerivativeTransaction[] = [];
        const createdIds = [];
        for (const transaction of transactions) {
          newTransactions.push({
            ...transaction,
            id: nextId,
          });

          createdIds.push(nextId);

          nextId++;
        }

        set((prev) => ({
          nonDerivativeTransactions: [
            ...newTransactions,
            ...prev.nonDerivativeTransactions,
          ],
        }));

        return createdIds;
      },
      upsertSecurityType(title, derivesFromId) {
        const securityTypes = get().securityTypes;
        const existingSecurityTypeIndex = securityTypes.findIndex(
          (st) => st.title === title
        );

        if (existingSecurityTypeIndex > 0) {
          const existingSecurityType = securityTypes[existingSecurityTypeIndex];
          if (existingSecurityType.derivesFromId !== derivesFromId) {
            set((prev) => ({
              securityTypes: [
                ...prev.securityTypes.slice(0, existingSecurityTypeIndex),
                {
                  ...existingSecurityType,
                  derivesFromId: derivesFromId || null,
                },
                ...prev.securityTypes.slice(existingSecurityTypeIndex + 1),
              ],
            }));
          }
          return securityTypes[existingSecurityTypeIndex].id;
        }

        const nextId = getNextId(securityTypes);

        set((prev) => ({
          securityTypes: [
            { id: nextId, title, derivesFromId: derivesFromId || null },
            ...prev.securityTypes,
          ],
        }));
        return nextId;
      },
      addFiling(
        reportingOwnerId,
        derivativeTransactionIds,
        nonDerivativeTransactionIds
      ) {
        const existingFilings = get().scheduledFilings;
        const nextId = getNextId(existingFilings);
        set({
          scheduledFilings: [
            ...existingFilings,
            {
              id: nextId,
              label: `${format(new Date(), "MM/dd/yyyy")} Form 4`,
              status: "draft",
              reportingOwnerId,
              derivativeTransactionIds,
              nonDerivativeTransactionIds,
            },
          ],
        });
        return nextId;
      },
      derivativeTransactions: [],
      nonDerivativeTransactions: [],
      scheduledFilings: [],
      reportingOwners: [],
      securityTypes: [],
      sixteenBTransactions: [],
      transactionHistory: [],
    }),
    {
      name: "section-16-stub-data",
    }
  )
);

interface EntityWithId {
  id: number;
}
function getNextId(existingEntities: EntityWithId[]) {
  if (!existingEntities.length) return 1;
  return Math.max(...existingEntities.map((i) => i.id)) + 1;
}

export function isDerivative(
  transaction: StubbedDerivativeTransaction | StubbedNonDerivativeTransaction
) {
  return "title_of_underlying_security" in transaction;
}
