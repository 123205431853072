import { FocusEventHandler } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

import { Breadcrumbs } from "../_common/components/Breadcrumbs/Breadcrumbs";
import { StatusBadge } from "./components/StatusBadge";
import { Button } from "../_common/components/Button";
import { FilingDataForm } from "./components/FilingDataForm";
import { ExhibitDocumentsTable } from "./components/ExhibitDocumentsTable/ExhibitDocumentsTable";
import { PrimaryDocumentTable } from "./components/PrimaryDocumentTable";
import { filingQueryKey, TFiling, updateFiling } from "../_common/api/filings";
import { FullscreenLoader } from "../_common/components/FullscreenLoader";
import { UploadDocumentCta } from "../_common/components/UploadDocumentCta";
import { DocumentTypeEnum } from "../../generated/types";
import { SubmitFiling } from "./components/SubmitFiling";
import { useFilingValidationErrors } from "./hooks/useFilingValidationErrors";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../_common/components/Tooltip";
import { ReferenceDocumentsTable } from "./components/ReferenceDocumentsTable";
import { AddReferenceCta } from "./components/AddReferenceCta";
import { ID } from "../_common/types/id";
import { PreviewEDGAR } from "./components/PreviewEdgar";
import { ItemsTable } from "./components/ItemsTable";
import { SubmitFilingForReview } from "./components/SubmitFilingForReview";

export function Filing() {
  const { filingId } = useParams();
  const { data } = useQuery<TFiling>({
    queryKey: [filingQueryKey(filingId as ID)],
  });

  const handleNameBlur: FocusEventHandler<HTMLHeadingElement> = (e) => {
    const val = e.currentTarget.textContent;
    if (!data?.id || !val || val === data.name) return;
    updateFiling(data.id, {
      name: val.trim(),
    });
  };

  const {
    exhibitsErrors,
    filingErrors,
    hasExhibitsErrors,
    hasFilingErrors,
    hassPrimaryDocumentErrors,
    primaryDocumentErrors,
    referenceDocumentsErrors,
    hasReferenceDocumentsErrors,
    reviewable,
  } = useFilingValidationErrors(data);

  if (!data) return <FullscreenLoader />;

  const items = data.items_8k || [];

  return (
    <>
      <div className="flex flex-col gap-4">
        <Breadcrumbs className="flex-none" />

        <div className="flex-1 border-t py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <h3
                className="text-xl font-semibold"
                contentEditable
                onBlur={handleNameBlur}
              >
                {data.name}
              </h3>
              <StatusBadge filing={data} />

              {hasFilingErrors ||
                (hassPrimaryDocumentErrors && (
                  <Tooltip>
                    <TooltipTrigger>
                      <ExclamationCircleIcon className="h-4 w-4 text-red-500" />
                    </TooltipTrigger>
                    <TooltipContent className="max-w-80 rounded bg-white p-4 text-xs text-gray-400 shadow-lg">
                      <ul className="list-inside list-disc space-y-2">
                        {Object.entries(filingErrors).map(([key, value]) => (
                          <li key={key}>{value}</li>
                        ))}
                        {Object.entries(primaryDocumentErrors).map(
                          ([key, value]) => (
                            <li key={key}>{value}</li>
                          ),
                        )}
                      </ul>
                    </TooltipContent>
                  </Tooltip>
                ))}
            </div>

            <div className="flex items-center gap-4">
              {/* <a
                href={`/api/filings/${filingId}/generate-pdf-proof/`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <ButtonLink className="text-gray-500">
                  <DownloadIcon />
                  <span>Download as PDF</span>
                </ButtonLink>
              </a> */}

              {data.latest_submission && (
                <Link to="submission">
                  <Button variant="outline" color="blue">
                    View Submission
                  </Button>
                </Link>
              )}

              <SubmitFilingForReview filing={data} disabled={!!reviewable} />

              <PreviewEDGAR
                filing={data}
                disabled={!!(hasFilingErrors || hasExhibitsErrors)}
              />

              {data.status === "draft" && (
                <SubmitFiling
                  filing={data}
                  disabled={
                    !!(
                      hasFilingErrors ||
                      hasExhibitsErrors ||
                      hassPrimaryDocumentErrors
                    )
                  }
                />
              )}
            </div>
          </div>
        </div>

        <FilingDataForm filing={data} />

        <div className="space-y-3 py-5">
          <h3 className="font-semibold">Primary Document</h3>
          <ItemsTable items={items} />
          <PrimaryDocumentTable filing={data} />
        </div>

        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1">
              <h3 className="font-semibold">Exhibits</h3>
              {hasExhibitsErrors && (
                <Tooltip>
                  <TooltipTrigger>
                    <ExclamationCircleIcon className="h-4 w-4 text-red-500" />
                  </TooltipTrigger>
                  <TooltipContent className="max-w-80 rounded bg-white p-4 text-xs text-gray-400 shadow-lg">
                    <ul className="list-inside list-disc space-y-2">
                      {Object.entries(exhibitsErrors).map(([key, value]) => (
                        <li key={key}>{value}</li>
                      ))}
                    </ul>
                  </TooltipContent>
                </Tooltip>
              )}
            </div>
            <UploadDocumentCta
              text="Upload New File"
              filingId={data.id}
              documentType={DocumentTypeEnum.exhibit}
            />
          </div>

          <ExhibitDocumentsTable filing={data} />
        </div>

        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1">
              <h3 className="font-semibold">Referenced Exhibits</h3>
              {hasReferenceDocumentsErrors && (
                <Tooltip>
                  <TooltipTrigger>
                    <ExclamationCircleIcon className="h-4 w-4 text-red-500" />
                  </TooltipTrigger>
                  <TooltipContent className="max-w-80 rounded bg-white p-4 text-xs text-gray-400 shadow-lg">
                    <ul className="list-inside list-disc space-y-2">
                      {Object.entries(referenceDocumentsErrors).map(
                        ([key, value]) => (
                          <li key={key}>{value}</li>
                        ),
                      )}
                    </ul>
                  </TooltipContent>
                </Tooltip>
              )}
            </div>
            <AddReferenceCta filing={data} />
          </div>

          <ReferenceDocumentsTable filing={data} />
        </div>
      </div>
    </>
  );
}
