export function useMainContentRect() {
  const mainContentElement = document.getElementById("main-content-panel")
  if (!mainContentElement) return {
    top: 0,
    left: 0,
    width: 0,
    height: 0
  }
  const rect = mainContentElement?.getBoundingClientRect()

  return rect
}
