import { HtmlHTMLAttributes } from "react";
import clsx from "clsx";

interface CardProps extends HtmlHTMLAttributes<HTMLDivElement> {
}

export function Card({ children, className, ...props }: CardProps) {
  return (
    <div className={clsx("rounded-lg bg-white", className)} {...props}>
      {children}
    </div>
  );
}
