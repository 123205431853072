import { components } from "../../../generated/types";
import { ID } from "../types/id";
import {
  listToNormalized,
  normalizedToList,
  upsertToNormalized,
} from "../utils/normalized";
import { axios, queryClient } from "./client";
import {
  filingQueryKey,
  TConstructedPrimaryDocument,
  TFiling,
} from "./filings";

export type TEditableBlock = components["schemas"]["EditableBlock"];
export type TBallotValues = components["schemas"]["BallotValues"];

export async function updateBlocksOrder(id: ID, orderedIds: ID[]) {
  const { data } = await axios.patch<TConstructedPrimaryDocument>(
    `constructed_primary_documents/${id}/update_blocks_order/`,
    { orderedIds }
  );

  _updateConstructedDocumentInCache(data);

  return data;
}

interface UpdateConstructedDocumentParams {
  title?: string;
  ballot_values?: TBallotValues;
}
export async function updateConstructedDocument(
  id: ID,
  params: UpdateConstructedDocumentParams
) {
  const { data } = await axios.patch<TConstructedPrimaryDocument>(
    `constructed_primary_documents/${id}/`,
    params
  );

  _updateConstructedDocumentInCache(data);

  return data;
}

function _updateConstructedDocumentInCache(data: TConstructedPrimaryDocument) {
  const existingFilingsData = queryClient.getQueryData<TFiling>([
    filingQueryKey(data.filing),
  ]);

  if (!existingFilingsData) {
    return;
  }

  let normReferences = listToNormalized(
    existingFilingsData.constructed_primary_documents,
    (d) => d.id
  );

  normReferences = upsertToNormalized(normReferences, data, (d) => d.id);

  const updatedFilingData = {
    ...existingFilingsData,
    constructed_primary_documents: normalizedToList(normReferences),
  };

  queryClient.setQueryData([filingQueryKey(data.filing)], updatedFilingData);
}

export async function convertUploadedDocumentToConstructedDocument(
  documentId: ID
) {
  const { data } = await axios.post<TConstructedPrimaryDocument>(
    `documents/${documentId}/convert_to_constructed_document/`
  );

  _updateConstructedDocumentInCache(data);

  return data;
}
