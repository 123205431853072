import { Label as HLabel, LabelProps } from "@headlessui/react";
import clsx from "clsx";

export function Label({ className, ...props }: LabelProps) {
  return (
    <HLabel
      {...props}
      className={clsx("text-gray-500 font-semibold text-sm", className)}
    />
  );
}
