import clsx from "clsx";
import { HtmlHTMLAttributes } from "react";

interface LoadingBarProps extends HtmlHTMLAttributes<HTMLDivElement> {
  percentage: number;
}
export function LoadingBar({
  percentage,
  className,
  ...props
}: LoadingBarProps) {
  return (
    <div
      className={clsx("bg-gray-300 overflow-hidden h-3 rounded-lg", className)}
      {...props}
    >
      <div
        style={{ width: `${percentage}%` }}
        className="h-full bg-gradient-to-r from-brandBlue to-brandOrange transition-all ease-in-out animate-gradient"
      />
    </div>
  );
}
