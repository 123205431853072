import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Field,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { Label } from "../../../_common/components/Label";
import { Input } from "../../../_common/components/Input";
import { Button } from "../../../_common/components/Button";
import { SignatureData } from "../../../_common/api/signatures";

interface AddSignatureDrawerProps {
  open: boolean;
  onClose: () => void;
  addSignature: (params: SignatureData) => Promise<void>;
}

export function AddSignatureDrawer({
  open,
  onClose,
  addSignature,
}: AddSignatureDrawerProps) {
  const { handleSubmit, register, formState } = useForm({
    defaultValues: { title: "", full_name: "" },
  });
  const isValid = formState.isValid;
  const isSubmitting = formState.isSubmitting;

  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-250 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-250 ease-in-out data-[closed]:translate-x-full sm:duration-500"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                      Add signature
                    </DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={onClose}
                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        {" "}
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="relative pt-6 flex-1 px-4 sm:px-6">
                  <form
                    onSubmit={handleSubmit(addSignature)}
                    className="space-y-2"
                  >
                    <Field className="flex flex-col gap-2">
                      <Label>Full name</Label>
                      <Input {...register("full_name", { required: true })} />
                    </Field>

                    <Field className="flex flex-col gap-2">
                      <Label>Title</Label>
                      <Input {...register("title", { required: true })} />
                    </Field>

                    <div className="flex gap-2 items-center">
                      <Button
                        type="button"
                        variant="default"
                        color="gray"
                        className="flex-1"
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="default"
                        color="blue"
                        loading={isSubmitting}
                        disabled={!isValid}
                        className="flex-1"
                      >
                        Save
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
