import { Field } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";

import { TDocument } from "../../_common/api/filings";
import { Modal } from "../../_common/components/Modal/Modal";
import { Label } from "../../_common/components/Label";
import { Input } from "../../_common/components/Input";
import { Select } from "../../_common/components/Select";
import { ModalActions } from "../../_common/components/Modal/ModalActions";
import { Button } from "../../_common/components/Button";
import {
  updateDocument,
  UpdateDocumentParams,
} from "../../_common/api/document";
import { Textarea } from "../../_common/components/Textarea";
import { useExhibitTypeOptions } from "../../_common/hooks/useExhibitTypeOptions";

interface EditExhibitModalProps {
  open: boolean;
  onClose: () => void;
  exhibit: TDocument | null;
}
export function EditExhibitModal({
  exhibit,
  open,
  onClose,
}: EditExhibitModalProps) {
  const exhibitTypeOptions = useExhibitTypeOptions();

  const defaultValues = useMemo(() => initialValues(exhibit), [exhibit]);

  const { handleSubmit, formState, register, reset } = useForm({
    defaultValues,
  });
  useEffect(() => reset(defaultValues), [defaultValues, reset]);

  async function onSubmit(v: UpdateDocumentParams) {
    if (!exhibit) {
      return;
    }

    await updateDocument(exhibit.id, v);
    onClose();
  }

  const loading = formState.isSubmitting;
  const valid = formState.isValid;

  return (
    <Modal open={open} onClose={onClose} title="Edit Exhibit">
      <form
        className="grid grid-cols-3 w-[420px] gap-y-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Field className="flex flex-col gap-2 col-span-3">
          <Label>File Name</Label>
          {/* @ts-expect-error - metadata is untyped */}
          <Input name="name" disabled value={exhibit?.metadata?.name} />
        </Field>
        <Field className="flex flex-col gap-2 col-span-2">
          <Label>Exhibit Type</Label>
          <Select {...register("exhibit_type")} className="rounded-r-none">
            {exhibitTypeOptions.map(({ label, value }) => (
              <option value={value}>{label}</option>
            ))}
          </Select>
        </Field>
        <Field className="flex flex-col gap-2 col-span-1">
          <Label>Suffix</Label>
          <Input
            {...register("exhibit_type_suffix")}
            className="rounded-l-none"
          />
        </Field>
        <Field className="flex flex-col gap-2 col-span-3">
          <Label>Link text</Label>
          <Textarea
            {...register("exhibit_link_text", {
              required: true,
              setValueAs: (v) => v.trim(),
            })}
            rows={4}
          />
        </Field>
        <Field className="flex flex-col gap-2 col-span-3">
          <Label>EDGAR Description</Label>
          <Textarea
            {...register("exhibit_edgar_description", {
              required: true,
              maxLength: 255,
              setValueAs: (v) => v.trim(),
            })}
            rows={4}
          />
        </Field>

        <ModalActions className="col-span-3">
          <Button
            variant="outline"
            color="gray"
            className="flex-1"
            type="button"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            variant="default"
            color="blue"
            className="flex-1"
            loading={loading}
            disabled={!valid}
          >
            Save
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
}

function initialValues(exhibit: TDocument | null): UpdateDocumentParams {
  return {
    exhibit_link_text: exhibit?.exhibit_link_text,
    exhibit_edgar_description: exhibit?.exhibit_edgar_description,
    exhibit_type: exhibit?.exhibit_type,
    exhibit_type_suffix: exhibit?.exhibit_type_suffix,
  };
}
