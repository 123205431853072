import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Description,
  DialogBackdrop,
} from "@headlessui/react";
import { ReactNode } from "react";

export interface CommonModalProps {
  open: boolean;
  onClose: () => void;
}

interface ModalProps extends CommonModalProps {
  title: string;
  description?: string;
  children: ReactNode;
}
export function Modal({
  title,
  description,
  open,
  onClose,
  children,
}: ModalProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      transition
      className="fixed inset-0 z-10 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0"
    >
      <DialogBackdrop className="fixed inset-0 bg-black/30" />

      <DialogPanel className="z-50 max-h-[600px] space-y-5 overflow-auto rounded bg-white p-8">
        <DialogTitle className="font-semibold">{title}</DialogTitle>
        {description && <Description>{description}</Description>}
        {children}
      </DialogPanel>
    </Dialog>
  );
}
