import { useQuery } from "@tanstack/react-query";

import { TFiling } from "../../api/filings";
import { Link } from "react-router-dom";
import { ID } from "../../types/id";

interface FilingBreadcrumbProps {
  id: ID;
  asLink: boolean;
}
export function FilingBreadcrumb({ id, asLink }: FilingBreadcrumbProps) {
  const { data } = useQuery<TFiling>({ queryKey: [`filings/${id}`] });

  if (asLink) {
    return (
      <Link
        to={`/filings/${data?.id}`}
        className="hover:underline"
        key="filings_link"
      >
        {data?.name}
      </Link>
    );
  }
  return <span className="text-gray-500">{data?.name}</span>;
}
