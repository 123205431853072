import { useState } from 'react';
import { isAxiosError } from "axios";
import { Button } from "../../_common/components/Button";
import { useDispatchErrorToast } from "../../_common/components/Toasts/context";
import { TFiling } from "../../_common/api/filings";
import { previewEdgar } from '../../_common/api/preview_edgar';

interface PreviewEDGARProps {
  filing: TFiling;
  disabled: boolean;
}

export function PreviewEDGAR({ filing, disabled }: PreviewEDGARProps) {
  const dispatchError = useDispatchErrorToast();
  const [previewingEDGAR, setPreviewingEDGAR] = useState(false);

   async function handlePreviewEDGAR() {
    setPreviewingEDGAR(true);
    try {
      const url = await previewEdgar(filing.id);
      window.open(url, '_blank');
    } catch (err) {
      if (isAxiosError(err)) {
        dispatchError(err.message);
      } else {
        dispatchError('An error occurred while generating the EDGAR preview');
      }
    } finally {
      setPreviewingEDGAR(false);
    }
  }

  return (
    <Button
      variant="default"
      color="blue"
      onClick={handlePreviewEDGAR}
      loading={previewingEDGAR}
      disabled={disabled}
    >
      Preview in EDGAR
    </Button>
  );
}
