import { useDocument } from "../../../Document/hooks/useDocument";
import { ID } from "../../types/id";

interface DocumentBreadcrumbProps {
  filingId: ID
  id: ID
}
export function DocumentBreadcrumb({ filingId, id }: DocumentBreadcrumbProps) {
  const document = useDocument(filingId, id);

  return (
    <span className="text-gray-500">
      {/* @ts-expect-error - metadata is untyped */}
      {document?.metadata?.name || "..."}
    </span>
  );
}
