import { useQuery } from "@tanstack/react-query";

import { ID } from "../../_common/types/id";
import { filingQueryKey, TFiling } from "../../_common/api/filings";

export function useFiling(filingId: ID, staleTime?: number) {
  const { data } = useQuery<TFiling>({
    queryKey: [filingQueryKey(filingId)],
    staleTime,
  });

  return data;
}
