import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";
import {
  PencilIcon,
  TrashIcon,
  ArrowsUpDownIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";

import { TEditableBlock } from "../../../_common/api/constructedDocument";
import { RichTextEditor } from "./RichTextEditor/RichTextEditor";
import { updateEditableBlock } from "../../../_common/api/editableBlock";
import { useDocumentBuilderContext } from "../DocumentBuilder/context";
import { isAxiosError } from "axios";
import { useDispatchErrorToast } from "../../../_common/components/Toasts/context";
import { EditableText } from "../../../_common/components/EditableText";

interface EditableBlockProps {
  editableBlock: TEditableBlock;
  onDelete: () => void;
}
export function EditableBlock({ editableBlock, onDelete }: EditableBlockProps) {
  const [editing, setEditing] = useState(false);
  const { filing, document } = useDocumentBuilderContext();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting,
  } = useSortable({ id: editableBlock.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const dispatchErrorToast = useDispatchErrorToast();

  async function handleNewTitle(title: string) {
    if (!filing || !document) return;

    try {
      await updateEditableBlock(filing.id, document.id, editableBlock.id, {
        title,
      });
    } catch (err) {
      if (isAxiosError(err)) {
        dispatchErrorToast(err.message, 3000);
      }
    }
  }

  async function handleNewContent(content: string) {
    if (!filing || !document) return;

    try {
      await updateEditableBlock(filing.id, document.id, editableBlock.id, {
        content,
      });
    } catch (err) {
      if (isAxiosError(err)) {
        dispatchErrorToast(err.message, 3000);
      }
    }
  }

  return (
    <div
      className={clsx("space-y touch-none rounded-md bg-white p-2", {
        "border border-gray-300": isSorting,
        "border-dashed": isSorting && !isDragging,
      })}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className="group flex w-full items-center gap-2" data-no-dnd="true">
        <div className="flex-1" style={{ fontFamily: document?.font_set.title }}>
          <EditableText
            className="flex-1 text-sm font-semibold"
            valueHandlerOnBlur={handleNewTitle}
            content={editableBlock.title}
          />
        </div>

        <div
          className="cursor-grab rounded-md p-1 text-gray-500 opacity-0 hover:bg-gray-500 hover:text-white group-hover:opacity-100"
          data-no-dnd="false"
        >
          <ArrowsUpDownIcon className="size-4" />
        </div>
        <button
          onClick={() => setEditing((v) => !v)}
          className={clsx("cursor-pointer rounded-md p-1", {
            "text-gray-500 opacity-0 hover:bg-gray-500 hover:text-white group-hover:opacity-100":
              !editing,
            "bg-gray-500 text-white hover:bg-gray-400": editing,
          })}
        >
          <PencilIcon className="size-4" />
        </button>

        <button
          className="cursor-pointer rounded-md p-1 text-red-500 opacity-0 hover:bg-red-500 hover:text-white group-hover:opacity-100"
          onClick={onDelete}
        >
          <TrashIcon className="size-4" />
        </button>
      </div>

      <div data-no-dnd="true">
        {editing ? (
          <RichTextEditor
            initialContent={editableBlock.content || ""}
            onContentUpdate={handleNewContent}
          />
        ) : (
          <div
            className="rich_text no-twp text-sm"
            dangerouslySetInnerHTML={{ __html: editableBlock.content || "" }}
            onDoubleClick={() => setEditing(true)}
            style={{ fontFamily: document?.font_set.body }}
          />
        )}
      </div>
    </div>
  );
}
