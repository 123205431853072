import { components } from "../../../generated/types";
import { ID } from "../types/id";

import { axios, queryClient } from "./client";
import { TUser } from "./user";

export type TEntity = components["schemas"]["Entity"];
export type TEntitySecData = components["schemas"]["SecData"];
export type TSecurityType = components["schemas"]["SecurityType"];

export interface CreateEntityParams {
  name: string;
  cik: string;
  ccc: string;
  users: { email: string }[];
}
export async function createEntity(params: CreateEntityParams) {
  const { data } = await axios.post("/entities/", {
    name: params.name,
    cik: params.cik,
    ccc: params.ccc,
    user_emails: params.users.map((i) => i.email),
  });

  _updateEntityInCache(data);
}
export interface EntityUpdateParams {
  name?: string;
  cik?: string;
  ccc?: string;
  logo?: File | null;
  sec_data?: Partial<TEntitySecData>;
  securities?: TSecurityType[];
}

export async function updateEntity(id: ID, params: EntityUpdateParams) {
  // Use multipart/form-data for logo
  let payload: EntityUpdateParams | FormData = params;
  if (params.logo) {
    payload = new FormData();

    Object.keys(params).forEach((k) => {
      const key = k as keyof EntityUpdateParams;
      if (params[key] == undefined) return;
      if (key == "sec_data" || key == "securities") {
        (payload as FormData).append(k, JSON.stringify(params[key]));
      } else {
        (payload as FormData).append(k, params[key] || "");
      }
    });
  }

  const { data } = await axios.patch(`/entities/${id}/`, payload);
  _updateEntityInCache(data);
  return data;
}

function _updateEntityInCache(data: TEntity) {
  const existingUserData = queryClient.getQueryData<TUser>(["users/me"]);
  if (!existingUserData) return;

  let preExisting = false;

  const updatedUserData: TUser = {
    ...existingUserData,
    entity_set: existingUserData.entity_set.reduce((acc, curr) => {
      if (curr.id === data.id) {
        preExisting = true;
        acc.push(data);
      } else {
        acc.push(curr);
      }
      return acc;
    }, [] as TEntity[]),
  };
  if (!preExisting) {
    existingUserData.entity_set.push(data);
  }

  queryClient.setQueryData<TUser>(["users/me"], updatedUserData);
}

export async function getEntitySecData(id: ID) {
  const data = await queryClient.fetchQuery<TEntitySecData>({
    queryKey: [`entities/${id}/sec-data`],
  });
  return data;
}
