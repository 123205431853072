import { isAxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

import { TFiling } from "../../_common/api/filings";
import { submitFiling } from "../../_common/api/submission";
import { Button } from "../../_common/components/Button";
import { useDispatchErrorToast } from "../../_common/components/Toasts/context";
import { SubmitFilingConfirmationGraphic } from "./SubmitFilingConfirmationGraphic";

interface SubmitFilingProps {
  filing: TFiling;
  disabled: boolean;
}

export function SubmitFiling({ filing, disabled }: SubmitFilingProps) {
  const dispatchError = useDispatchErrorToast();
  const [submittingFiling, setSubmittingFiling] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const navigate = useNavigate();

  async function handleSubmitFiling() {
    setSubmittingFiling(true);
    setConfirmationOpen(false);
    try {
      await submitFiling(filing.id);

      navigate("submission");
    } catch (err) {
      if (isAxiosError(err)) {
        dispatchError(err.message);
      }
    } finally {
      setSubmittingFiling(false);
    }
  }
  return (
    <>
      <Button
        variant="default"
        color="blue"
        onClick={() => setConfirmationOpen(true)}
        loading={submittingFiling}
        disabled={disabled}
      >
        Submit Filing
      </Button>
      <Dialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        transition
        className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4 transition duration-300 ease-out data-[closed]:opacity-0"
      >
        <DialogBackdrop className="fixed inset-0 bg-black/30" />

        <DialogPanel className="bg-white p-8 z-50 rounded text-center flex flex-col gap-3 items-center">
          <SubmitFilingConfirmationGraphic />
          <DialogTitle className="text-xl font-bold">Submit Filing</DialogTitle>
          <Description className="text-gray-500">
            Are you sure you want to submit this filing?
          </Description>

          <div className="flex justify-end gap-2 w-full">
            <Button
              variant="default"
              color="gray"
              onClick={() => setConfirmationOpen(false)}
              className="flex-1"
            >
              Cancel
            </Button>
            <Button
              variant="default"
              color="blue"
              onClick={handleSubmitFiling}
              className="flex-1"
            >
              Submit
            </Button>
          </div>
        </DialogPanel>
      </Dialog>
    </>
  );
}
