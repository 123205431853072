import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

export function useExhibitTypeOptions() {
  const { data } = useQuery<string[]>({
    queryKey: ["exhibit_types"],
    staleTime: Infinity,
  });

  return useMemo(
    () => data?.map((o) => ({ label: exhibitTypeDisplay(o), value: o })) || [],
    [data]
  );
}
function exhibitTypeDisplay(o: string) {
  if (o.startsWith("EX-")) {
    return o.slice(3);
  }
  return o;
}
