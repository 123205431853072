import { useCallback, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import clsx from "clsx";

import { SpinningGlobe } from "./WrappedHome/SpinningGlobe";

import bloom from "./WrappedHome/bloom.png";
import { queryClient } from "../../_common/api/client";
import { getQueryKey } from "../queries/wrappedData";
import { Spinner } from "../../_common/components/Spinner";
import { useHotkeys } from "react-hotkeys-hook";
import { useTrack } from "../../_common/utils/tracking";
import { GradientText } from "./GradientText/GradientText";

export function WrappedHome() {
  const [started, setStarted] = useState(false);
  const { year = "2024" } = useParams<{ year: string }>();
  const [params] = useSearchParams()
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      year: year,
      ticker: params.get("ticker") || "",
    },
  });

  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (vals: WrappedFormValues) => {
      await queryClient.prefetchQuery({
        queryKey: getQueryKey(year, vals.ticker),
      });
      navigate(vals.ticker);
    },
    [navigate, year],
  );

  const isValid = formState.isValid;
  const isSubmitting = formState.isSubmitting;

  useHotkeys("esc", () => setStarted(false));

  useTrack({ name: "wrapped_landing_viewed" })

  return (
    <div
      className={clsx("h-dvh w-screen font-instagram transition-all bg-[#EEEFE7]")}
    >
      <div className="relative flex size-full items-center justify-center overflow-hidden p-4 text-center">
        {/* Globe */}
        <div
          className={clsx(
            "absolute top-1/2 -translate-y-1/2 transition-all duration-1000",
          )}
          style={{
            transform: !started ? undefined : "translateY(1200px)",
          }}
        >
          <SpinningGlobe />
        </div>

        {/* Landing screen */}
        <div
          className={clsx(
            "absolute top-1/2 m-auto max-w-5xl -translate-y-1/2 space-y-14 px-2 transition-all duration-1000",
          )}
          style={{
            transform: !started ? undefined : "translateY(2500px)",
          }}
        >
          <div className="space-y-4">
            <h3 className="text-4xl font-semibold md:text-[80px] leading-[1]">
              Fyler Wrapped {year}
            </h3>

            <p className="text-xl md:text-[40px] leading-[1]">
              We prepared a year-in-review for you here at Fyler to celebrate the
              end of {year}!
            </p>
          </div>

          <button
            className="rounded bg-gradient-to-br from-[#E2D036] to-[#5EB6A7] px-8 py-4 text-[32px] leading-[1] text-black hover:animate-gradient"
            onClick={() => setStarted(true)}
          >
            Get Started
          </button>
        </div>

        {/* Bottom Bloom */}
        <img className="absolute bottom-0 w-full transition-all duration-300" src={bloom}
          style={{
            transform: started ? "translateY(75%)" : "translateY(2000%)",
          }}
        />

        {/* Bottom Text */}
        <div
          className={clsx(
            "pointer-events-none absolute bottom-0 w-full translate-y-1/2 text-[180px] text-white/30 transition-all duration-1000 md:text-[280px]",
          )}
          style={{
            transform: started ? undefined : "translateY(2500px)",
          }}
        >
          {year}
        </div>


        {/* Ticker Form */}
        <div
          className={clsx(
            "absolute top-1/2 m-auto max-w-2xl -translate-y-1/2 space-y-12 px-2 transition-all duration-1000",
          )}
          style={{
            transform: started ? undefined : "translateY(-2500px)",
          }}
        >
          <h3 className="text-3xl font-semibold uppercase md:text-5xl">
            Welcome to your{" "}
            <GradientText>
              Fyler wrapped!
            </GradientText>
          </h3>

          <form
            className="relative m-auto w-[28ch] text-xl md:text-2xl"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              className="w-full border-b border-[#DBD0BC] bg-transparent outline-none placeholder-black/30"
              placeholder="ENTER YOUR TICKER (AAPL)"
              {...register("ticker")}
            />
            <button
              type="submit"
              disabled={!isValid || isSubmitting}
              className="absolute right-0 top-1/2 -translate-y-1/2"
            >
              {!isSubmitting ? (
                <svg
                  width="34"
                  height="28"
                  viewBox="0 0 34 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="size-5"
                >
                  <path
                    d="M19.1735 25.84C19.1735 23.488 19.6535 21.496 20.6135 19.864C21.6215 18.232 23.0375 17.128 24.8615 16.552H0.7415V11.368H24.8615C23.0375 10.792 21.6215 9.688 20.6135 8.056C19.6535 6.424 19.1735 4.432 19.1735 2.08V0.0639992H25.2215V1.432C25.2215 4.504 25.8455 6.952 27.0935 8.776C28.3895 10.6 30.1895 11.512 32.4935 11.512H33.2855V16.408H32.4935C30.1895 16.408 28.3895 17.32 27.0935 19.144C25.8455 20.968 25.2215 23.416 25.2215 26.488V27.856H19.1735V25.84Z"
                    fill="black"
                  />
                </svg>
              ) : (
                <Spinner className="size-5" color="gray" />
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

interface WrappedFormValues {
  year: string;
  ticker: string;
}
