import { TFiling } from "../../_common/api/filings";
import { ID } from "../../_common/types/id";
import { useFiling } from "../../Filing/hooks/useFiling";

export function useConstructedDocument(
  filingId: ID,
  documentId: ID,
  staleTime?: number
) {
  const dId = parseInt(`${documentId}`);
  const filing = useFiling(filingId, staleTime);

  const document = filing
    ? getConstructedDocumentFromFiling(filing, dId)
    : undefined;

  return { filing, document };
}

export function getConstructedDocumentFromFiling(
  filing: TFiling,
  documentId: ID
) {
  return filing?.constructed_primary_documents.find((d) => d.id === documentId);
}
