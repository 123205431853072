import { useQuery } from "@tanstack/react-query";

import { filingQueryKey, TFiling } from "../../_common/api/filings";
import { ID } from "../../_common/types/id";

export function useDocument(filingId: ID, documentId: ID, staleTime?: number) {
  const fId = parseInt(`${filingId}`);
  const dId = parseInt(`${documentId}`);

  const { data } = useQuery<TFiling>({
    queryKey: [filingQueryKey(fId)],
    staleTime,
  });
  return data?.documents.find((d) => d.id === dId);
}
