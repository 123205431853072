import clsx from "clsx";
import { Textarea as HTextarea, TextareaProps } from "@headlessui/react";
import { forwardRef } from "react";

export const Textarea = forwardRef<HTMLInputElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <HTextarea
        {...props}
        ref={ref}
        className={clsx(
          "outline outline-1 outline-gray-300 rounded p-2 text-sm disabled:text-gray-400",
          className
        )}
      />
    );
  }
);
