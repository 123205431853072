import "./document_html_viewer.scss";
import { TDocument } from "../../../_common/api/filings";
import { useViewerClickHandler } from "../../hooks/useViewerClickHandler";
import { useDebouncedDocumentSave } from "../../hooks/useDebouncedDocumentSave";
import { useRef } from "react";

interface DocumentHtmlViewerProps {
  document: TDocument;
}
export function DocumentHtmlViewer({ document }: DocumentHtmlViewerProps) {
  const saveDocument = useDebouncedDocumentSave();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const onClick = useViewerClickHandler({
    handlers: [
      {
        shouldFireFn: (el) => {
          return el.getAttribute("data-davis-annotated") === "davis-ballot";
        },
        callback: (el) => {
          el.textContent = el.textContent === "☑" ? "☐" : "☑";

          saveDocument(document.id, {
            parsed_html: wrapperRef.current?.innerHTML,
          });
        },
      },
    ],
  });

  return (
    <div
      ref={wrapperRef}
      dangerouslySetInnerHTML={{ __html: document.parsed_html || "" }}
      className="bg-white rounded-lg p-12 shadow-lg no-twp fyler_document_viewer"
      onClick={onClick}
    ></div>
  );
}
