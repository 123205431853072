import { useMemo, useState } from 'react';
import { useForm } from "react-hook-form";
import { useQuery } from '@tanstack/react-query';
import { Table } from '../_common/components/Table/Table';
import { FullscreenLoader } from '../_common/components/FullscreenLoader';
import { Input } from '../_common/components/Input';
import { Button } from "../_common/components/Button";
import { getFiledExhibits, TFiledExhibit } from '../_common/api/filed_exhibits';
import { Field } from "@headlessui/react";
import { TableActionConfig, TableHeaderConfig } from '../_common/components/Table/types';

export function FiledExhibits() {
  const [searchTerm, setSearchTerm] = useState("");
  const { handleSubmit, register, formState: { isSubmitting, isValid } } = useForm({
    defaultValues: {
      searchTerm: ""
    }
  });

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['filedExhibits', searchTerm],
    queryFn: () => getFiledExhibits(searchTerm),
    enabled: true,
  });

  const onSubmit = (formData: { searchTerm: string }) => {
    setSearchTerm(formData.searchTerm);
    refetch();
  };

  const filedExhibitsHeadersConfig = useMemo<TableHeaderConfig<TFiledExhibit>[]>(() => [
    {
      label: 'View Exhibit',
      getStringContent: () => "",
      renderCellContent: (exhibit: TFiledExhibit) => (
        <a href={exhibit.exhibit_link} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
          View Exhibit
        </a>
      ),
      hasTooltip: false,
    },
    {
      label: 'Exhibit Type',
      getStringContent: (exhibit: TFiledExhibit) => exhibit.exhibit_type,
      hasTooltip: false,
    }, {
      label: 'Filing Type',
      getStringContent: (exhibit: TFiledExhibit) => exhibit.primary_document_type,
      hasTooltip: false,
    },
    {
      label: 'Exhibit EDGAR Description',
      getStringContent: (exhibit: TFiledExhibit) => exhibit.edgar_description || "-",
      hasTooltip: false,
    },
    {
      label: 'Date Filed',
      getStringContent: (exhibit: TFiledExhibit) => new Date(exhibit.filed_at).toLocaleDateString(),
      hasTooltip: false,
    },
    {
      label: 'View Filing',
      getStringContent: () => "",
      renderCellContent: (exhibit: TFiledExhibit) => (
        <a href={exhibit.primary_document_link} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
          View Filing
        </a>
      ),
      hasTooltip: false,
    },
  ], []);

  if (isLoading) return <FullscreenLoader />;

  if (error) return <div>An error occurred: {(error as Error).message}</div>;

  const exhibitActions: TableActionConfig<TFiledExhibit>[] = [
    {
      label: "Copy Exhibit Link",
      callback: (doc) => {
        navigator.clipboard.writeText(doc.exhibit_link).catch((err) => {
          console.error('Failed to copy: ', err);
        });
      },
    },
    {
      label: "Copy Filing Link",
      callback: (doc) => {
        navigator.clipboard.writeText(doc.primary_document_link).catch((err) => {
          console.error('Failed to copy: ', err);
        });
      },
    },
  ];

  return (
    <div className="space-y-6">
      <h3 className="font-semibold text-xl">Search Exhibits</h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Field className="flex flex-col gap-2">
          <div className="flex space-x-2">
            <Input
              {...register("searchTerm")}
              type="text"
              placeholder="Ninth Credit Agreement"
              className="max-w-sm"
            />
            <Button
              variant="default"
              color="blue"
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
            >
              Search
            </Button>
          </div>
        </Field>
      </form>

      {data && data.results && data.results.length > 0 ? (
        <Table
          data={data.results}
          headers={filedExhibitsHeadersConfig}
          rowKey="id"
          actions={exhibitActions}
          emptyState={<p>No results found</p>}
        />
      ) : (
        <p>{searchTerm ? 'No filed exhibits found.' : 'Search for exhibits to display results.'}</p>
      )}
    </div>
  );
}
