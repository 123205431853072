import { useQuery } from "@tanstack/react-query";
import { buildLookup } from "../../_common/utils/list";
import {
  ArrowsUpDownIcon,
  CheckBadgeIcon,
  ClockIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { Status101Enum } from "../../../generated/types";
import { DelimitedNumericArrayParam, useQueryParams } from "use-query-params";
import queryString from "query-string";

interface FilingStat {
  status: string;
  count: number;
}
export function FilingStats() {
  const [params] = useQueryParams({ entity_id: DelimitedNumericArrayParam });
  const { data = [] } = useQuery<FilingStat[]>({
    queryKey: [
      "filings/filing_status_breakdown",
      `?${queryString.stringify(params)}`,
    ],
  });
  const lookup = buildLookup(data, (d) => d.status);
  return (
    <div className="flex items-center gap-4">
      <div className="flex-1 rounded border bg-white p-4">
        <div className="flex items-center justify-between">
          <div>Pending Filings</div>

          <ArrowsUpDownIcon className="size-6 text-blue-500" />
        </div>

        <div className="text-lg">{lookup[Status101Enum.draft]?.count || 0}</div>
      </div>
      <div className="flex-1 rounded border bg-white p-4">
        <div className="flex items-center justify-between">
          <div>In Review</div>

          <UserIcon className="size-6 text-yellow-500" />
        </div>

        <div className="text-lg">
          {lookup[Status101Enum.in_review]?.count || 0}
        </div>
      </div>
      <div className="flex-1 rounded border bg-white p-4">
        <div className="flex items-center justify-between">
          <div>Scheduled</div>

          <ClockIcon className="size-6 text-purple-500" />
        </div>

        <div className="text-lg">
          {lookup[Status101Enum.scheduled]?.count || 0}
        </div>
      </div>
      <div className="flex-1 rounded border bg-white p-4">
        <div className="flex items-center justify-between">
          <div>Filed</div>

          <CheckBadgeIcon className="size-6 text-green-500" />
        </div>

        <div className="text-lg">{lookup[Status101Enum.filed]?.count || 0}</div>
      </div>
    </div>
  );
}
