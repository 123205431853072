import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { TUser } from "../_common/api/user";
import { Input } from "../_common/components/Input";
import { Button } from "../_common/components/Button";
import { Card } from "../_common/components/Card";
import { changePassword } from "../_common/api/auth";
import {
  useDispatchErrorToast,
  useDispatchInfoToast,
} from "../_common/components/Toasts/context";
import { AxiosError } from "axios";

export function Profile() {
  const { data } = useQuery<TUser>({ queryKey: ["users/me"] });
  const { register, handleSubmit, watch, formState, reset } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
  });

  const dispatchErrorToast = useDispatchErrorToast();
  const dispatchInfoToast = useDispatchInfoToast();

  const onSubmit = handleSubmit(async (data) => {
    try {
      await changePassword(data);
      dispatchInfoToast("Password changed successfully", 2000);
      reset();
    } catch (e) {
      if (e instanceof AxiosError) {
        dispatchErrorToast(e.response?.data.error, 2000);
      }
    }
  });

  const oldPassword = watch("oldPassword");
  const newPassword = watch("newPassword");

  return (
    <div className="space-y-3">
      <h2 className="font-semibold text-lg">Profile</h2>
      <Card className="p-3">
        <dl className="grid grid-cols-2 text-sm gap-2">
          <dt className="font-medium ">Username</dt>
          <dd className="text-gray-500">{data?.username}</dd>

          <dt className="font-medium">Email</dt>
          <dd className="text-gray-500">{data?.email}</dd>
        </dl>

        <form className="space-y-2 pt-2 border-t mt-2">
          <h4 className="font-medium">Change Password</h4>

          <div className="grid grid-cols-2 gap-2 items-center text-sm">
            <label htmlFor="oldPassword" className="font-medium">
              Old Password
            </label>
            <Input
              type="password"
              {...register("oldPassword", { required: true })}
            />

            <label htmlFor="newPassword" className="font-medium">
              New Password
            </label>
            <Input
              type="password"
              {...register("newPassword", {
                required: true,
                validate: (value) => {
                  if (value === oldPassword) {
                    return "New password cannot be the same as the old password";
                  }
                },
              })}
            />

            <label htmlFor="newPasswordConfirmation" className="font-medium">
              Confirm New Password
            </label>
            <Input
              type="password"
              {...register("newPasswordConfirmation", {
                required: true,
                validate: (value) => {
                  if (newPassword !== value) {
                    return "Passwords do not match";
                  }
                },
              })}
            />
          </div>
          <div className="flex justify-end">
            <Button
              variant="default"
              color="blue"
              className="text-xs"
              onClick={onSubmit}
              disabled={!formState.isValid}
              loading={formState.isSubmitting}
            >
              Change Password
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
}
