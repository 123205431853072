import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  StubbedDerivativeTransaction,
  StubbedNonDerivativeTransaction,
  useStubbedData,
} from "../../hooks/useStubbedData";
import { Select } from "../../../_common/components/Select";
import { Field } from "@headlessui/react";
import { Label } from "../../../_common/components/Label";
import { Table } from "../../../_common/components/Table/Table";
import { buildLookup } from "../../../_common/utils/list";
import { useEffect } from "react";
import { Button } from "../../../_common/components/Button";

export function NewOwnershipForm() {
  const {
    addFiling,
    reportingOwners,
    derivativeTransactions,
    nonDerivativeTransactions,
  } = useStubbedData();
  const { register, handleSubmit, watch, setValue } =
    useForm<NewOwnershipFormValues>({
      defaultValues: {
        reportingOwnerId: -1,
        derivativeTransactions: [],
        nonDerivativeTransactions: [],
      },
    });

  const reportingOwnerLookup = buildLookup(reportingOwners, (k) => k.id);

  const navigate = useNavigate();
  function onSubmit(v: NewOwnershipFormValues) {
    const createdId = addFiling(
      v.reportingOwnerId,
      v.derivativeTransactions.map((t) => t.id),
      v.nonDerivativeTransactions.map((t) => t.id)
    );

    navigate(`/section-16/${createdId}`);
  }

  const reportingOwnerId = watch("reportingOwnerId");

  useEffect(() => {
    setValue("nonDerivativeTransactions", []);
    setValue("derivativeTransactions", []);
  }, [reportingOwnerId, setValue]);

  const reportingOwnerDerivativeTransactions = derivativeTransactions.filter(
    (f) => f.reportingOwnerId === parseInt(String(reportingOwnerId))
  );

  const reportingOwnerNonDerivativeTransactions =
    nonDerivativeTransactions.filter(
      (f) => f.reportingOwnerId === parseInt(String(reportingOwnerId))
    );

  return (
    <div className="p-4">
      <h2 className="font-semibold text-lg">Create Report</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <Field className="flex flex-col gap-2">
          <Label>Reporting Owner</Label>
          <Select {...register("reportingOwnerId", { valueAsNumber: true })}>
            <option disabled value="">
              Select Reporting Owner
            </option>
            {reportingOwners.map((ro) => (
              <option value={ro.id} key={ro.id}>
                {ro.reporting_owner_name}
              </option>
            ))}
          </Select>
        </Field>

        <Field className="flex flex-col gap-2">
          <Label>Select derivative transactions</Label>
          <Table
            data={reportingOwnerDerivativeTransactions}
            rowKey="id"
            emptyState={
              <div className="pl-7 py-2 text-gray-500 whitespace-nowrap">
                No unreported transactions
              </div>
            }
            actions={[]}
            onSelectedItems={(v) => setValue("derivativeTransactions", v)}
            headers={[
              {
                label: "Owner",
                getStringContent: (tra) =>
                  reportingOwnerLookup[tra.reportingOwnerId]
                    .reporting_owner_name,
                hasTooltip: false,
              },
              {
                label: "Number of shares",
                getStringContent: (tra) =>
                  tra.number_of_derivative_securities_acquired ||
                  tra.number_of_derivative_securities_disposed,
                hasTooltip: false,
              },
              {
                label: "Security",
                getStringContent: (tra) => tra.security_title,
                hasTooltip: false,
              },
              {
                label: "Execution Date",
                getStringContent: (tra) => tra.transaction_date,
                hasTooltip: false,
              },
            ]}
          />
        </Field>

        <Field className="flex flex-col gap-2">
          <Label>Select non-derivative transactions</Label>
          <Table
            data={reportingOwnerNonDerivativeTransactions}
            rowKey="id"
            emptyState={
              <div className="pl-7 py-2 text-gray-500 whitespace-nowrap">
                No unreported transactions
              </div>
            }
            actions={[]}
            onSelectedItems={(v) => setValue("nonDerivativeTransactions", v)}
            headers={[
              {
                label: "Owner",
                getStringContent: (tra) =>
                  reportingOwnerLookup[tra.reportingOwnerId]
                    .reporting_owner_name,
                hasTooltip: false,
              },
              {
                label: "Number of shares",
                getStringContent: (tra) => tra.transaction_amount,
                hasTooltip: false,
              },
              {
                label: "Security",
                getStringContent: (tra) => tra.security_title,
                hasTooltip: false,
              },
              {
                label: "Execution Date",
                getStringContent: (tra) => tra.transaction_date,
                hasTooltip: false,
              },
            ]}
          />
        </Field>

        <div className="flex justify-end">
          <Button variant="default" color="blue" type="submit">
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}

interface NewOwnershipFormValues {
  reportingOwnerId: number;
  derivativeTransactions: StubbedDerivativeTransaction[];
  nonDerivativeTransactions: StubbedNonDerivativeTransaction[];
}
