import { axios } from "./client";

export async function logout() {
  axios.post("logout/", undefined, { baseURL: "/" });
  window.location.replace("/login/");
}

export async function changePassword(data: {
  oldPassword: string;
  newPassword: string;
}) {
  const response = await axios.post("change-password/", {
    old_password: data.oldPassword,
    new_password: data.newPassword,
  });
  return response.data;
}
