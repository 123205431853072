import { components } from "../../../generated/types";
import { ID } from "../types/id";
import { axios, queryClient } from "./client";
import { filingQueryKey, TFiling } from "./filings";

export type TSubmission = components["schemas"]["EdgarSubmission"];
export async function submitFiling(filingId: ID) {
  const { data } = await axios.post<TSubmission>(
    `filings/${filingId}/submission/`
  );

  const existingQueryData = queryClient.getQueryData<TFiling>([
    filingQueryKey(filingId),
  ]);
  if (existingQueryData) {
    queryClient.setQueryData<TFiling>([filingQueryKey(filingId)], {
      ...existingQueryData,
      latest_submission: data,
    });
  }

  return data;
}
