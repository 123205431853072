export function SubmitFilingConfirmationGraphic() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="size-24"
    >
      <defs>
        <linearGradient
          id="paint0_linear_58_1119"
          x1="98.4747"
          y1="3.52525"
          x2="4.47475"
          y2="97.5253"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA500" />
          <stop offset="1" stopColor="#32AAE3" />
        </linearGradient>
      </defs>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="url(#paint0_linear_58_1119)"
        d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
      />
    </svg>
  );
}
