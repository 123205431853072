import { Field, Input, InputProps, Label } from "@headlessui/react";
import clsx from "clsx";
import { forwardRef } from "react";

interface RadioPillProps extends InputProps {
  label: string;
  color: "yellow" | "blue" | "green" | "purple";
}
export const RadioPill = forwardRef<HTMLInputElement, RadioPillProps>(
  ({ label, color, ...rest }, ref) => {
    return (
      <Field>
        <Input
          type="radio"
          {...rest}
          className="peer sr-only hidden"
          ref={ref}
        />
        <Label
          className={clsx(
            "cursor-pointer select-none rounded border p-2 text-sm transition-colors duration-100",
            {
              "hover:bg-blue-200 peer-checked:border-blue-300 peer-checked:bg-blue-300 peer-checked:text-blue-600":
                color === "blue",
              "hover:bg-green-200 peer-checked:border-green-300 peer-checked:bg-green-300 peer-checked:text-green-600":
                color === "green",
              "hover:bg-purple-200 peer-checked:border-purple-300 peer-checked:bg-purple-300 peer-checked:text-purple-600":
                color === "purple",
              "hover:bg-yellow-200 peer-checked:border-yellow-300 peer-checked:bg-yellow-300 peer-checked:text-yellow-600":
                color === "yellow",
            },
          )}
        >
          {label}
        </Label>
      </Field>
    );
  },
);
