import { useForm } from "react-hook-form";
import { Input } from "../../_common/components/Input";
import { Button } from "../../_common/components/Button";
import { Label } from "../../_common/components/Label";
import { Field } from "@headlessui/react";
import { useCallback } from "react";
import { getSecFilingsDump } from "../api/getSecFilingsDump";

export function SecFilingsDump() {
  const { handleSubmit, register, formState } =
    useForm<SecFilingsDumpFormValues>();

  const onSubmit = useCallback(async (v: SecFilingsDumpFormValues) => {
    await getSecFilingsDump(v.accessionNumber);
  }, []);

  const isLoading = formState.isSubmitting;
  const isValid = formState.isValid;

  return (
    <div>
      <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
        <Field className="flex flex-col gap-2">
          <Label>Accession Number</Label>
          <Input {...register("accessionNumber")} />
        </Field>
        <Button
          type="submit"
          variant="default"
          color="blue"
          loading={isLoading}
          disabled={!isValid}
        >
          Download Filing
        </Button>
      </form>
    </div>
  );
}

interface SecFilingsDumpFormValues {
  accessionNumber: string;
}
