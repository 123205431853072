import { useState } from 'react';
import { isAxiosError } from "axios";
import { Button } from "../../_common/components/Button";
import { useDispatchErrorToast } from "../../_common/components/Toasts/context";
import { TFiling, updateFiling } from "../../_common/api/filings";
import { Status101Enum } from '../../../generated/types';

interface SubmitForReview {
  filing: TFiling;
  disabled: boolean;
}

export function SubmitFilingForReview({ filing, disabled }: SubmitForReview) {
  const dispatchError = useDispatchErrorToast();
  const [submitting, setSubmittingForReview] = useState(false);

   async function submitFilingForReview() {
    setSubmittingForReview(true);
    try {
      await updateFiling(filing.id, {"status" : Status101Enum.in_review});
    } catch (err) {
      if (isAxiosError(err)) {
        dispatchError(err.message);
      } else {
        dispatchError('An error occurred while submitting filing for review');
      }
    } finally {
      setSubmittingForReview(false);
    }
  }

  return (
    <Button
      variant="default"
      color="blue"
      onClick={submitFilingForReview}
      loading={submitting}
      disabled={disabled}
    >
      Submit for Review
    </Button>
  );
}
