import {
  Bars3BottomLeftIcon,
  Bars3BottomRightIcon,
  Bars3Icon,
  BoldIcon,
  ChevronRightIcon,
  H1Icon,
  ItalicIcon,
  ListBulletIcon,
  NumberedListIcon,
  StrikethroughIcon,
  TableCellsIcon,
  UnderlineIcon,
} from "@heroicons/react/24/outline";
import { useCurrentEditor } from "@tiptap/react";
import clsx from "clsx";

export function MenuBar() {
  const { editor } = useCurrentEditor();

  const inTable = editor?.isActive("table");

  return (
    <div className="flex items-center gap-2 text-gray-500 p-2 border-b">
      <button onClick={() => editor?.chain().focus().toggleBold().run()}>
        <BoldIcon
          className={clsx("size-4", {
            "text-brandBlue": editor?.isActive("bold"),
          })}
        />
      </button>
      <button onClick={() => editor?.chain().focus().toggleItalic().run()}>
        <ItalicIcon
          className={clsx("size-4", {
            "text-brandBlue": editor?.isActive("italic"),
          })}
        />
      </button>
      <button onClick={() => editor?.chain().focus().toggleUnderline().run()}>
        <UnderlineIcon
          className={clsx("size-4", {
            "text-brandBlue": editor?.isActive("underline"),
          })}
        />
      </button>
      <button onClick={() => editor?.chain().focus().toggleStrike().run()}>
        <StrikethroughIcon
          className={clsx("size-4", {
            "text-brandBlue": editor?.isActive("strike"),
          })}
        />
      </button>

      <span>|</span>

      <button
        onClick={() => editor?.chain().focus().setTextAlign("left").run()}
      >
        <Bars3BottomLeftIcon
          className={clsx("size-4", {
            "text-brandBlue": editor?.isActive({ textAlign: "left" }),
          })}
        />
      </button>
      <button
        onClick={() => editor?.chain().focus().setTextAlign("center").run()}
      >
        <Bars3Icon
          className={clsx("size-4", {
            "text-brandBlue": editor?.isActive({ textAlign: "center" }),
          })}
        />
      </button>
      <button
        onClick={() => editor?.chain().focus().setTextAlign("right").run()}
      >
        <Bars3BottomRightIcon
          className={clsx("size-4", {
            "text-brandBlue": editor?.isActive({ textAlign: "right" }),
          })}
        />
      </button>

      <span>|</span>

      <button
        onClick={() =>
          editor?.chain().focus().toggleHeading({ level: 1 }).run()
        }
      >
        <H1Icon
          className={clsx("size-4", {
            "text-brandBlue": editor?.isActive("heading", { level: 1 }),
          })}
        />
      </button>

      <button
        onClick={() =>
          inTable
            ? editor?.chain().focus().deleteTable().run()
            : editor?.chain().focus().insertTable().run()
        }
      >
        <TableCellsIcon
          className={clsx("size-4", inTable && "text-brandBlue")}
        />
      </button>

      <span>|</span>

      <button
        onClick={() => editor?.chain().focus().toggleBulletList().run()}
        className={editor?.isActive("bulletList") ? "is-active" : ""}
      >
        <ListBulletIcon
          className={clsx("size-4", {
            "text-brandBlue": editor?.isActive("bulletList"),
          })}
        />
      </button>

      <button
        onClick={() => editor?.chain().focus().toggleOrderedList().run()}
        className={editor?.isActive("orderedList") ? "is-active" : ""}
      >
        <NumberedListIcon
          className={clsx("size-4", {
            "text-brandBlue": editor?.isActive("orderedList"),
          })}
        />
      </button>

      <span>|</span>

      <button
        onClick={() => editor?.chain().focus().toggleTextIndent().run()}
        className={editor?.isActive({ textIndent: true }) ? "is-active" : ""}
      >
        <ChevronRightIcon
          className={clsx("size-4", {
            "text-brandBlue": editor?.isActive({ textIndent: true }),
          })}
        />
      </button>

    </div>
  );
}
