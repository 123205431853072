import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { useEffect, useState } from "react";
import { ID } from "../types/id";
import { CheckCircleIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

interface MultiselectProps<T> {
  options: T[];
  defaultValue?: T[];
  onChange: (selected: T[]) => void;
  getDisplayString: (t: T) => string;
  getId: (t: T) => ID;
  placeholder: string;
}
export function Multiselect<T>({
  options,
  defaultValue,
  getDisplayString,
  getId,
  placeholder,
  onChange,
}: MultiselectProps<T>) {
  const [value, setValue] = useState(defaultValue || []);

  useEffect(() => {
    onChange(value);
  }, [onChange, value]);

  return (
    <Listbox value={value} onChange={setValue} multiple>
      <ListboxButton className="flex w-80 items-center justify-between overflow-hidden text-ellipsis whitespace-nowrap rounded border bg-white p-2">
        <span className="text-sm">
          {value.length > 0
            ? value.map(getDisplayString).join(", ")
            : placeholder}
        </span>

        <ChevronDownIcon className="size-3" />
      </ListboxButton>

      <ListboxOptions anchor="bottom" className="w-80 rounded border bg-white">
        {options.map((o) => (
          <ListboxOption
            key={getId(o)}
            value={o}
            className="group flex cursor-pointer select-none items-center justify-between p-2 text-xs data-[focus]:bg-gray-300 data-[focus]:text-white"
          >
            <span>{getDisplayString(o)}</span>

            <CheckCircleIcon className="invisible size-3 group-data-[selected]:visible" />
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
}
