import clsx from "clsx";
import { CakeIcon, PencilIcon } from "@heroicons/react/24/outline";

import { useMultistageFlowContext } from "../../../../_common/components/MultistageFlow/context";
import { AddBlockMultistageContextData } from "./types";
import { MultistageFlowNavButtons } from "../../../../_common/components/MultistageFlow/MultistageFlow";

export function StrategyStage() {
  const { contextData, setContextData, complete, next } =
    useMultistageFlowContext<AddBlockMultistageContextData>();

  function onNext() {
    if (contextData.strategy === "custom") {
      complete();
    } else {
      next();
    }
  }

  return (
    <div className="space-y-2">
      <h3 className="text-lg">How would you like to fill out this block?</h3>

      <div className="flex gap-3 text-sm">
        <button
          onClick={() => setContextData({ ...contextData, strategy: "llm" })}
          className={clsx(
            "flex flex-col gap-2 p-4 border rounded-md items-center justify-center flex-1 hover:text-brandBlue/70",
            {
              "text-gray-500": contextData.strategy !== "llm",
              "text-brandBlue border-brandBlue": contextData.strategy === "llm",
            },
          )}
        >
          <CakeIcon className="size-6" />
          <span>Use LLM generated content</span>
          <span className="text-xs">
            (You'll still be able to edit the content afterwards)
          </span>
        </button>

        <button
          onClick={() => setContextData({ ...contextData, strategy: "custom" })}
          className={clsx(
            "flex flex-col gap-2 p-4 border rounded-md items-center justify-center flex-1 hover:text-brandBlue/70",
            {
              "text-gray-500": contextData.strategy !== "custom",
              "text-brandBlue border-brandBlue":
                contextData.strategy === "custom",
            },
          )}
        >
          <PencilIcon className="size-6" />
          <span>I'll create the content myself</span>
        </button>
      </div>

      <MultistageFlowNavButtons
        currentStageValid={!!contextData.strategy}
        onNext={onNext}
      />
    </div>
  );
}
