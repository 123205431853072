import { useCallback, useEffect, useRef } from "react";

export function useDebounced<Args extends Array<unknown>, Ret>(
  cbFn: (...args: Args) => Ret,
  debounceTime: number = 2000
) {
  const debounceRef = useRef<number>();

  useEffect(() => {
    return () => {
      clearTimeout(debounceRef.current);
    };
  }, []);

  return useCallback(
    (...args: Args) => {
      clearTimeout(debounceRef.current);
      debounceRef.current = setTimeout(() => {
        cbFn(...args);
      }, debounceTime);
    },
    [cbFn, debounceTime]
  );
}
