import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useStubbedData } from "../hooks/useStubbedData";
import { useCurrentUser } from "../../_common/hooks/useCurrentUser";
import { buildLookup } from "../../_common/utils/list";
import { NonDerivativeTransactionsTable } from "./OwnershipFormPreview/NonDerivativeTransactionsTable";
import { DerivativeTransactionsTable } from "./OwnershipFormPreview/DerivativeTransactionsTable";
import { ReportingOwnerTable } from "./OwnershipFormPreview/ReportingOwnerTable";

export function OwnershipFormPreview() {
  const { id } = useParams<{ id: string }>();
  const user = useCurrentUser();
  const issuer = user?.entity_set[0];
  const state = useStubbedData();

  const derivativeTransactionLookup = useMemo(
    () => buildLookup(state.derivativeTransactions, (d) => d.id),
    [state.derivativeTransactions],
  );
  const nonDerivativeTransactionLookup = useMemo(
    () => buildLookup(state.nonDerivativeTransactions, (d) => d.id),
    [state.nonDerivativeTransactions],
  );

  const {
    reportingOwner,
    derivativeTransactions,
    nonDerivativeTransactions,
    earliestTransactionDate,
  } = useMemo(() => {
    const form = state.scheduledFilings.find(
      (f) => f.id === parseInt(id || "0"),
    );
    const derivativeTransactions = [];
    const nonDerivativeTransactions = [];
    let earliestDate = null;

    for (const transactionId of form?.derivativeTransactionIds || []) {
      const transaction = derivativeTransactionLookup[transactionId];
      if (!transaction) continue;
      derivativeTransactions.push(transaction);
      const transactionDate = new Date(transaction.transaction_date);
      if (!earliestDate || transactionDate < earliestDate) {
        earliestDate = transactionDate;
      }
    }

    for (const transactionId of form?.nonDerivativeTransactionIds || []) {
      const transaction = nonDerivativeTransactionLookup[transactionId];
      if (!transaction) continue;
      nonDerivativeTransactions.push(transaction);
      const transactionDate = new Date(transaction.transaction_date);
      if (!earliestDate || transactionDate < earliestDate) {
        earliestDate = transactionDate;
      }
    }

    const reportingOwner = state.reportingOwners.find(
      (r) => r.id === form?.reportingOwnerId,
    );

    return {
      form,
      derivativeTransactions,
      nonDerivativeTransactions,
      reportingOwner,
      earliestTransactionDate: earliestDate,
    };
  }, [
    state.scheduledFilings,
    state.reportingOwners,
    id,
    derivativeTransactionLookup,
    nonDerivativeTransactionLookup,
  ]);

  return (
    <div className="no-twp text-xs">
      <table
        style={{ width: "100%" }}
        border={0}
        cellSpacing="0"
        cellPadding="4"
      >
        <tbody>
          <tr>
            <td
              style={{ width: "20%" }}
              colSpan={2}
              valign="top"
              align="center"
              className="FormName"
            >
              FORM 4
            </td>
            <td
              rowSpan={2}
              style={{ width: "60%" }}
              valign="middle"
              align="center"
            >
              <span className="FormTitle">
                UNITED STATES SECURITIES AND EXCHANGE COMMISSION
              </span>
              <br />
              <span className="MedSmallFormText">Washington, D.C. 20549</span>
              <br />
              <br />
              <span className="FormTitle">
                STATEMENT OF CHANGES IN BENEFICIAL OWNERSHIP
              </span>
              <br />
              <br />
              <span className="MedSmallFormText">
                Filed pursuant to Section 16(a) of the Securities Exchange Act
                of 1934
              </span>
              <br />
              <span className="MedSmallFormText">
                or Section 30(h) of the Investment Company Act of 1940
              </span>
            </td>
            <td
              rowSpan={2}
              style={{ width: "20%" }}
              valign="top"
              align="center"
            >
              <table
                style={{ width: "100%" }}
                border={1}
                summary="OMB Approval Status Box"
              >
                <tbody>
                  <tr>
                    <td className="FormTextC">OMB APPROVAL</td>
                  </tr>
                  <tr>
                    <td>
                      <table
                        style={{ width: "100%" }}
                        border={0}
                        summary="OMB Interior Box"
                      >
                        <tbody>
                          <tr>
                            <td className="SmallFormText" colSpan={3}>
                              OMB Number:
                            </td>
                            <td className="SmallFormTextR">3235-0287</td>
                          </tr>
                          <tr>
                            <td className="SmallFormText" colSpan={4}>
                              Estimated average burden
                            </td>
                          </tr>
                          <tr>
                            <td className="SmallFormText" colSpan={3}>
                              hours per response:
                            </td>
                            <td className="SmallFormTextR">0.5</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr style={{ verticalAlign: "middle" }}>
            <td>
              <table
                style={{ width: "100%" }}
                border={1}
                cellPadding="0"
                cellSpacing="0"
              >
                <tbody>
                  <tr>
                    <td>&nbsp;&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className="SmallFormText">
              Check this box if no longer subject to Section 16. Form 4 or Form
              5 obligations may continue.
              <i>See</i>
              Instruction 1(b).
            </td>
          </tr>
          <tr style={{ verticalAlign: "middle" }}>
            <td>
              <table
                style={{ width: "100%" }}
                border={1}
                cellPadding="0"
                cellSpacing="0"
              >
                <tbody>
                  <tr>
                    <td>&nbsp;&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td className="SmallFormText">
              Check this box to indicate that a transaction was made pursuant to
              a contract, instruction or written plan for the purchase or sale
              of equity securities of the issuer that is intended to satisfy the
              affirmative defense conditions of Rule 10b5-1(c). See Instruction
              10.{" "}
            </td>
          </tr>
        </tbody>
      </table>

      {reportingOwner && issuer && (
        <ReportingOwnerTable
          reportingOwner={reportingOwner}
          issuer={issuer}
          earliestTransactionDate={earliestTransactionDate}
        />
      )}

      <NonDerivativeTransactionsTable
        transactions={nonDerivativeTransactions}
      />

      <DerivativeTransactionsTable transactions={derivativeTransactions} />

      {/* <table border={0} style={{width:"100%">}}
        <tbody>
          <tr>
            <td className="MedSmallFormText">
              <b>Explanation of Responses:</b>
            </td>
          </tr>
          <tr>
            <td className="FootnoteData">
              1. Represents full vesting of shares earned under a performance
              stock award granted in September 2021 under the Microsoft
              Corporation Executive Incentive Plan for the 3-year performance
              period that ended on June 30, 2024.
            </td>
          </tr>
        </tbody>
      </table> */}
    </div>
  );
}
