import { StatBlock } from "../WrappedFlow/StatBlock/StatBlock";
import { useMultistageFlowContext } from "../../../_common/components/MultistageFlow/context";
import { WrappedData } from "../../types";
import { useMemo } from "react";
import { sumList } from "../../../_common/utils/sum";
import { GradientText } from "../GradientText/GradientText";

export function Snarky() {
  const { contextData } = useMultistageFlowContext<WrappedData>();
  const hoursSpent = useMemo(() => {
    const {
      filing_agent_prepared_name_count_lookup,
      self_filed_software_count_lookup,
      total_insider_reports_filings,
    } = contextData;
    // 8 * number of 8ks (waiting on proofs etc)
    const filingAgentHours =
      sumList(Object.values(filing_agent_prepared_name_count_lookup)) * 8;

    // 4 * number of 8ks (tagging and typestting)
    const selfFiledHours =
      sumList(Object.values(self_filed_software_count_lookup)) * 4;

    return Math.floor(
      filingAgentHours + selfFiledHours + total_insider_reports_filings * 0.5,
    );
  }, [contextData]);

  const moneySpent = useMemo(() => {
    // $2500 * number of filing agent prepared 8-ks
    return (
      sumList(
        Object.values(contextData.filing_agent_prepared_name_count_lookup),
      ) * 2500
    );
  }, [contextData.filing_agent_prepared_name_count_lookup]);

  return (
    <div className="mx-auto flex h-full text-center flex-col items-center justify-between pb-16 pt-6 md:py-6">
      <div className="space-y-3 ">

        <h3 className="text-3xl uppercase md:text-5xl">
          Some things are worth automating.
        </h3>

        <p className="md:text-xl text-lg">
          <span className="underline">Fyler AI</span> ensures your filings,
          reports, and compliance are handled in seconds, not hours.
        </p>
      </div>

      <div className="space-y-4">
        <p className="text-2xl md:text-4xl">
          With{" "}
          <GradientText>
            Fyler,
          </GradientText>{" "}
          you could save up to
        </p>
        <div className="flex justify-center gap-4 md:gap-12">
          <div className="space-y-4  max-w-min">
            <StatBlock size="custom" className="size-[100px] md:size-[200px] text-2xl md:text-[42px] md:leading-[1]">
              <div>
                {hoursSpent}
                <div className="text-sm uppercase">hours</div>
              </div>
            </StatBlock>
            <div className="text-xl">
              Manually tagging, typesetting and filing your disclosures!
            </div>
          </div>
          {moneySpent > 0 && (
            <div className="space-y-4 max-w-min">
              <StatBlock size="custom" className="size-[100px] md:size-[200px] text-2xl md:text-[42px] md:leading-[1]">
                ${Math.round(moneySpent / 1000)}K
              </StatBlock>
              <div className="text-xl">
                While also saving hours of your time waiting on proofs!
              </div>
            </div>
          )}
        </div>
      </div>

      <div />
    </div>
  );
}
