import { Link, NavLink } from "react-router-dom";
import { CalculatorIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

import { FilingsIcon } from "./icons/FilingsIcon";
import { SearchIcon } from "./icons/SearchIcon";

import { logout } from "../api/auth";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { Avatar } from "./Avatar";
import { useCurrentUser } from "../hooks/useCurrentUser";

export function Sidebar() {
  const data = useCurrentUser();

  return (
    <aside className="flex h-full flex-col justify-between px-7 py-10">
      <ul className="space-y-5 text-gray-400">
        <li>
          <NavLink to="/filings">
            {({ isActive }) => (
              <FilingsIcon
                className="size-6 hover:text-brandBlue"
                active={isActive}
              />
            )}
          </NavLink>
        </li>
        <li>
          <NavLink to="/section-16">
            {({ isActive }) => (
              <CalculatorIcon
                className={clsx(
                  "size-6 hover:text-brandBlue",
                  isActive && "text-brandBlue",
                )}
              />
            )}
          </NavLink>
        </li>
        <li>
          <NavLink to="/filed_exhibits">
            {({ isActive }) => (
              <SearchIcon
                className="size-6 hover:text-brandBlue"
                active={isActive}
              />
            )}
          </NavLink>
        </li>
      </ul>

      <div className="flex items-center gap-4">
        <Menu>
          <MenuButton className="flex items-center gap-2">
            {data && <Avatar src={data.gravatar_url} />}
          </MenuButton>

          <MenuItems
            anchor="bottom"
            className="flex flex-col gap-2 rounded-lg bg-white p-4 shadow-lg"
          >
            <MenuItem>
              <Link to="/entities" className="hover:underline">
                Entities
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to="/profile" className="hover:underline">
                Profile
              </Link>
            </MenuItem>

            <MenuItem>
              <button
                type="submit"
                onClick={logout}
                className="hover:underline"
              >
                Logout
              </button>
            </MenuItem>
          </MenuItems>
        </Menu>
      </div>
    </aside>
  );
}
