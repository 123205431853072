import { QueryClientProvider } from "@tanstack/react-query";

import { queryClient } from "./features/_common/api/client";
import { Router } from "./features/_common/components/Router";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  );
}

export default App;
