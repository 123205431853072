import { Field } from "@headlessui/react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { Modal } from "../Modal/Modal";
import { FileDropZone } from "../FileDropZone";
import { Input } from "../Input";
import { Select } from "../Select";
import { Label } from "../Label";
import { FileListItem } from "./FileListItem";
import { SubmittingModal } from "./SubmittingModal";
import { ModalActions } from "../Modal/ModalActions";
import { Button } from "../Button";
import { createFiling, CreateFilingParams } from "../../api/filings";
import { useNavigate } from "react-router-dom";
import { getEstimatedDocumentProcessingTime } from "../../api/document";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { format } from "date-fns/format";
import { FullscreenLoader } from "../FullscreenLoader";

interface AddFilingModalProps {
  initialValues: CreateFilingParams;
  onClose: () => void;
  open: boolean;
}
export function AddFilingModal({
  initialValues,
  onClose,
  open,
}: AddFilingModalProps) {
  const {
    reset,
    setValue,
    control,
    watch,
    formState,
    handleSubmit,
    register,
    trigger,
  } = useForm({
    defaultValues: initialValues,
    resolver: zodResolver(addFilingModalFormSchema),
  });

  const user = useCurrentUser();

  const uploaded_primary_document = watch("uploaded_primary_document");

  function handleClose() {
    onClose();
    // Timeout to prevent modal from jumping
    setTimeout(reset, 1000);
  }

  const removeFile = () => {
    setValue("uploaded_primary_document", undefined);
  };

  const estimatedProcessingTime = uploaded_primary_document
    ? getEstimatedDocumentProcessingTime(uploaded_primary_document)
    : 0;

  const navigate = useNavigate();
  async function onSubmit(vals: CreateFilingParams) {
    const promises = [createFiling(vals)];

    promises.push(
      new Promise((res) => {
        setTimeout(res, estimatedProcessingTime);
      }),
    );

    const [filing] = await Promise.all(promises);

    handleClose();
    navigate(`/filings/${filing.id}`);
  }

  function onFiles(f: File[]) {
    setValue("uploaded_primary_document", f[0]);
    trigger();
  }

  const { isValid, isSubmitting } = formState;

  if (!user) return <FullscreenLoader />;

  return (
    <>
      <Modal
        title="Add new filing"
        open={open && !isSubmitting}
        onClose={handleClose}
      >
        <div className="w-[600px] space-y-5">
          <div className="flex gap-6">
            <Field className="flex flex-1 flex-col gap-2">
              <Label>Name</Label>
              <Input
                placeholder={`${format(new Date(), "MM/dd/yyyy")} 8-K`}
                {...register("name", {
                  setValueAs: (v) => v.trim(),
                })}
              />
            </Field>

            <Field className="flex flex-1 flex-col gap-2">
              <Label>Filing Type</Label>
              <Select {...register("filing_type")}>
                <option>8-K</option>
                <option>8-K/A</option>
                <option disabled>10-K</option>
                <option disabled>10-Q</option>
              </Select>
            </Field>
          </div>

          {user.entity_set.length > 1 && (
            <Field className="flex flex-1 flex-col gap-2">
              <Label>Entity</Label>
              <Select {...register("entity_id", { setValueAs: parseInt })}>
                {user.entity_set.map((e) => (
                  <option key={e.id} value={e.id}>
                    {e.name}
                  </option>
                ))}
              </Select>
            </Field>
          )}

          {!uploaded_primary_document && (
            <div className="space-y-2">
              <div className="text-sm font-semibold text-gray-500">
                Primary Document (optional)
              </div>
              <p className="text-xs text-gray-400">
                If you've already got a starting point
              </p>
              <div className="flex items-stretch gap-2">
                <Field className="flex-1">
                  <Controller
                    control={control}
                    name="uploaded_primary_document"
                    render={() => <FileDropZone onFiles={onFiles} />}
                  />
                </Field>
              </div>
            </div>
          )}

          {uploaded_primary_document && (
            <FileListItem
              file={uploaded_primary_document}
              onRemove={removeFile}
            />
          )}
        </div>

        <ModalActions>
          <Button
            onClick={handleClose}
            variant="outline"
            color="gray"
            className="flex-1"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="default"
            color="blue"
            className="flex-1"
            disabled={!isValid || isSubmitting}
          >
            Save
          </Button>
        </ModalActions>
      </Modal>

      <SubmittingModal
        open={isSubmitting}
        processingTime={estimatedProcessingTime}
        completedMessage="Filing Complete"
      />
    </>
  );
}

const addFilingModalFormSchema = z.object({
  uploaded_primary_document: z.instanceof(File).optional(),
  name: z.string().min(1),
  filing_type: z.string(),
  entity_id: z.number(),
});
