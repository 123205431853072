import { useState } from "react";
import {
  StubbedReportingOwner,
  useStubbedData,
} from "../../hooks/useStubbedData";
import { Table } from "../../../_common/components/Table/Table";
import { ButtonLink } from "../../../_common/components/ButtonLink";
import { ReportingOwnerSecurities } from "./ReportingOwnerSecurities";
import clsx from "clsx";

export function ReportingOwnersPanel() {
  const [focusedReportingOwnerId, setFocusedReportingOwnerId] =
    useState<number>();
  const { reportingOwners } = useStubbedData();

  return (
    <div className="p-4">
      <div className="flex">
        <div className="space-y-4 flex-1">
          <h3 className="font-semibold">Reporting Owners</h3>

          <Table
            data={reportingOwners}
            actions={[]}
            rowKey="id"
            emptyState="No reporting owners"
            headers={[
              {
                label: "Name",
                renderCellContent: (d) => {
                  return (
                    <ButtonLink
                      className={clsx(
                        "w-full h-full",
                        focusedReportingOwnerId === d.id && "bg-bgSecondary"
                      )}
                      onClick={() => setFocusedReportingOwnerId(d.id)}
                    >
                      {d.reporting_owner_name}
                    </ButtonLink>
                  );
                },
                getStringContent: (d) => d.reporting_owner_name,
                hasTooltip: false,
              },
              {
                label: "Relation",
                getStringContent: ownerRelationDisplay,
                hasTooltip: false,
              },
            ]}
          />
        </div>

        {focusedReportingOwnerId && (
          <div className="flex-1 p-2 bg-bgSecondary">
            <ReportingOwnerSecurities
              reportingOwnerId={focusedReportingOwnerId}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function ownerRelationDisplay(ro: StubbedReportingOwner) {
  const relations = [];
  if (ro.is_director) {
    relations.push("Director");
  }
  if (ro.is_ten_percent_owner) {
    relations.push("Ten-percent owner");
  }
  if (ro.is_officer) {
    relations.push("Officer");
  }

  return relations.join(", ");
}
