import { TDocument, TReferenceDocument } from "../api/filings";

const NON_DIGIT_CHARS = /[^\d.]/g;
export function documentSortFn(doc: TDocument | TReferenceDocument): number {
  const exhibitTypeNum = parseFloat(
    doc.exhibit_type?.replace(NON_DIGIT_CHARS, "") || "0",
  );
  const suffixNum = parseFloat(
    doc.exhibit_type_suffix?.replace(NON_DIGIT_CHARS, "") || "0",
  );

  return exhibitTypeNum + suffixNum / 1000000;
}
